import { Injectable } from '@angular/core';
import { Caracteristics, Effect, zeroedCaracteristics } from '../core/models/effect.model';
import { Unit } from "../core/models/unit.model"
import { Building } from "../core/models/building.model"
import { SWExporterTypes } from '../core/types/sw-exporter.types';
import { RuneSet } from '../core/models/runeset.model';

type GuildSkillEffectType = SWExporterTypes.EffectType.ATKPercent | SWExporterTypes.EffectType.DEFPercent | SWExporterTypes.EffectType.HPPercent

interface Skill {
    hitCount: number,
    multipliers: any[],
    attackMultiplier: number,
    defMultiplier: number,
    livingAllyMultiplier: number,
    maxHpTargetMultiplier: number,
    maxHpMultiplier: number,
    spdAdd: number,
    spdDivider: number,
    targetSpd: number,
    livingAlly: number,
    skillUpBonus: number
    selectedSkill: any
}


@Injectable({
    providedIn: 'root',
})
export class StatsCalculatorService {

    constructor() {}

    ComputeStats(unit: Unit, team: Unit[] = [], leaderEffects: Effect[] = [], buildings: Building[] = [], guildSkill: 0 | 3 | 5 = 0, additionalSets: RuneSet[]=[]): Caracteristics {
        let computedCaracs = zeroedCaracteristics()

        let trueTeam = Array.from(team)
        trueTeam.push(unit) // Add current unit to team // TODO ensure, we want this behaviour,
        // or we want to include the monster directly to the team. May lead to inconsistencies on calculated stats.
        for(let teammate of trueTeam) {
            if (teammate != undefined) {
                for (let j in teammate.enabledRuneSets) {
                    switch (teammate.enabledRuneSets[j].id) {
                        // Sets that have effect over the whole team.
                        case SWExporterTypes.SetType.FIGHT: 
                        case SWExporterTypes.SetType.DETERMINATION:
                        case SWExporterTypes.SetType.ENHANCE:
                        case SWExporterTypes.SetType.ACCURACY:
                        case SWExporterTypes.SetType.TOLERANCE:
                            computedCaracs = this.CombineCaracteristics(teammate.enabledRuneSets[j].effect.Apply(unit.baseCaracteristics), computedCaracs)
                            for(let i in computedCaracs) {
                                computedCaracs[i] = Math.ceil(computedCaracs[i])
                            }
                    
                            break;
                    }
                }
            }
        }

        for(let set of additionalSets){
            switch (set.id) {
                // Sets that have effect over the whole team.
                case SWExporterTypes.SetType.FIGHT: 
                case SWExporterTypes.SetType.DETERMINATION:
                case SWExporterTypes.SetType.ENHANCE:
                case SWExporterTypes.SetType.ACCURACY:
                case SWExporterTypes.SetType.TOLERANCE:
                    computedCaracs = this.CombineCaracteristics(set.effect.Apply(unit.baseCaracteristics), computedCaracs)
                    break;
            }
        }

        //Rounded up sets
        for(let i in computedCaracs) {
          computedCaracs[i] = Math.round(computedCaracs[i])
        }

        // Apply Guild Skill 
        for(let effectType of [SWExporterTypes.EffectType.ATKPercent, SWExporterTypes.EffectType.DEFPercent, SWExporterTypes.EffectType.HPPercent])
        {
            computedCaracs = this.CombineCaracteristics(new Effect({
                type: effectType,
                value: guildSkill,
                gems: 0,
                grindstones: 0,
                effect_reducer: 1
            }).Apply(unit.baseCaracteristics), computedCaracs)
        }
        for(let i in buildings) {
            let building = buildings[i]
            // Compute stats modification for elemental buildings.
            if(building.metadata.name.includes("Sanctuary")){
                // TODO fix undefined. for attributes. 
                if(building.metadata.name.split(" ")[0].toLowerCase() == SWExporterTypes.Attribute[unit.attribute]?.toLowerCase()) {
                  computedCaracs = this.CombineCaracteristics(building.effect.Apply(unit.baseCaracteristics),computedCaracs)
                }
            }
            else {
                computedCaracs = this.CombineCaracteristics(building.effect.Apply(unit.baseCaracteristics),computedCaracs)
            }
        }
            
        for(let leaderEffect of leaderEffects) {
            computedCaracs = this.CombineCaracteristics(leaderEffect.Apply(unit.baseCaracteristics), computedCaracs)
        }
        computedCaracs = this.CombineCaracteristics(unit.totalCaracteristics, computedCaracs)

        for(let i in computedCaracs) {
            computedCaracs[i] = Math.ceil(computedCaracs[i])
        }
        return computedCaracs
    }

    // helper method to combine caracteristics.
    CombineCaracteristics(record1: Caracteristics, record2: Caracteristics) {
        let combined = zeroedCaracteristics()
        for(let i in record1) {
            combined[i] = record2[i] + record1[i]
        }
        return combined
    }

    // Compute effective hp based on the effective defense.
    ComputeEHP(caracs: Caracteristics): number {
        // effective defense = (1140 + (caracs.Defense * 3.5))/1000
        return caracs.Health * (1140 + (caracs.Defense * 3.5))/1000
    }

    ComputeSkillDamage(unit: Unit, hitCount: number, skillMultiplier: number, skillUpBonus) {
        let skillBonusDamage = unit.additionalCaracteristics.Attack * (skillUpBonus
            + unit.totalCaracteristics['Critical Damage']
           // + this.dmgBonusFirstSkill)/100
           )/100
        
        return (hitCount * skillMultiplier * unit.additionalCaracteristics.Attack) + skillBonusDamage
    }

    ComputeBoostedAttack() {}

    ComputeArtefactBonusDamage(unitCaracs: Caracteristics, 
        artefactDamagePerSpeed: number, artefactDamagePerDef: number, artefactDamagePerHP: number, artefactDamagePerAtk: number) 
    {
    
        let artifactBonus = unitCaracs.Defense * (artefactDamagePerDef / 100)
        + unitCaracs.Health * (artefactDamagePerHP / 100)
        + unitCaracs.Spd * (artefactDamagePerSpeed / 100)
        + unitCaracs.Attack * (artefactDamagePerAtk / 100)
            
        return artifactBonus
    }

    ComputeSkillHit(unitCaracs: Caracteristics, skill: Skill, 
        artefactSkillCrit: number, artefactBuffAttack: number, artefactCritBonusGoodHp: number, artefactCritBonusBadHp: number, targetDef: number,
        applyBreakDef: boolean, applyBuffAttack: boolean, maxTargetHp: number, isCritHit: boolean = true) : { maxDamage: number, minDamage: number, averageDamage: number, minDamageNoCrit: number} {
        let minDamage = 0
        let averageDamage = 0
        let minDamageNoCrit = 0
        let averageDamageNoCrit = 0
        let maxDamageNoCrit = 0
        let maxDamage = 0
        let buffedAttackBonus =0
        let attack = unitCaracs.Attack
        let hp = unitCaracs.Health
        let def = unitCaracs.Defense
        let spd = unitCaracs.Spd

        if (applyBuffAttack) {
            let buffAttack = 0.5
            buffedAttackBonus = Math.ceil(unitCaracs.Attack * (buffAttack + (buffAttack * (artefactBuffAttack/100)) )) 
            attack = attack + buffedAttackBonus
        }
        let skillBonusDamagePercent = (skill.skillUpBonus + artefactSkillCrit + (0.4 * artefactCritBonusGoodHp + (1 - 0.4) * artefactCritBonusBadHp))/100
        let skillCritDamageBonus = unitCaracs['Critical Damage']/100

        if(skill.selectedSkill.length > 0){
          for(let item of skill.selectedSkill.effects){
            if(item.effect.name == 'Bomb'){
              skillBonusDamagePercent = (skill.skillUpBonus + (0.4 * artefactCritBonusGoodHp + (1 - 0.4) * artefactCritBonusBadHp))/100
              skillCritDamageBonus = 0
            }
          }
        }

        let skillDamage = attack;

        if(skill.attackMultiplier > 0){
          skillDamage = skill.attackMultiplier * attack
          console.log(skill.attackMultiplier, attack)
        }
        if(skill.livingAllyMultiplier > 0){
          skillDamage = attack * (skill.attackMultiplier - skill.livingAllyMultiplier * skill.livingAlly)
        }
        if(skill.maxHpTargetMultiplier > 0){
          for(let multiplier of skill.multipliers){
            if(multiplier.includes('{ATK}')){
              skillDamage = skill.attackMultiplier * attack + skill.maxHpTargetMultiplier * maxTargetHp
            }
            if(multiplier.includes('{DEF}')){
              skillDamage = def + skill.maxHpTargetMultiplier * maxTargetHp
            }
          }
        }
        if(skill.maxHpMultiplier > 0){
          skillDamage = skill.maxHpMultiplier * hp
        }
        if(skill.maxHpMultiplier > 0 && skill.attackMultiplier > 0){
          skillDamage = skill.attackMultiplier * attack + skill.maxHpMultiplier * hp
        }
        if(skill.spdAdd > 0){
          for(let multiplier of skill.multipliers){
            if(multiplier.includes('{Target SPD}')){
              skillDamage = attack * (skill.targetSpd + skill.spdAdd) / skill.spdDivider;
            }
            if(multiplier.includes('{SPD}')) {
              skillDamage = attack * (spd + skill.spdAdd) / skill.spdDivider;
            }
          }
        }        
        let skillDamageNoCrit = skillDamage + (skillDamage * skillBonusDamagePercent)
        let skillDamageCrit = skillDamageNoCrit + (skillDamage * skillCritDamageBonus)

        if (applyBreakDef) {
            let breakDef = 0.3
            targetDef = breakDef* targetDef
        }
        let effectiveDef = 1000/(1140 + 3.5 * targetDef)

        minDamage = effectiveDef * ((isCritHit ? skillDamageCrit : skillDamageNoCrit) - skillDamageNoCrit/10)
        averageDamage  = effectiveDef * (isCritHit ? skillDamageCrit : skillDamageNoCrit)
        maxDamage = effectiveDef * ((isCritHit ? skillDamageCrit : skillDamageNoCrit) + skillDamageNoCrit/10)
        minDamageNoCrit = effectiveDef * skillDamageNoCrit;

        return {
            minDamage: minDamage,
            averageDamage: averageDamage,
            minDamageNoCrit: minDamageNoCrit,
            maxDamage: maxDamage,
        }
    }
}