import { Effect } from "../models/effect.model";
import { SWCalculatorTypes } from "../types/sw-calculator.types"
import { SWExporterTypes } from "../types/sw-exporter.types"

class BuildingStore {
    BuildingMetadata: Map<SWExporterTypes.BuildingType, SWCalculatorTypes.BuildingMetaData> = new Map()

    private static _instance: BuildingStore;

    private constructor() {
        // Elemental buildings
        this.createBuildingMetadata(SWExporterTypes.BuildingType.FIRE_SANCTUARY, "Fire Sanctuary", SWExporterTypes.EffectType.ATKPercent, 2, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.WATER_SANCTUARY, "Water Sanctuary", SWExporterTypes.EffectType.ATKPercent, 2, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.WIND_SANCTUARY, "Wind Sanctuary", SWExporterTypes.EffectType.ATKPercent, 2, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.LIGHT_SANCTUARY, "Light Sanctuary", SWExporterTypes.EffectType.ATKPercent,  2, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.DARK_SANCTUARY, "Dark Sanctuary", SWExporterTypes.EffectType.ATKPercent,  2, 1)
        //
        this.createBuildingMetadata(SWExporterTypes.BuildingType.CRYSTAL_ALTAR, "Crystal Altar", SWExporterTypes.EffectType.HPPercent, 1, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM, "Sky Tribe Totem", SWExporterTypes.EffectType.SPEEDPercent, 1, 0.75)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.ANCIENT_SWORD, "Ancient Sword", SWExporterTypes.EffectType.ATKPercent, 1, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.GUARDSTONE, "Guard Stone", SWExporterTypes.EffectType.DEFPercent, 1, 1)
        this.createBuildingMetadata(SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, "Fallen Ancient Guardian",  SWExporterTypes.EffectType.CRITDmg, 1.5, 1.25)
    }

    private createBuildingMetadata(buildingType :SWExporterTypes.BuildingType, name: string, effectType : SWExporterTypes.EffectType, baseBonus: number, bonusPerLevel: number, minLevel: number =0, maxLevel: number = 20) {
        this.BuildingMetadata.set(buildingType, new SWCalculatorTypes.BuildingMetaData(name, effectType, baseBonus, bonusPerLevel, minLevel, maxLevel, buildingType))
    }

    GetMetadataFromType(buildingType: SWExporterTypes.BuildingType) : SWCalculatorTypes.BuildingMetaData{
        if (this.BuildingMetadata.has(buildingType)) {
            return this.BuildingMetadata.get(buildingType)
        }
        // Return null if set is not defined, for now it is tied to the limited number defined above.
        return null
    }

    GetMetadata(){
      return this.BuildingMetadata
    }

    static GetInstance() {
      return this._instance || (this._instance = new this());
    }
}

export default BuildingStore.GetInstance()