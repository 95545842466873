import { Component } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {

  constructor(private route:Router) {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && localStorage.getItem("wizard_data")) {
        this.localStorageExist = true;
      }

      if (event instanceof NavigationError) {
          console.log(event.error);
      }
    });
  }

  title = "sw-rune";
  localStorageExist = false;
  checked: boolean;

  ngOnInit(){
    this.checked = JSON.parse(localStorage.getItem("isChecked"));
    if (localStorage.getItem("wizard_data") || this.checked == true) {
      this.localStorageExist = true;
    } else {
      this.route.navigate(['/']);
    }
  }
}
