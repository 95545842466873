<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filters <fa-icon class="faFilter ml-2" [icon]="filter"></fa-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="filters flex flex-wrap flex-col p-4 gap-4">
    <div class="flex flex-row flex-wrap w-full">
      <div class="rune-container sets">
        <label>Sets</label>
        <!--Using items input-->
        <ng-select [items]="sets" 
          bindLabel="value" 
          bindValue="id" 
          [(ngModel)]="selectedSets"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.value}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="rune-container mainStats">
        <label>Main Stats</label>
        <!--Using items input-->
        <ng-select [items]="stats" 
          bindLabel="value" 
          bindValue="id" 
          [(ngModel)]="selectedMainStats"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.value}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="rune-container innateStat">
        <label>Innate Stats</label>
        <!--Using items input-->
        <ng-select [items]="innateStats" 
          bindLabel="value" 
          bindValue="id" 
          [(ngModel)]="seletedInnateStats"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.value}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="rune-container subStat">
        <label>Secondary Effects</label>
        <!--Using items input-->
        <ng-select [items]="subStats" 
          bindLabel="value" 
          bindValue="id" 
          [(ngModel)]="selectedSubStats"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.value}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="rune-container baseRarity">
        <label>Base Rarity</label>
        <!--Using items input-->
        <ng-select [items]="extras" 
          bindLabel="value" 
          bindValue="id" 
          [(ngModel)]="selectedExtra"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.value}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="rune-container actualRarity">
        <label>Actual Rarity</label>
        <!--Using items input-->
        <ng-select [items]="ranks" 
          bindLabel="value" 
          bindValue="id" 
          [(ngModel)]="selectedRank"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.value}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="rune-container slot">
        <label>Slots</label>
        <!--Using items input-->
        <ng-select [items]="slots" 
          bindLabel="id" 
          bindValue="id" 
          [(ngModel)]="selectedSlot"
          [multiple]="true" 
          class="multiselect" 
          (change)="onSlidersChange()" 
          [closeOnSelect]="false">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice:0:1">
              <span class="ng-value-label">{{item.id}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="flex flex-row flex-wrap w-full mt-4 z-0">
        <div class="rune-container stars flex flex-col text-left border-sw-orange">
          <div class="custom-slider">
            <ngx-slider [(value)]="minStarsSelect" [(highValue)]="maxStarsSelect" [options]="optionsStars" (userChange)="onSlidersChange()"></ngx-slider>
          </div>
          <label clas="p-2">Stars 
            <fa-icon class="faStar ml-2" [icon]="star"></fa-icon>
          </label>
        </div>
        <div class="rune-container level flex flex-col text-left border-sw-orange">
          <div class="custom-slider">
            <ngx-slider [(value)]="minLevelsSelect" [(highValue)]="maxLevelsSelect" [options]="optionsLevels" (userChange)="onSlidersChange()"></ngx-slider>
          </div>
          <label clas="p-2">Levels</label>
        </div>
        <div class="rune-container flex flex-col justify-center">
          <mat-button-toggle-group name="fontStyle" value="All">
            <mat-button-toggle value="Ancient" (change)="onValAncientChange($event.value)" class="flex flex-1">Ancient</mat-button-toggle>
            <mat-button-toggle value="All" (change)="onValAncientChange($event.value)" class="flex flex-1">All</mat-button-toggle>
            <mat-button-toggle value="Normal" (change)="onValAncientChange($event.value)" class="flex flex-1">Normal</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="rune-container flex flex-col justify-center">
          <mat-button-toggle-group name="fontStyle" value="All">
            <mat-button-toggle value="Storage" (change)="onValAssignementChange($event.value)" class="flex flex-1">Storage</mat-button-toggle>
            <mat-button-toggle value="All" (change)="onValAssignementChange($event.value)" class="flex flex-1">All</mat-button-toggle>
            <mat-button-toggle value="Assigned" (change)="onValAssignementChange($event.value)" class="flex flex-1">Assigned</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>