export class CairossImport {
  get cairossImport() {
    var dungeon: any;

    return dungeon = {
      boss: {
        boss_img: {
          '"Giant\'s Keep B12"': "assets/img/boss/giant_caiross.jpg",
          '"Dragon\'s Lair B12"': "assets/img/boss/dragon_caiross.jpg",
          'Necropolis B12': "assets/img/boss/necropolis_caiross.jpg",
          '"Punisher\'s Crypt B12"': "assets/img/boss/crypt_caiross.jpg",
          'Steel Fortress': "assets/img/boss/fortress_caiross.jpg",
        },
        drop_img: {
          "runePieces": "assets/img/drop/rune-piece.jpg",
          "chaosSymbol": "assets/img/drop/chaos-symbol.jpg",
          "harmonySymbol": "assets/img/drop/harmony-symbol.jpg",
          "transcendenceSymbol": "assets/img/drop/transcendence-symbol.jpg",
          "mysticScroll": "assets/img/drop/mystic-scroll.jpg",
          "unknownScroll": "assets/img/drop/unknown-scroll.jpg",
          "shapeshiftingStone": "assets/img/drop/shapeshifting-stone.png",
          "arcenmon": "assets/img/drop/arcenmon.jpg"
        },
      },
    }
  }
}