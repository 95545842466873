import { Component, Input, OnInit } from "@angular/core";
import { Effect } from "src/app/core/models/effect.model";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "rune-secondary-stat",
  templateUrl: "./rune-secondary-stat.template.html",
  styleUrls: ["../../../styles.css"],
})
export class RuneSecondaryStatComponent implements OnInit {
  sync = faSyncAlt;
  name: string;

  @Input() effect: Effect; 

  ngOnInit(){
    this.name = Effect.typeStr[this.effect.type];
  } 
}