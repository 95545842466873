<div class="main-container">
  <div class="full h-56 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl z-20 mt-12">Monsters</h1>
    <div class="absolute text-s font-light py-4 ml-4 z-20 bottom-0" style="top: 140px; right: 25px;">
      <mat-slide-toggle [ngModel]="showBonus" (ngModelChange)="toggleBonus($event)" class="w-40px">
      </mat-slide-toggle>
      <span class="pl-2 text-xl">Show/Hide Bonus</span>
    </div>
    <div class="absolute w-full h-56 bg-sw-darkGrey opacity-90 z-10"></div>
  </div>
  
  <div class="container table_container min-w-full">
    <!-- <mat-form-field class="mat-search z-20">
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="Filter ..."
      />
    </mat-form-field> -->
    <div class="max-w-full overflow-scroll">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="monster-table"
        #matSecSort="matSort" 
        multiTemplateDataRows
        matSortActive="spd"
        matSortDirection="desc" 
        matSortDisableClear
      >
      
        <ng-container matColumnDef="image" sticky>
          <th mat-header-cell *matHeaderCellDef class="monster_img padding-right"></th>
          <td mat-cell *matCellDef="let element" class="monster_img inherit-size">
            <img
            src="https://swarfarm.com/static/herders/images/monsters/{{
              element.image
            }}"
            />
          </td>
        </ng-container>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element" class="w-0 px-4">
            {{ element.name }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="has_runes">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="flex justify-center">
            <img *ngIf="hasRune(element.runes)" class="wh-40px" src="assets/img/rune/runes.png" />
          </td>
        </ng-container>
        
        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Lvl</th>
          <td mat-cell *matCellDef="let element" class="px-4">{{ element.level }}</td>
        </ng-container>
        
        <ng-container matColumnDef="atk">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Atk</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics.Attack}} + {{element.additionalCaracteristics.Attack | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics.Attack | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics.Attack > 0">(+{{element.additionalCaracteristics.Attack | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="def">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Def</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics.Defense}} + {{element.additionalCaracteristics.Defense | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics.Attack | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics.Defense > 0">(+{{element.additionalCaracteristics.Defense | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="spd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Spd</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics.Spd}} + {{element.additionalCaracteristics.Spd | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics.Spd | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics.Spd > 0">(+{{element.additionalCaracteristics.Spd | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="hp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>HP</th>
          <td mat-cell *matCellDef="let element" class="px-4"  matTooltip="{{element.baseCaracteristics.Health}} + {{element.additionalCaracteristics.Health | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics.Health | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics.Health > 0">(+{{element.additionalCaracteristics.Health | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="critical_damage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Crit. Dmg</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics['Critical Damage']}} + {{element.additionalCaracteristics['Critical Damage'] | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics["Critical Damage"] | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics['Critical Damage'] > 0">(+{{element.additionalCaracteristics['Critical Damage'] | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="critical_rate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Crit. Rate</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics['Critical Rate']}} + {{element.additionalCaracteristics['Critical Rate'] | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics["Critical Rate"] | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics['Critical Rate'] > 0">(+{{element.additionalCaracteristics['Critical Rate'] | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="resist">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Res</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics.Resistance}} + {{element.additionalCaracteristics.Resistance | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics.Resistance | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics.Resistance > 0">(+{{element.additionalCaracteristics.Resistance | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="accuracy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Acc</th>
          <td mat-cell *matCellDef="let element" class="px-4" matTooltip="{{element.baseCaracteristics.Accuracy}} + {{element.additionalCaracteristics.Accuracy | number: '1.0-0'}}" matTooltipPosition="above">
            {{ element.totalCaracteristics.Accuracy | number: "1.0-0" }} <span class="text-sw-gold" *ngIf="showBonus && element.additionalCaracteristics.Accuracy > 0">(+{{element.additionalCaracteristics.Accuracy | number: '1.0-0'}})</span>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="runes">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="rune_wrapper"
              [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="flex flex-row min-w-full">
                <div class="flex flex-row flex-1">
                  <ng-container *ngFor="let runeKey of getKeys(element.runes)">
                    <rune-card class="flex-1" [rune]="element.runes[runeKey]"></rune-card>
                  </ng-container>
                </div>
                <div class="flex flex-col">
                  <ng-container *ngFor="let artifactKey of getKeys(element.artifacts)">
                    <artifact-card class="flex-1" [artifact]="element.artifacts[artifactKey]"></artifact-card> 
                  </ng-container>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"
          class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['runes']"
          class="example-detail-row"
        ></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[25, 50, 100, 200, 500]"></mat-paginator>
  </div>  
</div>
