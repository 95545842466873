<div class="main-container"> 
    <div class="full h-56 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
        <h1 class=" font-semibold tracking-widest text-5xl z-20 mt-12 flex flex-row items-center">Contributors</h1>
        <div class="absolute w-full h-56 bg-sw-darkGrey opacity-90 z-10"></div>
      </div>
    <div class="flex flex-row flex-wrap p-4 justify-between gap-4">
        <div class="flex flex-col flex-1">
            <div class="flex flex-row p-4 rounded-2xl bg-sw-mediumGrey justify-center">
                <h2 class="text-2xl">Developpers <fa-icon class="text-lg ml-2" [icon]="code"></fa-icon></h2>
            </div>
            <a href="https://twitter.com/NokiDev" target="_blank">
                <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                    <img class="wh-40px rounded-2xl" src="./assets/img/contrib/noki.jpg">
                    <div class="ml-4 flex flex-col">
                        <h2 class="text-lg flex flex-row items-center">Noki <img class="wh-20px" src="./assets/img/contrib/twitter.png"></h2>
                        <span>Refactor god & coodeveloper of SwLab.</span>
                    </div>
                </div>
            </a>
            <a href="https://twitter.com/MonsieurToutNu" target="_blank">
                <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                    <img class="wh-40px rounded-2xl" src="./assets/img/contrib/kowoshira.jpg">
                    <div class="ml-4 flex flex-col">
                        <h2 class="text-lg flex flex-row items-center">Kowoshira <img class="wh-20px" src="./assets/img/contrib/twitter.png"></h2>
                        <span>Project owner, developer and designer.</span>
                    </div>
                </div>
            </a>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-row p-4 rounded-2xl bg-sw-mediumGrey justify-center">
                <h2 class="text-2xl">Helpers <fa-icon class="text-lg" [icon]="helper"></fa-icon></h2>
            </div>
            <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                <img class="wh-40px rounded-2xl" src="./assets/img/contrib/shinneday.png">
                <div class="ml-4 flex flex-col">
                    <h2 class="text-lg flex flex-row items-center">Shinneday</h2>
                    <span>Theorycraft and formulas god.</span>
                </div>
            </div>
            <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                <img class="wh-40px rounded-2xl" src="./assets/img/contrib/kannely.png">
                <div class="ml-4 flex flex-col">
                    <h2 class="text-lg flex flex-row items-center">Kannely</h2>
                    <span>Theorycraft and formulas goddess.</span>
                </div>
            </div>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-row p-4 rounded-2xl bg-sw-mediumGrey justify-center">
                <h2 class="text-2xl">Contributors <fa-icon class="text-lg ml-2" [icon]="contributor"></fa-icon></h2>
            </div>
            <a href="https://twitter.com/Shamirae_" target="_blank">
                <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                    <img class="wh-40px rounded-2xl" src="./assets/img/contrib/shamirae.jpg">
                    <div class="ml-4 flex flex-col">
                        <h2 class="text-lg flex flex-row items-center">Shamirae <img class="wh-20px" src="./assets/img/contrib/twitter.png"></h2>
                        <span>Day 1 supporter and idea maker.<span class="entypo-heart ml-2"></span></span>
                    </div>
                </div>
            </a>
            <a href="https://twitter.com/ManaProd" target="_blank">
                <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                    <img class="wh-40px rounded-2xl" src="./assets/img/contrib/manaprod.jpg">
                    <div class="ml-4 flex flex-col">
                        <h2 class="text-lg flex flex-row items-center">Mana <img class="wh-20px" src="./assets/img/contrib/twitter.png"></h2>
                        <span>Huge help to promote the project.</span>
                    </div>
                </div>
            </a>
            <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                <img class="wh-40px rounded-2xl" src="./assets/img/contrib/miette_cosmique.jpg">
                <div class="ml-4 flex flex-col">
                    <h2 class="text-lg flex flex-row items-center">Miette Cosmique</h2>
                    <span>My SW guild who supported me since the beginning </span>
                </div>
            </div>
        </div>
        <div class="flex flex-col flex-1">
            <div class="flex flex-row p-4 rounded-2xl bg-sw-mediumGrey justify-center">
                <h2 class="text-2xl">Ressources <fa-icon class="text-lg ml-2" [icon]="ressources"></fa-icon></h2>
            </div>
            <a href="https://swarfarm.com/api/" target="_blank">
                <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                    <img class="wh-40px rounded-2xl" src="./assets/img/contrib/buff_speed.png">
                    <div class="ml-4 flex flex-col">
                        <h2 class="text-lg flex flex-row items-center">Swarfarm Api</h2>
                        <span>A huge Api that helps us so much.</span>
                    </div>
                </div>
            </a>
            <a href="https://github.com/Xzandro/sw-exporter/releases" target="_blank">
              <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                  <img class="wh-40px rounded-2xl" src="./assets/img/contrib/swex.png">
                  <div class="ml-4 flex flex-col">
                      <h2 class="text-lg flex flex-row items-center">SWEX <img class="wh-20px rounded-full ml-2" src="./assets/img/contrib/github.png"></h2>
                      <span>Cool soft that extract your .json</span>
                  </div>
              </div>
            </a>
            <a href="https://sw.com2us.com/fr" target="_blank">
                <div class="flex flex-row p-4 rounded-2xl bg-sw-lightGrey justify-start mt-4 text-white">
                    <img class="wh-40px rounded-2xl" src="./assets/img/contrib/com2us.png">
                    <div class="ml-4 flex flex-col">
                        <h2 class="text-lg flex flex-row items-center">Com2uS</h2>
                        <span>All ressources belongs to Com2uS</span>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>