import { Component, OnInit } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs'; 

@Component({
  selector: 'app-lab-hub',
  templateUrl: './lab-hub.component.html',
  styleUrls: ['./lab-hub.component.scss']
})
export class LabHubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
