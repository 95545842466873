<div class="full h-28 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
  <h1 class=" font-semibold tracking-widest text-5xl">Caiross Run</h1>
  <app-footer></app-footer>
</div>

<div class="flex justify-between bg-gray-900">
  <div class="left flex justify-start items-center pl-5 py-4">
    <span>Import your Runs informations
      <fa-icon class="text-white mx-4" [icon]="fileCsv"></fa-icon>
    </span>
    <input class="hidden" type="file" accept=".csv" id="csvFileUpload" (change)="fileChangeListener($event)" #fileInputCSV />
    <button mat-flat-button color="accent" type="button" (click)="fileInputCSV.click()" class="px-4 py-2">
      Choose file
    </button>
  </div>
  <div class="right flex items-center">
    <button mat-flat-button color="accent" class="py-4 pl-5 h-full rounded-none bg-gray-700" type="button" (click)="openDialog()">Show runs details<fa-icon class="mx-4" [icon]="chart"></fa-icon>
    </button>
  </div>
</div>
<div class="container no-padding min-w-full" *ngIf="importState == ImportState.imported">
  <table mat-table [dataSource]="dataSource" class="run-table" matSort #matSecSort="matSort"
    multiTemplateDataRows>

    <ng-container matColumnDef="dungeon.dungeonName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="xsmall-header" style="padding: 0px !important">
      </th>
      <td mat-cell *matCellDef="let element" class="xsmall-header rune_img">
        <img src="./{{ element.dungeon.dungeon_img }}" />
      </td>
    </ng-container>

    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="xsmall-header"> Result </th>
      <td mat-cell *matCellDef="let element" class="xsmall-header px-4"> {{element.result}}
        <span *ngIf="element.result === 'Win'">
          <fa-icon [icon]="checkCircle" class="green-icon"></fa-icon>
        </span>
        <span *ngIf="element.result === 'Lose'">
          <fa-icon [icon]="timesCircle" class="red-icon"></fa-icon>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="xsmall-header"> Time </th>
      <td mat-cell *matCellDef="let element" class="xsmall-header px-4"> {{element.time }} </td>
    </ng-container>

    <ng-container matColumnDef="drop">
      <th mat-header-cell *matHeaderCellDef class="xsmall-header"> Drop </th>
      <td mat-cell *matCellDef="let element" ngClass="./{{ element.rarity }}" class="rune_img">
        <img class="m-auto" *ngIf="element.drop.drop_img" src="./{{ element.drop.drop_img }}" />
        <img class="m-auto" *ngIf="element.slot.rune_img" src="./{{ element.slot.rune_img }}" />
        <img *ngIf="element.set.set_img" class="-mt-40 m-auto" src="./{{ element.set.set_img }}" />
      </td>
    </ng-container>

    <ng-container matColumnDef="efficiency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-header"> Efficiency </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.efficiency}} </td>
    </ng-container>

    <ng-container matColumnDef="grade">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-header rune_img">
        <fa-icon [icon]="star"></fa-icon>
      </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.grade.grade}} <fa-icon *ngIf="element.grade?.grade"
          [icon]="star"></fa-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="main_stat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Main Stat </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.main_stat}} </td>
    </ng-container>
    <ng-container matColumnDef="prefix_stat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Innate stat </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.prefix_stat}} </td>
    </ng-container>
    <ng-container matColumnDef="sub1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub 1 </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.sub1}} </td>
    </ng-container>
    <ng-container matColumnDef="sub2">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub 2 </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.sub2}} </td>
    </ng-container>
    <ng-container matColumnDef="sub3">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub 3 </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.sub3}} </td>
    </ng-container>
    <ng-container matColumnDef="sub4">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub 4 </th>
      <td mat-cell *matCellDef="let element" class="px-4"> {{element.sub4}} </td>
    </ng-container>

    <ng-container matColumnDef="team">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="team-header"> Team </th>
      <td mat-cell *matCellDef="let element" class="flex flex-nowrap flex-row p-0">
        <img *ngIf="element.team1?.monster_file" src="https://swarfarm.com/static/herders/images/monsters/{{element.team1.monster_file}}">
        <img *ngIf="element.team2?.monster_file" src="https://swarfarm.com/static/herders/images/monsters/{{element.team2.monster_file}}">
        <img *ngIf="element.team3?.monster_file" src="https://swarfarm.com/static/herders/images/monsters/{{element.team3.monster_file}}">
        <img *ngIf="element.team4?.monster_file" src="https://swarfarm.com/static/herders/images/monsters/{{element.team4.monster_file}}">
        <img *ngIf="element.team5?.monster_file" src="https://swarfarm.com/static/herders/images/monsters/{{element.team5.monster_file}}">
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
