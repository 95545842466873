import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  checked: boolean;
  mugHot = faCoffee;

  constructor() { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-scroll');
    } else {
      element.classList.remove('navbar-scroll');
    }
  }

  ngOnInit(): void {
    this.checked = JSON.parse(localStorage.getItem("isChecked"));
  }
  
}
