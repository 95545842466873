<div class="main-container">  
  <div class="full h-56 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl z-20 mt-12 flex flex-row items-center">Runes</h1>
    <div class="absolute w-full h-56 bg-sw-darkGrey opacity-90 z-10"></div>
  </div>

  <rune-filter></rune-filter>

  <div class="max-w-full overflow-x-scroll">
    <table mat-table [dataSource]="dataSource" 
    class="rune-table" 
    #matSortTotal="matSort" 
    matSort matSortActive="spd"
    matSortDirection="desc">

      <!-- Rune Set Column -->
      <ng-container matColumnDef="rune_set">
        <th mat-header-cell *matHeaderCellDef class="rune_set padding-right mw-40px" mat-sort-header> Rune </th>
        <td mat-cell *matCellDef="let element" class="rune_set flex items-center justify-center inherit-size"
        ngClass="{{ getExtraName(element.extra) }}" [style.background-image]="'url(/' + element.backgroundImage + ')'">
          <img class="z-10 p-4px -ml-10px lazyload" data-src="{{ element.slotImage }}" />
          <img class="w-20px z-10 p-1 -ml-30px lazyload" data-src="{{ element.setImage }}" />
        </td>
      </ng-container>

      <!-- Monster Column -->
      <ng-container matColumnDef="unitImage">
        <th mat-header-cell *matHeaderCellDef class="monster_img text-center padding-right">
          <img class="w-20px m-auto lazyload" data-src="../../../assets/img/icon/monster-icon.png"/>
        </th> 
        <td mat-cell *matCellDef="let element" class="inherit-size"> 
          <img *ngIf="element.unitImage != undefined"
          data-src="https://swarfarm.com/static/herders/images/monsters/{{
            element.unitImage
          }}" class="w-40px h-40px lazyload" />
        </td>
      </ng-container>

      <ng-container matColumnDef="setType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rune Set</th>
        <td mat-cell *matCellDef="let element" class="px-2">
          {{ getSetName(element.setType) }}
        </td>
      </ng-container>

      <!-- Grade Column -->
      <ng-container matColumnDef="stars">
        <th mat-header-cell *matHeaderCellDef> Grade </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          {{ element.stars }}
          <fa-icon [icon]="star"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actual_efficiency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Efficiency </th>
        <td mat-cell *matCellDef="let element" class="px-2 bg-sw-clay">
          {{ element.efficiency.current.toFixed(2) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="max_efficiency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Max Efficiency </th>
        <td mat-cell *matCellDef="let element" class="px-2 bg-sw-clay">
          {{ element.efficiency.max.toFixed(2) }}
        </td>
      </ng-container>

      <!-- Upgrade Column -->
      <ng-container matColumnDef="upgradeLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Upgrade </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          + {{ element.upgradeLevel }} 
        </td>
      </ng-container>

      <!-- Main Stat Column -->
      <ng-container matColumnDef="rune_main_stat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Main Stat </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <rune-secondary-stat [effect]="element.primaryEffect"></rune-secondary-stat>
        </td>
      </ng-container>

      <!-- Innate Stat Column -->
      <ng-container matColumnDef="innate_stat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Innate Stat </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <rune-secondary-stat *ngIf="element.innateEffect?.value > 0" [effect]="element.innateEffect"></rune-secondary-stat>
        </td>
      </ng-container>

      <!-- ATK% Column -->
      <ng-container matColumnDef="atkPercent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ATK% </th>
        <td mat-cell *matCellDef="let element" class="px-2"> 
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'ATK%'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- ATK Flat Column -->
      <ng-container matColumnDef="atkFlat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ATK </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'ATK'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- DEF% Column -->
      <ng-container matColumnDef="defPercent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> DEF% </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'DEF%'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- DEF Flat Column -->
      <ng-container matColumnDef="defFlat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> DEF </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'DEF'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- HP% Column -->
      <ng-container matColumnDef="hpPercent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> HP% </th>
        <td mat-cell *matCellDef="let element" class="px-2"> 
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'HP%'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- HP Flat Column -->
      <ng-container matColumnDef="hpFlat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> HP </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'HP'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- SPD Flat Column -->
      <ng-container matColumnDef="spd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> SPD </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'SPD'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- ACCURACY Flat Column -->
      <ng-container matColumnDef="accuracy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ACC </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'Acc'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- RES Column -->
      <ng-container matColumnDef="resistance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> RES </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'Res'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- CritRate Column -->
      <ng-container matColumnDef="critRate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CritRate </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'C.Rate'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- CritDdmg Column -->
      <ng-container matColumnDef="critDdmg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CritDmg </th>
        <td mat-cell *matCellDef="let element" class="px-2">
          <ng-container *ngFor="let sec_eff of element.secondaryEffects">
            <ng-container *ngIf="getEffectTypeString(sec_eff.type) === 'C.Dmg'">
              <rune-secondary-stat [effect]="sec_eff"></rune-secondary-stat>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- Rune Value Column -->
      <!-- <ng-container matColumnDef="sell_value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mana </th>
        <td mat-cell *matCellDef="let element" class="px-4">
          <span class="text-white">{{ element.sell_value }}</span>
          <img class="mana ml-3 inline" src="assets/img/mana-crystal.png" />
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator
  #matTablePaginatorStorage="matPaginator"
  [pageSizeOptions]="[50, 200, 400, 800, 1000]"
  ></mat-paginator> 
</div>