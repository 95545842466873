/* Angular*/
import { Component, OnInit, ViewChild } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
/* Angular Material */
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {MatDialog} from '@angular/material/dialog';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
/* FontAwesome */
import { faStar, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { RuneSlot, Unit } from "src/app/core/models/unit.model";
import { ImportService } from "../../services/import.service";
import { Effect, InGameEffect } from "src/app/core/models/effect.model";
import { SWExporterTypes } from "src/app/core/types/sw-exporter.types";
import runeSetDataStore from "src/app/core/stores/rune-set-data.store";
import { SWCalculatorTypes } from "src/app/core/types/sw-calculator.types";

@Component({
  selector: "app-monsters",
  templateUrl: "./monsters.component.html",
  styleUrls: ["../../../styles.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class MonstersComponent implements OnInit {
  isGrindStone = false;
  /* FontAwesome Icons */
  star = faStar;
  slider = faSlidersH;
  /* Table */

  monsterArtifacts: any = [];
  dataSource: any;
  displayedColumns: string[] = [
    "image",
    "name",
    "has_runes",
    "level",
    "atk",
    "def",
    "spd",
    "hp",
    "critical_damage",
    "critical_rate",
    "resist",
    "accuracy",
  ];

  selection = new SelectionModel<any>(true, []);
  expandedElement: any;
  showBonus: boolean = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public dialog: MatDialog, private importService :ImportService) {}

  ngOnInit() {
    let units = this.importService.GetUnits()
    this.dataSource = new MatTableDataSource<Unit>(units);
    this.expandedElement = units;
    /* SORT FILTER FOR NESTED OBJECTS */
    this.dataSource.sortingDataAccessor = (item: Unit, property) => {
      switch (property) {
        case "name":
          return item.name;
        case "atk":
          return item.totalCaracteristics.Attack;
        case "def":
          return item.totalCaracteristics.Defense;
        case "accuracy":
          return item.totalCaracteristics.Accuracy;
        case "spd":
          return item.totalCaracteristics.Spd;
        case "critical_rate":
          return item.totalCaracteristics["Critical Rate"];
        case "critical_damage":
          return item.totalCaracteristics["Critical Damage"];
        case "resist":
          return item.totalCaracteristics.Resistance;
        case "hp":
          return item.totalCaracteristics.Health;
        default:
          return item[property];
      }
    };
    this.dataSource.filterPredicate = function (data: Unit, filter: string, filter_number: number): boolean {
      return (
        data.name?.toLowerCase().includes(filter) ||
        data.totalCaracteristics.Health?.toString().toLowerCase().includes(filter) ||
        data.totalCaracteristics.Defense?.toString().toLowerCase().includes(filter) ||
        data.totalCaracteristics["Critical Rate"]?.toString().toLowerCase().includes(filter) ||
        data.totalCaracteristics["Critical Damage"]?.toString().toLowerCase().includes(filter) ||
        data.totalCaracteristics.Accuracy?.toString().toLowerCase().includes(filter) ||
        data.totalCaracteristics.Resistance?.toString().toLowerCase().includes(filter) //||
        //data.?.toString().toLowerCase().includes(filter)
      );
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openDialog() {
    this.dialog.open(dialogParams);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getEffectTypeString(effectType: SWExporterTypes.EffectType) {
    return Effect.typeStr[effectType]
  }

  getSetName(setType: SWExporterTypes.SetType) {
    return runeSetDataStore.GetRuneSetFromType(setType).name
  }

  getKeys(obj:any){
    return Object.keys(obj)
  }

  hasRune(runeSlot: RuneSlot){
    for(let rune in runeSlot){
      if(runeSlot[rune] != undefined){
        return true
      }
    }
    return false
  }

  toggleBonus(e){
    console.log(e)
    this.showBonus = !this.showBonus
  }
}

@Component({
  selector: 'dialog-params',
  templateUrl: 'dialog-params.html',
  styleUrls: ["./dialog-params.scss"],
})
export class dialogParams {
  star = faStar;
}