import { Injectable } from "@angular/core";
import { Building } from "../core/models/building.model";
import { SWExporterTypes } from "../core/types/sw-exporter.types";
import { ImportService } from "./import.service";



@Injectable({
    providedIn: 'root',
})
export class BuildingService {
    constructor(private importService: ImportService) {

    }

    GetBuildingsWithType(types: SWExporterTypes.BuildingType[]){
      let buildings = types.map(t => new Building(t, 0))

      for(let building of this.importService.GetBuildings().map(n => Building.CreateFromBuilding(n))){
        if(types.includes(building.metadata.type)){
          for(let defaultBuilding of buildings){
            if(building.metadata.type == defaultBuilding.metadata.type){
              defaultBuilding.level = building.level
            }
          }
        }
      }
      return buildings
    }
}