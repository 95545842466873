
<header id="header-content" class="relative h-full overflow-hidden" style="height: 100vh;">
  <div class="container">
    <div class="flex flex-row flex-wrap">
      <div class="text-left text-6xl flex flex-col z-50" style="max-width: 625px;">
        <span class="pre-title text-3xl mb-4">Enhance and Develop your</span>
        <span class="text-title mb-8">Game Experience</span>
        <span class="desc-title mb-12 text-2xl">Simply import your .json file and take advantage of the Lab' to improve, develop and strengthen your game content.</span>
        <input style="display: none;" type="file" accept=".json" (change)="onFileLoad($event)" #fileInput />
        <div class="flex flex-wrap z-20 text-black mb-12 button-container gap-4">
          <button mat-button class="p-4 whitespace-nowrap btn-primary" 
          [disabled]="checked" (click)="fileInput.click()">
          Choose file
            <fa-icon class="w-5 h-5 mx-2" [icon]="folder"></fa-icon>
          </button>
          <span *ngIf="importState == ImportState.imported" class="mx-0 my-auto">
            <fa-icon [icon]="checkCircle"></fa-icon>
          </span>
          <button mat-button class="p-4 whitespace-nowrap btn-secondary-action" type="button"
            (click)="importJson(); openDialog()" [disabled]="importState == ImportState.none"> 
            Import
            <fa-icon class="w-5 h-5 mx-2" [icon]="file"></fa-icon>
          </button>
          <hr class="divider-right">
          <button mat-button class="p-4 whitespace-nowrap btn-secondary-delete" [disabled]="!this.data" (click)="clearData()"> 
            Clear Data
            <fa-icon class="w-5 h-5 mx-2" [icon]="trash"></fa-icon>
          </button>
        </div>
        <div class="data-clear flex flex-row pt-6 bottom-0 justify-start align-middle items-center z-50">
          <mat-slide-toggle [ngModel]="checked" (ngModelChange)="onChange($event)" class="w-40px"></mat-slide-toggle>
          <span class="pl-2 text-base">Use w/o .json</span>
        </div>
        <div class="data-info flex flex-row items-center justify-left z-30 pt-6" *ngIf="(fileInput?.files)[0]">
          <span class="text-gray-400 text-left text-lg underline">
            {{ (fileInput?.files)[0]?.name }}
          </span>
        </div>
      </div> 
    </div>
  </div>
  <div class="absolute bottom-1 right-1 z-50">
    <a href="https://twitter.com/SwLab_" target="_blank" class="flex flex-row items-center opacity-70 text-white">@SwLab_ <img class="wh-20px" src="./assets/img/contrib/twitter.png"></a>
  </div>
  <div _ngcontent-cwh-c291="" class="importHeader absolute -top-1/2 -left-1/2 w-full-2 h-full-2 z-0">
    <div class="absolute opacity-60 h-full w-full z-10 bg-black"></div>
    <video muted loop controls autoplay #video 
    (canplay)="video.play()" poster="./assets/img/header/bg_sw.jpg"
    (loadedmetadata)="video.muted = true" class="absolute top-0 bottom-0 left-0 right-0 m-auto">
        <source src="./assets/video/bg_dark_video_SWC2020.mp4?autoplay=1" type="video/mp4">
        <img class="w-full h-screen" src="./assets/img/header/bg_sw.jpg"/>
    </video>
  </div>
</header>