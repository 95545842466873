import buildingDataStore from "../stores/building-data.store"
import { SWCalculatorTypes } from "../types/sw-calculator.types"
import { SWExporterTypes } from "../types/sw-exporter.types"
import { Effect } from "./effect.model"

export class Building {
    id: SWExporterTypes.BuildingType = -1
    private _level: number =0
    
    metadata: SWCalculatorTypes.BuildingMetaData
    effect: Effect

    constructor(id: number, level: number) {
        this.id = id
        this._level = level
        this.metadata = buildingDataStore.GetMetadataFromType(this.id)
        this.effect = new Effect({
            type: this.metadata.effectType,
            value: this.computeTotalBonus(this._level),
            effect_reducer: 1, 
            gems: 0,
            grindstones:0,
        })
    }
    
    static CreateFromBuilding(building: Building) : Building{
        return new Building(building.id, building._level)
    }

    get level(): number {
        return this._level
    }
    set level(newLevel: number) {
        // ensure we do not set a level > maxLevel or < minLevel
        newLevel = newLevel >= this.metadata.minLevel ? newLevel : this.metadata.minLevel
        newLevel = newLevel <= this.metadata.maxLevel ? newLevel : this.metadata.maxLevel
        this._level = newLevel

        this.effect = new Effect({
            type: this.metadata.effectType,
            value: this.computeTotalBonus(this._level),
            effect_reducer: 1,
            gems: 0,
            grindstones:0,
        })
    }

    computeTotalBonus(level: number) {
        let bonus = level >0 ? this.metadata.baseBonus : 0
        level = level-1 <0 ? 0 : level-1
        // baseBonus is fixed. bonus Per level can be a float so we floor if final bonus is between x and y,
        return Math.floor(bonus + (level)* this.metadata.bonusPerLevel)
    }

    get image() {
        return "assets/img/buildings/" + SWExporterTypes.BuildingType[this.id].split(" ").join("_").toLowerCase() + ".png"
    }
}

