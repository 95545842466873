import {SWCalculatorTypes} from "../types/sw-calculator.types"
import {SWExporterTypes} from "../types/sw-exporter.types"
import { Effect, InGameEffect } from "./effect.model"

export function emptyArtifactSlot() {
    return {
        [SWExporterTypes.ArtifactSlot.ELEMENT]: null,
        [SWExporterTypes.ArtifactSlot.TYPE]: null
    }
}

export class Artifact implements SWCalculatorTypes.Artifact {
    type: SWExporterTypes.Archetype
    attribute: SWExporterTypes.Attribute
    slot: SWExporterTypes.ArtifactSlot
    extra: number 
    rank: number
    level: number
    primaryEffect: Effect
    secondaryEffects: InGameEffect[] = []

    constructor(data: SWCalculatorTypes.Artifact) {
        // create effect
        this.primaryEffect = new Effect(data.primaryEffect)
        
        for(let i in data.secondaryEffects ) {
            this.secondaryEffects.push(new InGameEffect(data.secondaryEffects[i]))
        }
        this.slot = data.slot
        this.type = data.type
        this.level = data.level
        this.attribute = data.attribute
        this.extra = data.extra
        this.rank = data.rank
    }

    get image(): string {
        let artifact_type = this.slot == 1 ? 
            "element_" + SWExporterTypes.Attribute[this.attribute].toLowerCase()
            : "type_" + SWExporterTypes.Archetype[this.type].toLowerCase()
        return 'assets/img/artifacts/element-type/' + artifact_type + ".png"
    }
}

