<nav class="fixed top-0 z-50 w-full navbar" (scroll)="onWindowScroll();">
    <div class="navbar-container relative flex items-center justify-between h-16 w-full">
      <div class="flex items-center justify-between w-full">
        <div class="flex-shrink-0 flex items-center">
          <a routerLink="/" class="flex flex-row items-center">
              <span class="text-title text-white text-3xl mr-4">SwLab</span>
              <img class="block h-8 w-auto" src="./assets/img/sw-neutron-logo.png" alt="Logo">
          </a>
        </div>
        <div class="ml-6">
          <div class="nav-links flex space-x-4">
            <a href="#" class="link btn-nav" routerLink="/monsters" routerLinkActive="active">
                Monsters</a>

            <a href="#" class="link btn-nav" routerLink="/runes" [routerLinkActive]="['active']">
                Runes</a>

            <a href="#" class="link btn-nav" routerLink="/labs" [routerLinkActive]="['active']">
              Labs</a> 

            <a href="#" *ngIf="!checked" class="link btn-nav" routerLink="/artifacts" [routerLinkActive]="['active']">
                Artifacts</a> 

            <a href="#" *ngIf="!checked" class="link btn-nav" routerLink="/contributors" [routerLinkActive]="['active']">
              Contributors</a> 

            <a href='https://ko-fi.com/Z8Z85HYNF' target='_blank' class="flex text-white">
              <button  mat-button class="mx-3 p-4 whitespace-nowrap btn-secondary" type="button">
                Ko-fi<fa-icon class="w-5 h-5 mx-2" [icon]="mugHot"></fa-icon>
              </button>
            </a>
            <a href="https://twitter.com/SwLab_" target="_blank" *ngIf="!checked" class="link btn-nav">
              <img class="wh-20px" src="./assets/img/contrib/twitter.png"></a>
          </div>
        </div>
      </div>
    </div>
</nav>
<nav class="mob-nav h-16 bg-sw-darkGrey fixed">
  <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu">
  <label for="openSidebarMenu" class="sidebarIconToggle">
    <div class="spinner diagonal part-1"></div>
    <div class="spinner horizontal"></div>
    <div class="spinner diagonal part-2"></div>
  </label>
  <div id="sidebarMenu">
    <ul class="sidebarMenuInner">
      <li>
        <a href="#" routerLink="/monsters" routerLinkActive="active">
          Monsters</a>
      </li>
      <li>
        <a href="#" routerLink="/runes" [routerLinkActive]="['active']">
            Runes</a>
      </li>
      <li>
        <a href="#" routerLink="/labs" [routerLinkActive]="['active']">
            Labs</a>  
      </li>
      <li>
        <a href="#" *ngIf="!checked" routerLink="/artifacts" [routerLinkActive]="['active']">
            Artifacts</a> 
      </li>
      <li>
        <a href="#" *ngIf="!checked" routerLink="/contributors" [routerLinkActive]="['active']">
          Contributors</a> 
      </li>
    </ul>
  </div>
</nav>
