import { Injectable } from '@angular/core';
import { CompressionService } from './compression.service';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {

    private localStorageVersion = 3.2

    constructor(private storageCompression: CompressionService) {
        let storedVersion = this.Get("version")
        if (!storedVersion || storedVersion < this.localStorageVersion)  {
            this.RemoveAll()
        }
        this.Set("version", this.localStorageVersion)
    }

    RemoveAll() {
        localStorage.clear()
    }

    Get<T=any>(id: string): T {
        let t = localStorage.getItem(id)
        if (t != undefined) {
            return JSON.parse(this.storageCompression.decompressString(t)) as T
        }
        return null
    }

    Set<T=any>(id: string, obj: T) {

        localStorage.setItem(id, this.storageCompression.compressString(JSON.stringify(obj)))
    }

    Remove(id: string) {
        localStorage.removeItem(id);
    }
}