import { Component, OnInit } from '@angular/core';
/* FontAwesome Icons */
import { faCheckCircle, faTimesCircle, faPlusCircle, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { Building } from 'src/app/core/models/building.model';
import { Caracteristics, Effect, zeroedCaracteristics } from 'src/app/core/models/effect.model';
import { Rune } from 'src/app/core/models/rune.model';
import { Unit } from 'src/app/core/models/unit.model';
import runeSetDataStore from 'src/app/core/stores/rune-set-data.store';
import { SWExporterTypes } from 'src/app/core/types/sw-exporter.types';
import { BuildingService } from 'src/app/services/building.service';
import { ImportService } from 'src/app/services/import.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { StatsCalculatorService } from 'src/app/services/stats-calculator.service';
import { SwarFarmAPIService } from 'src/app/services/swarfarm-api.service';

@Component({
  selector: 'app-lushen',
  templateUrl: './lushen.component.html',
  styleUrls: ['./lushen.component.scss']
})
export class LushenComponent implements OnInit {
  /* Data */
  buildings: Building[] = [];
  actualMaxSpd: number;
  //
  selectedRune: Rune;
  showSelectLushen: boolean = false;
  /* FontAwesome Icons */
  checkCircle = faCheckCircle;
  timeCircle = faTimesCircle;
  plusCircle = faPlusCircle;
  edit = faPenSquare;
  //
  LushenBoostedAtk: number;
  bossReduction: number;
  finalDmg: number;
  // Lushen's Hits
  firstHitHp: number;
  secondHitHp: number;
  thirdHitHp: number;
  /* Bonus Stats */
  fightSet: number = 0;
  guildSkill: 0 | 3 | 5 =0;
  /* Skill Bonus */
  skillUp: number = 30;
  skillCrit: number = 0;
  /* Artifacts Bonus Damage */
  dmgBonusPerAtk: number =0;
  dmgBonusPerHp: number =0;
  dmgBonusPerDef: number = 0;
  dmgBonusPerSpeed: number = 0;
  dmgBonusThirdSkill: number = 0;
  dmgDealtOnWater: number = 0;
  critBonusGoodHp: number = 0;
  critBonusBadHp: number = 0;
  //
  additionalFightSet: number
  /* Lushens */
  lushens: Unit[] = []
  selectedLushen: Unit
  lushenDmgPerHit : number
  //
  selectedLushenCaracs: Caracteristics = zeroedCaracteristics()
  // Count total activated sets
  runeSetCount: Map<SWExporterTypes.SetType,number>

  isValidLushen: boolean;
  atkBuff: boolean;
  defBuff: boolean;
  speedBuff: boolean;

  spdBonus: number = 0;
  defBonus: number = 0;
  // 
  lushensCaracs : Caracteristics[] = []
  //Team 
  meliasCaracs: Caracteristics[] = []
  melias: Unit[] = []
  actualMaxSpdMeliaFirst: number;
  actualMaxSpdMeliaSecond: number;
  actualMaxAccuracyMeliaFirst: number;
  actualMaxAccuracyMeliaSecond: number;

  sathCaracs: Caracteristics[] = []
  saths: Unit[] = []
  actualMaxSpdSath: number;
  actualMaxAccuracySath: number;

  tatuCaracs: Caracteristics[] = []
  tatus: Unit[] = []
  actualMaxSpdTatu: number;
  actualMaxAccuracyTatu: number;
  requiredSpd: boolean = false;

  dungeons = [
    {name: 'Giant B12', waveHp: 26235},
    {name: 'Dragon B12', waveHp: 28350},
    {name: 'Necropolis B12', waveHp: 18945},
    {name: 'Punisher Crypt B10', waveHp: 24045},
    {name: 'Steel Fortress B10', waveHp: 21615}
  ];
  selectedDungeon: any;

  constructor(
    private buildingService: BuildingService,
    private browserStorage: LocalStorageService, 
    private statCalculator: StatsCalculatorService, 
    private importService: ImportService, 
    private swarfarm: SwarFarmAPIService,
    private swarfarmApi: SwarFarmAPIService
  ) { }

  async ngOnInit() {
    this.selectedDungeon = 26235
    this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
      SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.WIND_SANCTUARY,
      SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);

    let t = this.browserStorage.Get<Unit>("lushen_select")
    if (t) {
      this.onLushenSelect(new Unit(t))
    }

    this.melias = this.importService.GetUnits().filter(n => n.id == 22113).map(n => new Unit(n));
    // Handle the case of less than 2 melia found.
    if(this.melias.length < 2) {
      let base_melia = await this.swarfarm.GetMonsterById(22113)
      for(let i = this.melias.length ; i <2; i++) {
        this.melias.push(new Unit(base_melia)) // Copy base melia until there is in the array.
      }
    }

    this.saths = this.importService.GetUnits().filter(n => n.id == 16032).map(n => new Unit(n));
    // Handle the case of less than 2 melia found.
    if(this.saths.length < 1) {
      let base_sath = await this.swarfarm.GetMonsterById(16032)
      for(let i = this.saths.length ; i <1; i++) {
        this.saths.push(new Unit(base_sath)) // Copy base saths until there is in the array.
      }
    }

    this.tatus = this.importService.GetUnits().filter(n => n.id == 10332).map(n => new Unit(n));
    // Handle the case of less than 2 tatu found.
    if(this.tatus.length < 1) {
      let base_tatu = await this.swarfarm.GetMonsterById(10332)
      for(let i = this.tatus.length ; i <1; i++) {
        this.tatus.push(new Unit(base_tatu)) // Copy base tatu until there is in the array.
      }
    }

    this.lushens = this.importService.GetUnits().filter(n => n.name == "Lushen").map(n => new Unit(n));
    if (this.lushens.length == 0) {
      let base_lushen = await this.swarfarmApi.GetMonsterByName("Lushen")
      this.onLushenSelect(new Unit(base_lushen))
    }

    this.guildSkill = 5;
    //
    this.computeTatu()
    this.computeSath();
    this.computeMelias();
    this.computeLushen();
  }

  TEAM_REQUIRED_SPEED = 169
  LUSHEN_LEAD_SKILL=33 // Atk Percent
  GOLEM_HP: number
  GOLEM_REDUCTION=0.6
  NECRO_REDUCTION=0.85

  computeSath() {
    this.sathCaracs = this.saths.map((n, i) => {
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: SWExporterTypes.EffectType.SPEEDPercent,
        value: this.LUSHEN_LEAD_SKILL
      })
      // 
      return this.statCalculator.ComputeStats(this.saths[i], this.saths.filter((n, index) => index != i), [], this.buildings, this.guildSkill)
    })
    this.actualMaxSpdSath = Math.max(...this.sathCaracs.map(n => Math.round(n.Spd)))
  }

  computeTatu() {  
    this.tatuCaracs = this.tatus.map((n, i) => {
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: SWExporterTypes.EffectType.SPEEDPercent,
        value: this.LUSHEN_LEAD_SKILL
      })
      // 
      return this.statCalculator.ComputeStats(this.tatus[i], this.tatus.filter((n, index) => index != i), [], this.buildings, this.guildSkill)
    })
    this.actualMaxSpdTatu = Math.max(...this.tatuCaracs.map(n => Math.round(n.Spd)))
  }

  computeMelias() {  
    this.meliasCaracs = this.melias.map((n, i) => {
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: SWExporterTypes.EffectType.SPEEDPercent,
        value: this.LUSHEN_LEAD_SKILL
      })
      // 
      return this.statCalculator.ComputeStats(this.melias[i], this.melias.filter((n, index) => index != i), [], this.buildings, this.guildSkill)
    })
    this.actualMaxSpdMeliaFirst = Math.max(this.meliasCaracs.map(n => Math.round(n.Spd))[0])
    this.actualMaxSpdMeliaSecond = Math.max(this.meliasCaracs.map(n => Math.round(n.Spd))[1])
  }

  computeLushen() {
    this.GOLEM_HP =  this.selectedDungeon 
    console.log(this.GOLEM_HP)
    if (this.selectedLushen != undefined ) {
      // TODO store lead effects in unit.
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: SWExporterTypes.EffectType.ATKPercent,
        value: 33
      })

      let additionalSets = []
      for (let i=0; i < this.fightSet; i++) {
        additionalSets.push(runeSetDataStore.GetRuneSetFromType(SWExporterTypes.SetType.FIGHT))
      }
      
      this.selectedLushenCaracs = this.statCalculator.ComputeStats(this.selectedLushen, [], [leaderEffect], this.buildings, this.guildSkill, additionalSets)

      let targetDef = 0
      let attackMultiplier = 0.68

      if(this.atkBuff){
        attackMultiplier = 1.08
      }

      let hits = this.statCalculator.ComputeSkillHit(
        this.selectedLushenCaracs, {
          multipliers: [],
          attackMultiplier,
          defMultiplier: 0,
          livingAllyMultiplier: 0,
          maxHpTargetMultiplier: 0,
          maxHpMultiplier: 0,
          spdAdd: 0,
          spdDivider: 0,
          targetSpd: 0,
          livingAlly: 0,
          hitCount: 1,
          skillUpBonus: this.skillUp,
          selectedSkill: {}
        },
        this.dmgBonusThirdSkill,
        0,
        this.critBonusGoodHp,
        this.critBonusBadHp,
        targetDef,
        false,
        false,
        0
      )
      
        // add additional theory deterset count.
      this.lushensCaracs = this.lushens.map((n, i) => {
        let leaderEffect = new Effect({
          effect_reducer: 1,
          gems: 0,
          grindstones: 0,
          type: SWExporterTypes.EffectType.ATKPercent,
          value: 33
        })
        // 
        return this.statCalculator.ComputeStats(this.lushens[i], this.lushens.filter((n, index) => index != i), [leaderEffect], this.buildings, this.guildSkill)
      })

      let artifactBonusDamage = this.statCalculator.ComputeArtefactBonusDamage(
        this.selectedLushenCaracs, 
        this.dmgBonusPerSpeed, this.dmgBonusPerDef,this.dmgBonusPerHp,this.dmgBonusPerAtk
      )
      
      this.finalDmg = (1+ (this.dmgDealtOnWater/100)) * hits.minDamage + artifactBonusDamage
      console.log(hits.averageDamage + artifactBonusDamage)
      
      if(this.selectedDungeon == 18945){
        this.firstHitHp = this.GOLEM_HP - (this.finalDmg * this.NECRO_REDUCTION)
        this.secondHitHp = this.firstHitHp - (this.finalDmg * this.NECRO_REDUCTION);
        this.thirdHitHp = this.secondHitHp - (this.finalDmg * this.NECRO_REDUCTION);
      } else {
        this.firstHitHp = this.GOLEM_HP - this.finalDmg;
        this.secondHitHp = this.firstHitHp - this.finalDmg; 

        if(this.selectedDungeon == 26235){
          this.thirdHitHp = this.secondHitHp - (this.finalDmg * this.GOLEM_REDUCTION);
        } else {
          this.thirdHitHp = this.secondHitHp - this.finalDmg;
        }
      }

      if(this.thirdHitHp <= 0) {
        this.isValidLushen = true;
      } else {
        this.isValidLushen = false;
      }
    }
    this.actualMaxSpd = Math.max(this.selectedLushenCaracs.Spd)
  }

  onChange() {
    this.computeTatu()
    this.computeSath();
    this.computeMelias();
    this.computeLushen();
  }

  onRuneSelect(rune: Rune): void {
    this.selectedRune = rune;
  }
  onLushenSelect(lushen): void {
    this.selectedLushen = lushen;

    this.getLushenArtifacts();
    this.computeLushen();

    this.showSelectLushen = true;
    this.browserStorage.Set<Unit>("lushen_select", this.selectedLushen);
  }

  showSelect(){
    this.browserStorage.Remove("lushen_select");
    this.selectedLushen = undefined
    this.showSelectLushen = false
    this.finalDmg = undefined
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    this.critBonusGoodHp =0 
    this.critBonusBadHp =0
    this.dmgBonusThirdSkill =0
    this.dmgDealtOnWater =0
  }

  getKeys(obj:any){
    return Object.keys(obj)
  }

  getEffectTypeString(effectType: SWExporterTypes.EffectType) {
    return Effect.typeStr[effectType]
  }

  getLushenArtifacts(){
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    this.critBonusGoodHp =0 
    this.critBonusBadHp =0
    this.dmgBonusThirdSkill =0
    this.dmgDealtOnWater =0
    for(let artifact in this.selectedLushen.artifacts){
      if(this.selectedLushen.artifacts[artifact] != undefined){
        for(let sec_eff in this.selectedLushen.artifacts[artifact].secondaryEffects){
          let effect = this.selectedLushen.artifacts[artifact].secondaryEffects[sec_eff];
          switch(effect.type) {
            case 218:
              this.dmgBonusPerHp += effect.value
              break;
            case 219: 
              this.dmgBonusPerAtk += effect.value
              break;
            case 220: 
              this.dmgBonusPerDef += effect.value
              break;
            case 221: 
              this.dmgBonusPerSpeed += effect.value
              break;
            case 222: 
              this.critBonusGoodHp += effect.value 
              break;           
            case 223: 
              this.critBonusBadHp += effect.value
              break;
            case 402: 
              this.dmgBonusThirdSkill += effect.value
              break;
            case 301: 
              this.dmgDealtOnWater += effect.value
              break;
          }
        }
      }
    }
  }

  array(n: number) {
    return Array(n)
  }
}