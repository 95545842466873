/* Angular*/
import { Component, Inject, OnInit } from '@angular/core';
/* FontAwesome Icons */
import { faCheckCircle, faTimesCircle, faPlusCircle, faChevronCircleRight, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { Building } from 'src/app/core/models/building.model';
import { Unit } from 'src/app/core/models/unit.model';
import { ImportService } from 'src/app/services/import.service';
import { SwarFarmAPIService } from 'src/app/services/swarfarm-api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Caracteristics, Effect, zeroedCaracteristics } from 'src/app/core/models/effect.model';
import { StatsCalculatorService } from 'src/app/services/stats-calculator.service';
import { SWExporterTypes } from 'src/app/core/types/sw-exporter.types';
import { Rune } from 'src/app/core/models/rune.model';
import runeSetDataStore from 'src/app/core/stores/rune-set-data.store';
import { BuildingService } from 'src/app/services/building.service';
import { MatDialog } from '@angular/material/dialog';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-bj5',
  templateUrl: './bj5.component.html',
  styleUrls: ['./bj5.component.scss']
})
export class Bj5Component implements OnInit {
  isBaleygrFromStorage: boolean = false
  showSelectBaleygr: boolean = false
  /* FontAwesome Icons */
  checkCircle = faCheckCircle;
  timeCircle = faTimesCircle;
  plusCircle = faPlusCircle;
  chevronRight = faChevronCircleRight;
  edit = faPenSquare;
  /* Baleygr Ehp & Dmg check */
  validMinHit: boolean;
  validMaxHit: boolean;
  validAverageHit: boolean;
  validEhp: boolean;
  
  hp: number = 0;
  eHp: number = 0;
  def: number = 0;
  spd: number = 0;
  atk: number = 0;
  crit_damage: number = 0;
  crit_rate: number = 0;
  /* Bonus Stats */
  fightSet: number = 12;
  selectFightSetCount: number;

  guildSkill: 0 | 3 | 5 = 5;
  atkLeader: number = 40;
  atkPercentBonus: number = 0;
  /* FrontLine Requirement EhP */
  frontLine: number = 2;
  requiredEhP: number;
  //
  baleygrs: Unit[] = []
  selectedBaleygr: Unit
  //
  selectedBaleygrCaracs: Caracteristics = zeroedCaracteristics()
  /* Baleygr Stats Max/Min Damage For last hit */
  baleygrAtk: number;
  baleygrBoostedAtk: number;
  maxHit: number;
  minHit: number;
  averageHit: number;
  /* Skill Bonus */
  skillCrit: number = 0;
  skillUp: number = 30;
  /* Artifacts Bonus Damage */
  dmgBonusPerAtk: number = 0;
  dmgBonusPerHp: number = 0;
  dmgBonusPerDef: number = 0;
  dmgBonusPerSpeed: number = 0;

  /* team */
  team: any;
  selectedTeam: any;

  loren: any = [];
  colleen: any = [];
  janssen: any = [];
  lead: any = [];
  revive: any = [];

  checked = false;
  disabled = false;

  selected=-1;

  // update
  units: Unit[]
  //team: Unit[]
  buildings: Building[]
  baleygr: Unit
  selectedRune: any
  

  constructor(
    private buildingService: BuildingService,
    private importService: ImportService, 
    private statCalculator: StatsCalculatorService,
    private swarfarmApi: SwarFarmAPIService, 
    private browserStorage: LocalStorageService,
    public dialog: MatDialog) {}

  async ngOnInit() {
    this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
      SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.FIRE_SANCTUARY,
      SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);

    let t = this.browserStorage.Get<Unit>("baleygr_select")
    if(t) {
      this.onBaleygrSelect(new Unit(t))
    }

    this.guildSkill = 5;
    //
    this.baleygrs = this.importService.GetUnits().filter(n => n.name == "Baleygr").map(n => new Unit(n));
    if (this.baleygrs.length == 0) {
      let base_baleygr = await this.swarfarmApi.GetMonsterByName("Baleygr")
      this.onBaleygrSelect(new Unit(base_baleygr))
    }

    this.team = Array.prototype.concat(this.loren, this.colleen, this.lead, this.janssen, this.revive);
    /* Import Buildings Mapping */
    /* Display Unit */
    this.computeBaleygr();
    this.selectTeam();
  }

  REQUIRED_HIT_DAMAGE = 32000
  DRAGON_DEFENSE = 801

  computeBaleygr() {

    if (this.selectedBaleygr != undefined ) {
    // TODO add leads skills.
      let leaderEffect = new Effect({
        effect_reducer: 1,
        grindstones: 0,
        gems: 0,
        type: SWExporterTypes.EffectType.ATKPercent,
        value: this.atkLeader
      })

      let team = [] //[this.loren, this.colleen, this.janssen, this.revive, this.lead]
      let additionalSets = []
      for (let i=0; i < this.fightSet; i++) {
        additionalSets.push(runeSetDataStore.GetRuneSetFromType(SWExporterTypes.SetType.FIGHT))
      }
      this.selectedBaleygrCaracs = this.statCalculator.ComputeStats(this.selectedBaleygr, team, [leaderEffect], this.buildings, this.guildSkill, additionalSets)
      this.eHp = this.statCalculator.ComputeEHP(this.selectedBaleygrCaracs)
     
      let baleygrSkill3MagicConstant = 1.4

      let hits = this.statCalculator.ComputeSkillHit(
        this.selectedBaleygrCaracs,
        {
          multipliers: [],
          attackMultiplier: 4,
          defMultiplier: 0,
          livingAllyMultiplier: 0,
          maxHpTargetMultiplier: 0,
          maxHpMultiplier: 0,
          spdAdd: 0,
          spdDivider: 0,
          targetSpd: 0,
          livingAlly: 0,
          hitCount: 2,
          skillUpBonus: this.skillUp,
          selectedSkill: {}
        },
        this.skillCrit,
        this.atkPercentBonus,
        0,
        0, 
        this.DRAGON_DEFENSE,
        false,
        true,
        0
      )

      let artifactBonusDamage = this.statCalculator.ComputeArtefactBonusDamage(
        this.selectedBaleygrCaracs,  
        this.dmgBonusPerSpeed, this.dmgBonusPerDef, this.dmgBonusPerHp, this.dmgBonusPerAtk
      )
      this.averageHit = hits.averageDamage * baleygrSkill3MagicConstant + artifactBonusDamage
      this.minHit = hits.minDamage * baleygrSkill3MagicConstant + artifactBonusDamage;
      this.maxHit = hits.maxDamage * baleygrSkill3MagicConstant + artifactBonusDamage;

      if(this.frontLine == 2){
        this.requiredEhP = 116186;
      }
      if(this.frontLine == 3){
        this.requiredEhP = 105041;
      }
      if(this.frontLine == 4){
        this.requiredEhP = 99666;
      }

      if(this.minHit >= 32000){
        this.validMinHit = true;
      } else {
        this.validMinHit = false;
      }

      if(this.maxHit >= 32000){
        this.validMaxHit = true;
      } else {
        this.validMaxHit = false;
      }

      if(this.averageHit >= 32000){
        this.validAverageHit = true;
      } else {
        this.validAverageHit = false;
      }

      if(this.eHp > this.requiredEhP){
        this.validEhp = true;
      } else {
        this.validEhp = false;
      }
    }
  }

  onChange() {
    this.selectTeam();
    this.computeBaleygr();
  }

  onBaleygrSelect(baleygr): void {
    this.selectedBaleygr = baleygr;

    this.getBaleygrArtifacts();
    this.computeBaleygr();

    this.showSelectBaleygr = true;
    this.browserStorage.Set<Unit>("baleygr_select", this.selectedBaleygr);
  }

  selectTeam(){
    console.log(this.leadFilter())
    this.team = this.importService.GetUnits().filter(
      n => n.name == "Loren" || n.name == "Janssen"
      || n.name == "Dagora" || n.name == "Colleen"
    ).map(n => new Unit(n))
  }

  showSelect(){
    this.browserStorage.Remove("baleygr_select");
    this.selectedBaleygr = undefined
    this.showSelectBaleygr = false  
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    this.skillCrit =0 
    this.atkPercentBonus =0
  }

  onRuneSelect(rune: Rune): void {
    this.selectedRune = rune;
  }

  getBaleygrArtifacts(){
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    this.skillCrit =0 
    this.atkPercentBonus =0
    for(let artifact in this.selectedBaleygr.artifacts){
      if(this.selectedBaleygr.artifacts[artifact] != undefined){
        for(let sec_eff in this.selectedBaleygr.artifacts[artifact].secondaryEffects){
          let effect = this.selectedBaleygr.artifacts[artifact].secondaryEffects[sec_eff];

          switch(effect.type) {
            case 218:
              this.dmgBonusPerHp += effect.value
              break;
            case 219: 
              this.dmgBonusPerAtk += effect.value
              break;
            case 220: 
              this.dmgBonusPerDef += effect.value
              break;
            case 221: 
              this.dmgBonusPerSpeed += effect.value
              break;
            case 402: 
              this.skillCrit += effect.value
              break;
            case 204: 
              this.atkPercentBonus += effect.value
              break;
          }
        }
      }
    }
  }

  getKeys(obj:any){
    return Object.keys(obj)
  }

  getEffectTypeString(effectType: SWExporterTypes.EffectType) {
    return Effect.typeStr[effectType]
  }

  array(n: number) {
    return Array(n)
  }

  nameFilter(name: string) {
    return (unit: Unit) => unit.name == name
  }

  leadFilter() {
    return (unit: Unit) => unit.id == 16112 || unit.id == 16102 || unit.id == 15512 || unit.id == 15502
    || unit.id == 19813 || unit.id == 19803 || unit.id == 11712 || unit.id == 11702
    || unit.id == 12012 || unit.id == 12002 || unit.id == 11212 || unit.id == 11202
    || unit.id == 23712 || unit.id == 23702 || unit.id == 18015
  }

  reviveFilter() {
    return (unit: Unit) => unit.id == 10711 || unit.id == 22412 || unit.id == 22402
  }
}