<div *ngIf="artifact" class="artifact flex flex-col border border-sw-orange bg-clearBlack justify-between mw-28em flex-1">
  <div class="artifact_main_stat w-full flex flex-col justify-center items-center mb-2">
    <span class="text-sw-orange px-4 pt-4">+{{ artifact.level }}
      <!-- Fix Artifact Type -->
      <!-- <ng-container *ngIf="artifact.slot == 1">
        {{artifact.attribute}}
      </ng-container>
      <ng-container *ngIf="artifact.slot == 2">
        {{artifact.type}}
      </ng-container> -->
      Artifact
    </span>
    <div class="rune_img flex justify-around flex-nowrap flex-row w-full px-6 align-middle items-center">
      <div class="rune_img flex items-center justify-center relative">
        <img class="artifact_element absolute wi-1" src="{{artifact.image}}">
        <img *ngIf="artifact.slot == 1" class="wh-40px artifact_element" src="../../../assets/img/artifacts/rarity/artifact_element_legendary.png">
        <img *ngIf="artifact.slot == 2" class="wh-40px artifact_element" src="../../../assets/img/artifacts/rarity/artifact_type_legendary.png">
      </div>
      <div class="flex flex-row justify-between">
        <span class="px-2 text-white">{{getEffectTypeString(artifact.primaryEffect.type)}}</span>
        <span>+{{artifact.primaryEffect.value}}</span>
      </div>
    </div>
  </div>
  <div class="flex flex-col flex-1 justify-center p-4">
    <ng-container *ngFor="let sec_eff of artifact.secondaryEffects">
      <div class="flex flex-row">
        <span class="px-2 text-white">{{getInGameEffectTypeString(sec_eff.type)}}</span>
        <span class="text-green">{{sec_eff.value}}%</span>
      </div>
    </ng-container>
  </div>
</div>