
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { LocalStorageService } from "./local-storage.service";

import { SwarFarmApiTypes} from "../core/types/swarfarm.types"
import { Unit } from "../core/models/unit.model";
import { SWCalculatorTypes } from "../core/types/sw-calculator.types";
import { emptyRuneSlot } from "../core/models/rune.model";
import { emptyArtifactSlot } from "../core/models/artifact.model";
import { SWExporterTypes } from "../core/types/sw-exporter.types";

@Injectable({
    providedIn: 'root',
})
export class SwarFarmAPIService {
    private apiUrl = "https://sw.kowoshira.me/api"
    /* TODO : Fix Hardcoded page count */
    private numberOfMonsterPage = 19
    private numberOfSkillPage = 31
    private monsters: Unit[] = []
    private skills: any = []

    constructor(private http: HttpClient, private browserStorage: LocalStorageService) {
        // load from local storage.
        let localMonsters = this.browserStorage.Get<any>("swarfarm_monsters");
        let localSkills = this.browserStorage.Get<any>("swarfarm_skills");
        this.monsters = localMonsters ? localMonsters : []
        this.skills = localSkills ? localSkills : []
    }

    private async APIRequest(apiPath) : Promise<any> {
        let url = this.apiUrl + apiPath;
        return this.http
                .get<any>(url)
                .toPromise();
    }

    private async APIAllPagesRequest<T>(apiPath: string, startPage: number, endPage: number): Promise<(T extends readonly (infer InnerArr)[] ? InnerArr : T)[]> {
        let apiCallPromises = []
        for(let i = startPage; i < endPage; i++){
            apiCallPromises.push(this.APIRequest(apiPath + "/?page=" + i));
        }
        let allResults = (await Promise.all(apiCallPromises)).map( x => x.results as T ).flat();
        return allResults
    }

    async GetAllSkills(): Promise<any> {
        if (this.skills.length == 0) {
            let allResults = await this.APIAllPagesRequest<any>("/v2/skills", 1, this.numberOfSkillPage)
    
            this.skills = allResults
            this.browserStorage.Set<any>("swarfarm_skills", this.skills)
        }
        return this.skills
    }

    async GetAllMonsters() : Promise<Unit[]> {

        if (this.monsters.length == 0)
        {
            let allResults = await this.APIAllPagesRequest<SwarFarmApiTypes.Monster>("/v2/monsters", 1, this.numberOfMonsterPage)
            this.monsters = allResults.map(monster => 
                this.CreateNewUnitFromSwarFarmAPI(monster)
            )
            this.browserStorage.Set<any>("swarfarm_monsters", this.monsters)
        }
        this.GetAllSkills();
        return this.monsters
    }

    async GetMonsterByName(name: string) : Promise<Unit>{
        // TODO find a faster way to retrieve specific monster
        // FIXme call swarfarmapi if needed.
        let foundMonster =  this.monsters.filter(n => n.name == name)[0]
        
        if (foundMonster) {
            return foundMonster
        }
        else {
            console.log("seeking for all monsters")
            // Temporarly perform all monster requests (but will need to look for single monster request.)
            await this.GetAllMonsters()
            return this.GetMonsterByName(name)
        }
    }

    async GetMonsterById(id: number) : Promise<Unit>{
        // TODO find a faster way to retrieve specific monster
        // FIXme call swarfarmapi if needed.
        let foundMonster =  this.monsters.filter(n => n.id == id)[0]
        
        if (foundMonster) {
            return foundMonster
        }
        else {
            console.log("seeking for all monsters")
            // Temporarly perform all monster requests (but will need to look for single monster request.)
            await this.GetAllMonsters()
            return this.GetMonsterById(id)
        }
    }
    
    private GetAttributeFromString(attribute: string) : SWExporterTypes.Attribute{
      return SWExporterTypes.Attribute[attribute.toUpperCase()] 
    }

    private GetArchetypeFromString(archetype: string) : SWExporterTypes.Archetype{
      return SWExporterTypes.Archetype[archetype.toUpperCase()] 
    }

    private CreateNewUnitFromSwarFarmAPI(monster: SwarFarmApiTypes.Monster) : Unit{
        return new Unit(
            {
                id: monster.com2us_id,
                name: monster.name,
                level: 40, // set to max level
                image: monster.image_filename,
                attribute: this.GetAttributeFromString(monster.element),
                archetype: this.GetArchetypeFromString(monster.archetype),
                runes: emptyRuneSlot(),
                artifacts: emptyArtifactSlot(),
                skills: monster.skills,
                spells: [],
                baseCaracteristics: {
                  [SWCalculatorTypes.CaracteristicsType.HEALTH]: monster.max_lvl_hp,
                  [SWCalculatorTypes.CaracteristicsType.ATTACK]: monster.max_lvl_attack,
                  [SWCalculatorTypes.CaracteristicsType.DEFENSE]: monster.max_lvl_defense,
                  [SWCalculatorTypes.CaracteristicsType.SPEED]: monster.speed,
                  [SWCalculatorTypes.CaracteristicsType.CRIT_DAMAGE]: monster.crit_damage,
                  [SWCalculatorTypes.CaracteristicsType.CRIT_RATE]: monster.crit_rate,
                  [SWCalculatorTypes.CaracteristicsType.ACCURACY]: monster.accuracy,
                  [SWCalculatorTypes.CaracteristicsType.RESISTANCE]: monster.resistance,
                  [SWCalculatorTypes.CaracteristicsType.WILL]: 0,
                  [SWCalculatorTypes.CaracteristicsType.SHIELD]: 0,
                  [SWCalculatorTypes.CaracteristicsType.REPLAY_CHANCE]: 0,
                  [SWCalculatorTypes.CaracteristicsType.STUN_CHANCE]: 0,
                  [SWCalculatorTypes.CaracteristicsType.LIFE_STEAL]: 0,
                  [SWCalculatorTypes.CaracteristicsType.ATB_MISSING_HEALTH]: 0,
                  [SWCalculatorTypes.CaracteristicsType.COUNTER_ATTACK_CHANCE]: 0,
                  [SWCalculatorTypes.CaracteristicsType.HPPercent_DESTRUCTION]: 0,
                }
            }
        )    
    }
}