import { Component, OnInit } from '@angular/core';
import { ImportService } from 'src/app/services/import.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-siege',
  templateUrl: './siege.component.html',
  styleUrls: ['./siege.component.scss']
})
export class SiegeComponent implements OnInit {

  wizard_data: any

  constructor(private browserStorage: LocalStorageService, private importService :ImportService) {
    this.wizard_data = this.browserStorage.Get("wizard_data");
  }

  ngOnInit(): void {
    console.log(this.wizard_data)
  }
}
