import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@m0t0r/ngx-slider';
/* Angular Material */
import { MaterialModule } from "./module/material.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NgSelectModule } from '@ng-select/ng-select';
/* Routing */
import { AppRoutingModule } from "./app-routing.module";
/* Component */
import { AppComponent } from "./app.component";
import { RunsComponent } from './views/runs/runs.component';
import { Bj5Component } from './views/bj5/bj5.component';
import { InfosComponent } from './views/infos/infos.component';
import { ResumeComponent } from './views/resume/resume.component';
import { RunesComponent } from "./views/runes/runes.component";
import { ImportDataComponent } from "./views/import-data/import-data.component";
import { MonstersComponent } from "./views/monsters/monsters.component";
import { IcaruComponent } from './views/icaru/icaru.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LushenComponent } from './views/lushen/lushen.component';
/* NgxParser */
import { NgxCsvParserModule } from 'ngx-csv-parser';
/* FONT AWESOME */
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
/* Pipe */
import { ReplacePipe } from './core/replace.pipe';
import { MinuteSecondsPipe } from './core/format.pipe';
import { EnumPipe } from './core/enum.pipe';
/* DialogBox */
import { loadingDialog } from './views/import-data/import-data.component';
import { loadingDialogCsv } from './views/runs/runs.component';
import { dialogParams } from './views/monsters/monsters.component';
import { RuneCardComponent } from "./shared/rune-card/rune-card.component";
import { ArtifactCardComponent } from "./shared/artifact-card/artifact-card.component";
import { UnitSelectorComponent } from "./shared/unit-selector/unit-selector.component";
import { RuneSecondaryStatComponent } from "./shared/rune-secondary-stat/rune-secondary-stat.component";
import { MediaCardComponent } from "./shared/media-card/media-card.component";
import { RuneFilterComponent } from "./shared/filters/rune-filter.component";
import { CompressionService } from "./services/compression.service";
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ArtifactsComponent } from './views/artifacts/artifacts.component';
import { ContributorsComponent } from './views/contributors/contributors.component';
import { SiegeComponent } from './views/siege/siege.component';
import { LabHubComponent } from './views/lab-hub/lab-hub.component';

@NgModule({
  declarations: [
    AppComponent,
    ImportDataComponent,
    MonstersComponent, 
    RunesComponent,
    loadingDialog,
    dialogParams,
    RunsComponent,
    loadingDialogCsv,
    ReplacePipe,
    MinuteSecondsPipe,
    Bj5Component,
    InfosComponent,
    ResumeComponent,
    IcaruComponent, 
    FooterComponent,
    LushenComponent,
    RuneCardComponent,
    ArtifactCardComponent,
    UnitSelectorComponent,
    RuneSecondaryStatComponent,
    MediaCardComponent,
    RuneFilterComponent,
    EnumPipe,
    NavbarComponent,
    ArtifactsComponent,
    ContributorsComponent,
    SiegeComponent,
    LabHubComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxCsvParserModule,
    FormsModule,
    AppRoutingModule,
    NgxSliderModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    CompressionService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
