import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Unit } from "src/app/core/models/unit.model";
import { ImportService } from "src/app/services/import.service";
import { SwarFarmAPIService } from "src/app/services/swarfarm-api.service";

@Component({
    selector: 'unit-selector',
    templateUrl: './unit-selector.template.html',
    styleUrls: ['../../../styles.css']
})
export class UnitSelectorComponent implements OnInit {

    @Input() filter : (unit: Unit)=> boolean = (n) => true
    @Input() title: string
    @Input() selectedUnit: Unit
    @Output() onSelectChange = new EventEmitter<Unit>();

    units: Unit[]=[]


    constructor(private importer: ImportService, private swarfarmAPI: SwarFarmAPIService) {
        this.title = "Unit Select"
        
    }

    ngOnChanges() {
// Ugly hack to make selection work correctly with equal object but strictly inequal...
        let strictSelected = this.units.filter(n => n === this.selectedUnit)
        if (strictSelected.length == 0) {
            let newSelected = this.units.filter(n => JSON.stringify(n) == JSON.stringify(this.selectedUnit))
            if (newSelected.length > 0) {
                this.selectedUnit = newSelected[0]
            }
            else {
                let t = this.units.filter(n =>{
                     return false
                })
            }
        }
    }

    async ngOnInit() {
        this.units = this.importer.GetUnits().filter(this.filter).map(n => new Unit(n))
    }

    onSelectUnit() {
        this.onSelectChange.emit(this.selectedUnit)
    }

    isSame(unit1: Unit, unit2: Unit) {
        let t = JSON.stringify(unit1) === JSON.stringify(unit2)
        console.log(t)
        console.log(unit1)
        console.log(unit2)
        return t
    }
}
// TODO create unit small card component.