/* Angular*/
import { Component, Directive, OnInit } from "@angular/core";
import {Router} from '@angular/router';
/* FontAwesome Icons */
import { faCheckCircle, faTimesCircle, faFileImport, faFolder, faTrash } from "@fortawesome/free-solid-svg-icons";
/* Angular Material */
import { MatDialog } from '@angular/material/dialog';
import { ImportService } from "src/app/services/import.service";

/* File State */
export enum ImportState {
  none,
  importing,
  imported,
  loaded,
}

@Component({
  selector: "app-import-data",
  templateUrl: "./import-data.component.html",
  styleUrls: ["./import-data.component.scss"],
})
export class ImportDataComponent implements OnInit {
  showSide = false;
  /* Set Import State */
  ImportState = ImportState; 
  importState = ImportState.none;
  selectedFile: File;
  /* FontAwesome Icons */
  checkCircle = faCheckCircle;
  timeCircle = faTimesCircle;
  file = faFileImport;
  folder = faFolder;
  trash = faTrash;
  /* Data */
  data: any;
  checked: boolean = false;

  constructor(private importService:ImportService, public dialog: MatDialog, private route:Router) {}

  ngOnInit() {
    this.data = this.importService.GetWizardData();
    // TODO find another way to retrieve this "checked state."
    this.checked = JSON.parse(localStorage.getItem("isChecked"));
  }

  onFileLoad(event) {
    this.importState = ImportState.none;
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, "UTF-8");
    fileReader.onload = () => {
      this.data = JSON.parse(fileReader.result as string);
      this.importState = ImportState.loaded;
    };
  }

  async importJson() {
    this.importState = ImportState.importing;
    await this.importService.Import(this.data)
    this.importState = ImportState.imported;
    this.dialog.closeAll();
    this.route.navigate(['/monsters']);
  }

  clearData(){
    this.importService.Clear();
    window.location.reload();
  }

  onChange(checked: boolean) {
    this.checked = checked;
    localStorage.setItem("isChecked", JSON.stringify(this.checked));
    window.location.reload();
  }

  openDialog() {
    this.dialog.open(loadingDialog, { disableClose: true });
  }
}

@Component({
  selector: 'loadingDialog',
  templateUrl: 'loadingDialog.html',
  styleUrls: ["./loading-dialog.scss"],
})
export class loadingDialog {}

@Directive({
  selector: 'video[autoplay]',
  host: {
      'autoplay': '',
      'oncanplay': 'this.play()',
      'onloadedmetadata': 'this.muted = true'
  }
})
export class VideoAutoplayDirective {}