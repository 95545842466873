<div class="main-container"> 
  <div class="full h-32 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl z-20 flex flex-row items-center">BJ5 Lab <img class="wh-40px" src="./assets/img/sw-neutron-logo.png"></h1>
    <div class="absolute h-32 w-full bg-sw-darkGrey opacity-90 z-10"></div>
  </div>
  <div class="flex flex-row w-full gap-4 p-4 content-container">
    <div class="result-baleygr flex flex-col w-10/12 gap-4">
      <div class="flex flex-row p-4 gap-4 bg-sw-mediumGrey rounded-2xl flex-wrap whitespace-nowrap w-full justify-center">
        <div class="flex flex-col items-center p-4 text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Baleygr required Dmg: </span>
            <span class="m-2 text-xl text-secondary">+/- 32,000</span>
          </div>
          <span class="text-sm">To Kill Khi'zar Kha'jul beast Lvl.5</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="mr-2">Min Hit <span class="text-green">{{ minHit | number: '1.0-0' }}</span></span>
              <fa-icon *ngIf="validMinHit" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="!validMinHit" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
            <div class="flex flex-1 items-center p-4 justify-between">
              <span class="mr-2">Average Hit <span class="text-green">{{ averageHit | number: '1.0-0' }}</span></span>
              <fa-icon *ngIf="validAverageHit" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="!validAverageHit" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="mr-2">Max Hit <span class="text-green">{{ maxHit | number: '1.0-0' }}</span></span>
              <fa-icon *ngIf="validMaxHit" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="!validMaxHit" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
        <div class="flex flex-col flex-1 p-4 items-center text-white justify-between bg-sw-darkGrey rounded-2xl">
          <div clas="flex items-center text-left mx-2">
            <span>Required EHP:</span>
            <span class="m-2 text-xl text-secondary">{{requiredEhP | number: '1.0-0'}}</span>
          </div>
          <span class="text-sm">To Tank Khi'zar Kha'jul beast Lvl.5</span>
          <div class="flex flex-row items-center mt-2 border-t-soft">
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="ml-2 text-xl">
                with
                <mat-form-field class="input-number small" appearance="fill">
                  <input class="input-number small" matInput [(ngModel)]="frontLine" step="1" type="number" min="2" max="4" #ctrl="ngModel" (ngModelChange)="onChange()">
                </mat-form-field>
                units in front
              </span>
            </div>
          </div> 
        </div>
        <div class="flex flex-col flex-1 p-4 items-center text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Actual Baleygr EHP </span>
            <span class="m-2 text-xl text-secondary"></span>
          </div>
          <span class="text-sm">To Tank Khi'zar Kha'jul beast Lvl.5</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="mr-2"><span class="text-green">{{this.eHp | number: '1.0-0'}}</span></span>
              <fa-icon *ngIf="validEhp" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="!validEhp" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="flex flex-row flex-wrap whitespace-nowrap w-full bg-sw-mediumGrey text-lg border-t items-center rounded-2xl">
          <div class="bonus flex flex-wrap p-4 gap-4">
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2">Fight Set</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="fightSet" step="1" type="number" min="0" max="12" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2">Guild Skill</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="guildSkill" step="3" type="number" min="2" max="5" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <img class="w-20px h-20px rounded-2xl" src="./assets/img/balgr.jpg">
              <span class="mx-2">S3 Skill Up</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="skillUp" step="15" type="number" min="0" max="30" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mx-2">Atk Leader</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="atkLeader" step="1" type="number" min="0" max="50" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full gap-4">
        <div class="baleygr flex flex-col flex-wrap p-4 bg-sw-mediumGrey rounded-2xl">
          <mat-spinner [diameter]="65" class="m-auto" *ngIf="baleygrs.length == 0 && !selectedBaleygr"></mat-spinner>
          <div class="baleygrs" *ngIf="!selectedBaleygr">
            <ng-container *ngFor="let baleygr of baleygrs">
              <div class="bg-sw-darkGrey rounded-2xl px-4 py-4 mb-2">
                <div class="baleygr_title flex flex-row flex-wrap items-center text-center rounded-2xl">
                  <img class="wh-40px rounded-2xl" src="./assets/img/balgr.jpg">
                  <div class="flex flex-col mx-4">
                    <h2 class="text-2xl">{{ baleygr.name }}</h2> 
                    <span class="text-left">Lvl. {{baleygr.level}}</span>
                  </div>
                  <button mat-button class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide" (click)="onBaleygrSelect(baleygr)">Select Baleygr</button>
                </div>
                <div class="flex w-full justify-center">
                  <div *ngFor="let slot of getKeys(baleygr.runes)" (click)="onRuneSelect(baleygr.runes[slot])">
                    <div class="rune_img flex items-center justify-start cursor-pointer mt-2">
                      <img *ngIf="baleygr.runes[slot]?.slotImage" class="wh-40px" src="{{ baleygr.runes[slot]?.slotImage }}" />
                      <img *ngIf="baleygr.runes[slot]?.setImage" class="rune_element wh-20px absolute ml-10px" ngClass="_{{ baleygr.runes[slot]?.setType }}"
                        src="{{ baleygr.runes[slot]?.setImage }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container> 
          </div>
          <div class="baleygr_title flex flex-row justify-between items-center text-center bg-sw-darkGrey p-4 rounded-2xl" *ngIf="selectedBaleygr">
            <img class="wh-40px rounded-2xl" src="./assets/img/balgr.jpg">
            <h2 class="text-2xl mx-4">Baleygr</h2>
            <button mat-button class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide"  (click)="showSelect()" *ngIf="baleygrs.length > 0">Change Baleygr</button>
          </div>
          <div class="flex flex-col" *ngIf="selectedBaleygr">
            <div class="baleygr_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
              <div class="level flex pb-2">
                <div class="w-full flex justify-between px-2">
                  <span>Lvl. {{selectedBaleygr.level}}</span>
                  <span class="flex justify-start text-sw-mediumGrey">Attack</span>
                </div>
              </div>
              <div class="hp flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">HP</span>
                  <span>{{selectedBaleygr.baseCaracteristics.Health}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.bonusCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.manualAdditionalCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="atk flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">ATK</span>
                  <span>{{selectedBaleygr.baseCaracteristics.Attack}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.bonusCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.manualAdditionalCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="def flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">DEF</span>
                  <span>{{selectedBaleygr.baseCaracteristics.Defense}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedBaleygr.bonusCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.manualAdditionalCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="speed flex pb-4">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">SPD</span>
                  <span>{{selectedBaleygr.baseCaracteristics.Spd}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedBaleygr.bonusCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.manualAdditionalCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <br>
              <div class="critRate flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Crit Rate</span>
                  <span>{{selectedBaleygr.baseCaracteristics["Critical Rate"]}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedBaleygr.bonusCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.manualAdditionalCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="critDamage flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Crit Dmg</span>
                  <span>{{selectedBaleygr.baseCaracteristics["Critical Damage"]}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedBaleygr.bonusCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedBaleygr.manualAdditionalCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-row bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg justify-center">
              <div *ngFor="let slot of getKeys(selectedBaleygr.runes)" (click)="onRuneSelect(selectedBaleygr.runes[slot])">
                <div class="rune_img flex items-center justify-start cursor-pointer" *ngIf="selectedBaleygr.runes[slot] !== null">
                  <img *ngIf="selectedBaleygr.runes[slot]?.slotImage" class="wh-40px" src="{{ selectedBaleygr.runes[slot]?.slotImage }}" />
                  <img *ngIf="selectedBaleygr.runes[slot]?.setImage" class="rune_element wh-20px absolute ml-10px" ngClass="_{{ selectedBaleygr.runes[slot]?.setType }}"
                    src="{{ selectedBaleygr.runes[slot]?.setImage }}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" flex flex-col justify-between p-4 bg-sw-mediumGrey rounded-2xl flex-1 opacity-30">
          <div class="flex flex-row justify-between items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
              <h2 class="text-2xl mx-4">Team Selection comming soon</h2>
              <button mat-button class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide" disabled>Change Team</button>
          </div>
          <div class="bg-sw-darkGrey p-2 mt-4 rounded-2xl flex-1 flex items-center justify-center text-6xl">
          </div>
          <div class="flex flex-row justify-between items-center text-center bg-sw-darkGrey p-4 rounded-2xl mt-4">
            <h2 class="text-2xl mx-4"></h2>
          </div>
        </div>
      </div>
      <div class="buildings flex flex-wrap">
        <div class="building flex flex-row flex-wrap text-lg  bg-sw-mediumGrey rounded-2xl p-4 gap-4 w-full">
          <ng-container *ngFor="let building of buildings">
            <div class="flex flex-col flex-1 items-center p-4 text-center bg-sw-darkGrey rounded-2xl">
              <div class="flex flex-row align-middle items-center">  
                <img class="wh-40px p-1 rounded-full" src="{{building.image}}">
                <span class="text-sw-yellow p-1">{{building.metadata.name}}</span>
              </div>
              <div class="flex flex-wrap flex-row items-center p-1 justify-center">
                <fa-icon class="px-2" [icon]="plusCircle"></fa-icon>
                <span>Level</span>
                <ng-select [(ngModel)]="building.level" (ngModelChange)="onChange()" class="ml-2">
                  <ng-option *ngFor="let item of array(building.metadata.maxLevel+1); let i = index" [value]="i">{{i}}</ng-option>
                </ng-select>
              </div>
              <span class="capitalize">{{building.computeTotalBonus(building.level)}} - {{getEffectTypeString(building.effect.type)}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="artifacts w-2/12">
      <div class="flex flex-col p-4 rounded-2xl bg-sw-mediumGrey gap-4">
        <div class="artifact_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
          <img class="wh-40px" src="./assets/img/artifacts/rarity/artifact_element_legendary.png">
          <h2 class="text-2xl px-2">Artifact Bonus</h2>
        </div>
        <div class="flex flex-row flex-wrap flex-1 gap-4">
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Def</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerDef" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Hp</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerHp" step="0.1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Speed</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerSpeed" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Atk</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerAtk" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Skill 3 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="skillCrit" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Increase Atk% effect + N% </span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="atkPercentBonus" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
