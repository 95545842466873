<div class="main-container"> 
  <div class="full h-32 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl z-20 flex flex-row items-center">Tricaru Lab <img class="wh-40px" src="./assets/img/sw-neutron-logo.png"></h1>
    <div class="absolute w-full h-32 bg-sw-darkGrey opacity-90 z-10"></div>
  </div>
  <div class="flex flex-row w-full gap-4 p-4 content-container">
    <div class="result-verde flex flex-col w-10/12 gap-4">
      <div class="flex flex-row p-4 gap-4 bg-sw-mediumGrey rounded-2xl flex-wrap whitespace-nowrap w-full justify-center">
        <div class="flex flex-col items-center p-4 text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Min Tricaru's Def Required:</span>
            <span class="m-2 text-xl text-secondary">{{tricaruMinDef | number: '1.0-0'}}</span>
          </div>
          <span class="text-sm">To coop with 3 other units</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div *ngFor="let icaru of icarus; let i=index" class="flex flex-1 p-4 justify-between items-center max-w-xxs" [class.notOk]="icaru.additionalCaracteristics.Defense < tricaruMinDef">
              <span class="mr-2"><span class="text-green">{{icaru.additionalCaracteristics.Defense | number : '1.0-0'}}</span> Def</span>
              <fa-icon *ngIf="icaru.additionalCaracteristics.Defense >= tricaruMinDef" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="icaru.additionalCaracteristics.Defense < tricaruMinDef" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
        <div class="flex flex-col items-center p-4 text-white justify-between bg-sw-darkGrey rounded-2xl">
          <div clas="flex items-center text-left mx-2">
            <span>Min Tricaru's Accuracy Required:</span>
            <span class="m-2 text-xl text-secondary">30</span>
          </div>
          <span class="text-sm">To land break def</span>
          <div class="flex flex-row items-center mt-2 border-t-soft">
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="mr-2">Max Icaru Accuracy <span class="text-green">{{ actualMaxAccuracy | number: '1.0-0'}}</span></span>
              <fa-icon *ngIf="actualMaxAccuracy >= 30" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="actualMaxAccuracy < 30" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
        <div class="flex flex-col items-center p-4 text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Required EHP: </span>
            <span class="m-2 text-xl text-secondary">81,000</span>
          </div>
          <span class="text-sm">Tank 1 hit regardless the conditions</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center" [class.notOk]="eHpVerde < VERDE_MIN_EHP">
              <span class="mr-2">Actual Verde EHP <span class="text-green">{{this.eHpVerde | number: '1.0-0'}}</span></span>
              <fa-icon *ngIf="eHpVerde >= VERDE_MIN_EHP" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="eHpVerde < VERDE_MIN_EHP" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
        <div class="flex flex-col items-center p-4 text-white justify-between bg-sw-darkGrey rounded-2xl">
          <div clas="flex items-center text-left mx-2">
            <span>Verde Min Dmg /Per hit </span>
            <span class="m-2 text-xl text-secondary">+/- {{MIN_FINAL_DAMAGE}}</span>
          </div>
          <span class="text-sm">To defeat Dragons</span>
          <div class="flex flex-row items-center border-t-soft mt-2">
            <div class="flex flex-1 p-4 justify-between items-center" [class.notOk]="finalDmg < MIN_FINAL_DAMAGE">
              <span class="mr-2">Actual Verde Dmg /Per hit <span class="text-green">{{ finalDmg | number: '1.0-0'}}</span></span>
              <fa-icon *ngIf="finalDmg >= MIN_FINAL_DAMAGE" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="finalDmg < MIN_FINAL_DAMAGE" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
        <div class="flex flex-col items-center p-4 text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Verde Required Crit Rate: </span>
            <span class="m-2 text-xl text-secondary">100%</span>
          </div>
          <span class="text-sm">To Boost Atb every hit</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="mr-2">Actual Verde Crit Rate 
                <span class="text-green">{{ verdehileCritRate | number: '1.0-0' }} %</span>
              </span>
              <fa-icon *ngIf="verdehileCritRate >= 100" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="verdehileCritRate < 100" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div> 
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="flex flex-row flex-wrap whitespace-nowrap w-full bg-sw-mediumGrey text-lg border-t items-center rounded-2xl">
          <div class="bonus flex flex-wrap p-4 gap-4">
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2">Fight Set</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="fightSet" step="1" type="number" min="0" max="12" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mx-2">Deter. Set</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="determinationSet" step="1" type="number" min="0" max="50" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2">Guild Skill</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="guildSkill" step="3" type="number" min="2" max="5" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <img class="w-20px h-20px rounded-2xl" src="./assets/img/verde.jpg">
              <span class="mx-2">S1 Skill Up</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="skillUp" step="15" type="number" min="0" max="30" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full gap-4">
        <div class="tricaru flex flex-row flex-wrap p-4 gap-4 bg-sw-mediumGrey rounded-2xl flex-1">
          <div class="icaru flex flex-col flex-wrap flex-1 bg-sw-mediumGrey min-w-96" *ngFor="let icaru of icarus">
            <div class="icaru_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
              <img class="wh-40px" src="./assets/img/icaru.jpg">
              <h2 class="text-2xl px-2">Icaru</h2>
            </div>
            <div class="icaru-flex flex flex-row">
              <div class="flex flex-col w-full">
                <div class="icaru_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
                  <div class="level flex pb-2">
                    <div class="w-full flex justify-between px-2">
                      <span>Lvl. {{icaru.level}}</span>
                    </div>
                  </div>
                  <div class="hp flex">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">HP</span>
                      <span>{{icaru.baseCaracteristics.Health}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<span>{{icaru.bonusCaracteristics.Health}}</span>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="atk flex">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">ATK</span>
                      <span>{{icaru.baseCaracteristics.Attack}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<span>{{icaru.bonusCaracteristics.Attack}}</span>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>                  
                  <div class="def flex">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">DEF</span>
                      <span>{{icaru.baseCaracteristics.Defense}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<input type="number" step="1"
                        [(ngModel)]="icaru.bonusCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="speed flex pb-4">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">SPD</span>
                      <span>{{icaru.baseCaracteristics.Spd}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<span>{{icaru.bonusCaracteristics.Spd}}</span>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>
                  <br>
                  <div class="critRate flex">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">Crit Rate</span>
                      <span>{{icaru.baseCaracteristics["Critical Rate"]}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<span>{{icaru.bonusCaracteristics["Critical Rate"]}}</span>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="critDamage flex">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">Crit Dmg</span>
                      <span>{{icaru.baseCaracteristics["Critical Damage"]}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<span>{{icaru.bonusCaracteristics["Critical Damage"]}}</span>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="acc flex">
                    <div class="w-7/12 flex justify-between px-2">
                      <span class="text-sw-yellow">Accuracy</span>
                      <span>{{icaru.baseCaracteristics["Accuracy"]}}</span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-green">
                        +<input type="number" step="1"
                        [(ngModel)]="icaru.bonusCaracteristics['Accuracy']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                    <div class="w-5/12 px-2">
                      <span class="flex justify-start text-sw-red">
                        +<input type="number" step="1"
                            [(ngModel)]="icaru.manualAdditionalCaracteristics['Accuracy']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                            <fa-icon [icon]="edit"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg justify-center">
                  <div *ngFor="let slot of getKeys(icaru.runes)" (click)="onRuneSelect(icaru.runes[slot])">
                    <div class="rune_img flex items-center justify-start cursor-pointer" *ngIf="icaru.runes[slot] !== null">
                      <img *ngIf="icaru.runes[slot]?.slotImage" class="wh-40px" src="{{ icaru.runes[slot]?.slotImage }}" />
                      <img *ngIf="icaru.runes[slot]?.setType" class="rune_element wh-20px absolute ml-10px" ngClass="_{{ icaru.runes[slot]?.setType }}"
                        src="{{ icaru.runes[slot]?.setImage }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="verde flex flex-1 flex-col bg-sw-mediumGrey rounded-2xl">
            <mat-spinner [diameter]="65" class="m-auto" *ngIf="verdehiles.length == 0 && !selectedVerdehile"></mat-spinner>
            <div class="verdehiles" *ngIf="!selectedVerdehile">
              <ng-container *ngFor="let verde of verdehiles">
                <div class="bg-sw-darkGrey rounded-2xl px-4 py-4 mb-2">
                  <div class="baleygr_title flex flex-row flex-wrap items-center text-center rounded-2xl">
                    <img class="wh-40px rounded-2xl" src="./assets/img/verde.jpg">
                    <div class="flex flex-col mx-4">
                      <h2 class="text-2xl">{{ verde.name }}</h2> 
                      <span class="text-left">Lvl. {{verde.level}}</span>
                    </div>
                    <button mat-button class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide" (click)="onVerdeSelect(verde)">Select verde</button>
                  </div>
                  <div class="flex w-full justify-center">
                    <div *ngFor="let slot of getKeys(verde.runes)" (click)="onRuneSelect(verde.runes[slot])">
                      <div class="rune_img flex items-center justify-start cursor-pointer mt-2">
                        <img *ngIf="verde.runes[slot]?.slotImage" class="wh-40px" src="{{ verde.runes[slot]?.slotImage }}" />
                        <img *ngIf="verde.runes[slot]?.setType" class="rune_element wh-20px absolute ml-10px" ngClass="_{{ verde.runes[slot]?.setType }}"
                          src="{{ verde.runes[slot]?.setImage }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container> 
            </div>
            <div class="baleygr_title flex flex-row justify-between items-center text-center bg-sw-darkGrey p-4 rounded-2xl" *ngIf="selectedVerdehile">
              <img class="wh-40px rounded-2xl" src="./assets/img/verde.jpg">
              <h2 class="text-2xl mx-4">Verdehile</h2>
              <button mat-button class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide"  (click)="showSelect()" *ngIf="verdehiles.length > 0">Change Verdehile</button>
            </div>
            <div class="flex flex-col" *ngIf="selectedVerdehile">
              <div class="baleygr_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
                <div class="level flex pb-2">
                  <div class="w-full flex justify-between px-2">
                    <span>Lvl. {{selectedVerdehile.level}}</span>
                    <span class="flex justify-start text-sw-mediumGrey">Attack</span>
                  </div>
                </div>
                <div class="hp flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">HP</span>
                    <span>{{selectedVerdehile.baseCaracteristics.Health}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.bonusCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                          <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.manualAdditionalCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                          <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="atk flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">ATK</span>
                    <span>{{selectedVerdehile.baseCaracteristics.Attack}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.bonusCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                          <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.manualAdditionalCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                          <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="def flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">DEF</span>
                    <span>{{selectedVerdehile.baseCaracteristics.Defense}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1"
                      [(ngModel)]="selectedVerdehile.bonusCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.manualAdditionalCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                          <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="speed flex pb-4">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">SPD</span>
                    <span>{{selectedVerdehile.baseCaracteristics.Spd}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1"
                      [(ngModel)]="selectedVerdehile.bonusCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.manualAdditionalCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                          <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <br>
                <div class="critRate flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">Crit Rate</span>
                    <span>{{selectedVerdehile.baseCaracteristics["Critical Rate"]}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1"
                      [(ngModel)]="selectedVerdehile.bonusCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.manualAdditionalCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="critDamage flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">Crit Dmg</span>
                    <span>{{selectedVerdehile.baseCaracteristics["Critical Damage"]}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1"
                      [(ngModel)]="selectedVerdehile.bonusCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<input type="number" step="1"
                          [(ngModel)]="selectedVerdehile.manualAdditionalCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="acc flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">Accuracy</span>
                    <span>{{selectedVerdehile.baseCaracteristics["Accuracy"]}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span>{{selectedVerdehile.baseCaracteristics["Accuracy"]}}</span>
                    </span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-red">
                      +<span>{{selectedVerdehile.manualAdditionalCaracteristics["Accuracy"]}}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-row bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg justify-center">
                <div *ngFor="let slot of getKeys(selectedVerdehile.runes)" (click)="onRuneSelect(selectedVerdehile.runes[slot])">
                  <div class="rune_img flex items-center justify-start cursor-pointer" *ngIf="selectedVerdehile.runes[slot] !== null">
                    <img *ngIf="selectedVerdehile.runes[slot]?.slotImage" class="wh-40px" src="{{ selectedVerdehile.runes[slot]?.slotImage }}" />
                    <img *ngIf="selectedVerdehile.runes[slot]?.setType" class="rune_element wh-20px absolute ml-10px" ngClass="_{{ selectedVerdehile.runes[slot]?.setType }}"
                      src="{{ selectedVerdehile.runes[slot]?.setImage }}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buildings flex flex-wrap">
        <div class="building flex flex-row flex-wrap text-lg  bg-sw-mediumGrey rounded-2xl p-4 gap-4 w-full">
          <ng-container *ngFor="let building of buildings">
            <div class="flex flex-col flex-1 items-center p-4 text-center bg-sw-darkGrey rounded-2xl">
              <div class="flex flex-row align-middle items-center">  
                <img class="wh-40px p-1 rounded-full" src="{{building.image}}">
                <span class="text-sw-yellow p-1">{{building.metadata.name}}</span>
              </div>
              <div class="flex flex-wrap flex-row items-center p-1 justify-center">
                <fa-icon class="px-2" [icon]="plusCircle"></fa-icon>
                <span>Level</span>
                <ng-select [(ngModel)]="building.level" (ngModelChange)="onChange()" class="ml-2">
                  <ng-option *ngFor="let item of array(building.metadata.maxLevel+1); let i = index" [value]="i">{{i}}</ng-option>
                </ng-select>
              </div>
              <span class="capitalize">{{building.computeTotalBonus(building.level)}} - {{getEffectTypeString(building.effect.type)}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="artifacts w-2/12">
      <div class="flex flex-col p-4 rounded-2xl bg-sw-mediumGrey gap-4">
        <div class="artifact_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
          <img class="wh-40px" src="./assets/img/artifacts/rarity/artifact_element_legendary.png">
          <h2 class="text-2xl px-2">Artifact Bonus</h2>
        </div>
        <div class="flex flex-row flex-wrap gap-4">
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Def</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerDef" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Hp</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerHp" step="0.1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Speed</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerSpeed" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Atk</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerAtk" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Skill 1 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusFirstSkill" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Increase Atk% effect + N% </span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnFire" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center p-4 w-full">
    <span><a href="https://www.reddit.com/r/summonerswar/comments/ivt9gv/triple_icaru_min_stat_requirement_math/" class="text-center" target="_blank">Based on a Reddit post made by Shinedday</a></span>
  </div>
</div> 
