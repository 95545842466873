/* Angular*/
import { Component, ViewChild } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
/* Angular Material */
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormControl } from '@angular/forms';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
/* FontAwesome */
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ImportService } from "src/app/services/import.service";
import { SWExporterTypes } from "src/app/core/types/sw-exporter.types";
import { Effect, InGameEffect } from "src/app/core/models/effect.model";
import runeSetDataStore from "src/app/core/stores/rune-set-data.store";
import { Rune } from "src/app/core/models/rune.model";
import { RuneFilterService } from "src/app/services/rune-filter.service";
import { SwarFarmAPIService } from "src/app/services/swarfarm-api.service";
import { Unit } from "src/app/core/models/unit.model";

@Component({
  selector: "app-runes",
  templateUrl: "./runes.component.html",
  styleUrls: ["../../../styles.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class RunesComponent {
  isLoading = false;
  /* FontAwesome Icons */
  star = faStar;
  /* Data */
  runes: any = [];
  /* Table */
  dataSource: any;

  displayedColumns: string[] = [
    "rune_set",
    "unitImage",
    "setType",
    "stars",
    "upgradeLevel",
    "rune_main_stat",
    "innate_stat",
    "actual_efficiency",
    "max_efficiency",
    "atkPercent",
    "defPercent",
    "hpPercent",
    "spd",
    "critRate",
    "critDdmg",
    "accuracy",
    "resistance",
    "atkFlat",
    "defFlat",
    "hpFlat",
    // "sell_value",
  ];

  selection = new SelectionModel<any>(true, []);
  expandedElement: any;
  /* Table Unequiped Rune */
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("matSortTotal", { static: true }) matSortTotal: MatSort;
  @ViewChild("matTablePaginatorStorage", { static: true })
  matTablePaginatorStorage: MatPaginator;

  constructor(private importService: ImportService, private runeFilterService: RuneFilterService, private swarFarmAPIService: SwarFarmAPIService) {}
  
  ngOnInit() {
    //TODO store subscription and unsubscribe.
    this.runeFilterService.getFilteredRune().subscribe((runes) => {
      this.dataSource = new MatTableDataSource(runes);

      // /* Define Datasource for table */
      /* Sort filter for nested object */
      this.dataSource.sortingDataAccessor = (item: Rune, property) => {
        switch (property) {
          case "rune_set":
            return item.slotFactor
          case "setType":
            return this.getSetName(item.setType);
          case "stars":
            return item.stars;
          case "upgradeLevel":
            return item.upgradeLevel;
          case "actual_efficiency": 
            return item.efficiency.current;
          case "max_efficiency": 
            return item.efficiency.max;
          case "rune_main_stat":
            return  this.getEffectTypeString(item.primaryEffect.type)
          case "atkPercent": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'ATK%'){
                return sec_eff.totalValue
              }
            }
            break;
          case "atkFlat": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'ATK'){
                return sec_eff.totalValue
              }
            }
            break;
          case "defPercent": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'DEF%'){
                return sec_eff.totalValue
              }
            }
            break;
          case "defFlat": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'DEF'){
                return sec_eff.totalValue
              }
            }
            break;
          case "hpPercent": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'HP%'){
                return sec_eff.totalValue
              }
            }
            break;
          case "hpFlat": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'HP'){
                return sec_eff.totalValue
              }
            }
            break;
          case "spd": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'SPD'){
                return sec_eff.totalValue
              }
            }
            break
          case "accuracy": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'Acc'){
                return sec_eff.totalValue
              }
            }
            break;
          case "resistance": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'Res'){
                return sec_eff.totalValue
              }
            }
            break;
          case "critRate": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'C.Rate'){
                return sec_eff.totalValue
              }
            }
            break;
          case "critDdmg": 
            for(let sec_eff of item.secondaryEffects){
              if(this.getEffectTypeString(sec_eff.type) === 'C.Dmg'){
                return sec_eff.totalValue
              }
            }
            break;
          default:
            return item[property];
        }
      };

      this.dataSource.filterPredicate = function (
        data,
        filter: string,
      ): boolean {
          console.log()
          return (
            data.extra.unit_name?.toString().toLowerCase().includes(filter)
          );
      };
      this.dataSource.paginator = this.matTablePaginatorStorage;
      this.dataSource.sort = this.matSortTotal;
    });

    this.runeFilterService.ApplyFilters()
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.dataSource.filterSub;
  }
  
  getEffectTypeString(effectType: SWExporterTypes.EffectType) {
    return Effect.typeStr[effectType]
  }

  getSetName(setType: SWExporterTypes.SetType) {
    return runeSetDataStore.GetRuneSetFromType(setType).name
  }

  getExtraName(extra: SWExporterTypes.Extra){
    return SWExporterTypes.Extra[extra % 10];
  }
}
