<div  *ngIf="rune" class="rune flex flex-col h-full w-full border border-sw-orange bg-clearBlack justify-between">
  <div class="rune_main_stat flex flex-col justify-center w-full items-center mb-2">
    <span class="text-sw-orange p-4">+{{ rune.upgradeLevel }} {{ getSetName(rune.setType) }} ({{
      rune.slotFactor
    }})</span>
    <div class="rune_img flex flex-nowrap flex-col w-24">
      <span class="flex w-full text-white justify-start ml-1 mt-1 z-10 px-2 -mb-10px">
        <img class="wi-1 lazyload" src="assets/img/rune/star-awakened.png">
        {{ rune.stars }}
      </span>
      <div class="rune_img flex items-center justify-center relative">
        <img class="absolute z-0 w-full lazyload" src="{{ rune.backgroundImage }}">
        <img class="z-10 w-50px lazyload" src="{{ rune.slotImage }}" />
        <img class="rune_element w-20px absolute z-10 lazyload" ngClass="_{{ rune.slotFactor }}"
        src="{{ rune.setImage }}"
        />
      </div>
      <span class="flex w-full justify-end mb-1 z-10 text-white px-2 -mt-5px">+{{ rune.upgradeLevel }}</span>
    </div>
    <div class="flex justify-center flex-row px-4 pt-4 pb-1 text-lg">
      <span class="text-sw-orange">
        {{ getEffectTypeString(rune.primaryEffect.type) | uppercase }}
      </span>
      <span class="text-white ml-4">
        +{{ rune.primaryEffect.value }}
      </span>
    </div>
    <span class="text-sw-yellow px-4 py-1" *ngIf="rune.innateEffect.value > 0">
      {{ getEffectTypeString(rune.innateEffect?.type) | uppercase }} +{{
        rune.innateEffect?.value
      }}
    </span>
    <span class="text-sw-yellow px-4 py-1" *ngIf="rune.innateEffect.value == 0">
      -/-
    </span>
  </div>
  <div class="rune_secondary_stat flex flex-col justify-between w-2/4 px-6 min-h-96">
    <ng-container *ngFor="let sub of rune.secondaryEffects">
      <div class="flex flex-row justify-between">
        <span class="text-sw-yellow">{{ getEffectTypeString(sub.type) }}</span>
        <span
          class="text-white ml-4"
          [class.text-sw-orange]="sub.grindstones > 0"
          >
          +{{ sub.value + sub.grindstones }}
        </span>
      </div>
    </ng-container>
  </div>
  <div class="flex w-full p-2 bg-gray-800 items-center justify-center mt-2">
    <img class="wh-20px mana lazyload" src="assets/img/mana-crystal.png" />
    <span class="text-center text-white ">{{ rune.sellValue | number: "2." }}</span>
  </div>
</div>