import { SWExporterTypes } from "../types/sw-exporter.types"
import { SWCalculatorTypes } from "../types/sw-calculator.types"
import runeSetDataStore from "../stores/rune-set-data.store"
import { Effect } from "./effect.model"
import { SwarFarmAPIService } from "src/app/services/swarfarm-api.service"

export function emptyRuneSlot() {
    return {
        [SWExporterTypes.RuneSlot.DOWN]: null,
        [SWExporterTypes.RuneSlot.DOWN_LEFT]: null,
        [SWExporterTypes.RuneSlot.DOWN_RIGHT]: null,
        [SWExporterTypes.RuneSlot.UP]: null,
        [SWExporterTypes.RuneSlot.UP_LEFT]: null,
        [SWExporterTypes.RuneSlot.UP_RIGHT]: null,
    }
}

export class Rune implements SWCalculatorTypes.Rune {
    setType: SWExporterTypes.SetType /// Use set ID.
    isAntique: boolean
    stars: number // 1 - 6
    extra: SWExporterTypes.Extra
    rank: SWExporterTypes.Rank
    slotFactor: SWExporterTypes.RuneSlot      

    sellValue: number

    maxUpgradeLevel: number
    upgradeLevel: number

    innateEffect: Effect
    primaryEffect: Effect
    secondaryEffects: Effect[] = []
    unitImage?: string = null

    constructor(data: SWCalculatorTypes.Rune) {
        this.setType = data.setType
        this.isAntique = data.isAntique
        this.slotFactor = data.slotFactor
        this.extra = data.extra
        this.rank = data.rank
        this.stars = data.stars
        this.sellValue = data.sellValue
        this.maxUpgradeLevel = data.maxUpgradeLevel
        this.upgradeLevel = data.upgradeLevel
        if (data.innateEffect) {
            this.innateEffect = new Effect(data.innateEffect)
        }
        this.primaryEffect = new Effect(data.primaryEffect)
        for (let i in data.secondaryEffects) {
            this.secondaryEffects.push(new Effect(data.secondaryEffects[i]))
        }
        this.unitImage = data.unitImage
    }


    get backgroundImage () : string {
    // TODO change antique modifier when new images are available.
        let rank =  this.rank % 10
        return "assets/img/rune/bg_" + SWExporterTypes.Rank[rank].toLowerCase() + (this.isAntique ? "": "") + ".png"
    }
    
    get slotImage () : string {
    // TODO change antique modifier when new images are available.
    return "assets/img/rune/rune_" + this.slotFactor + ".png"
    }

    get setImage() : string {
        let runeset = runeSetDataStore.GetRuneSetFromType(this.setType);
        let setTypeStr = "unknown"
        if (runeset != undefined)
          setTypeStr = runeset.name.toLowerCase()
        else {
            console.log(SWExporterTypes.SetType[this.setType])
        }
        return "assets/img/rune/rune_" + setTypeStr + ".png"
    }
    

    static subStatEfficiency: Map<SWExporterTypes.EffectType,number> = new Map([
        [SWExporterTypes.EffectType.HP, 1875],
        [SWExporterTypes.EffectType.HPPercent, 40],
        [SWExporterTypes.EffectType.ATK, 100],
        [SWExporterTypes.EffectType.ATKPercent, 40],
        [SWExporterTypes.EffectType.DEF, 100],
        [SWExporterTypes.EffectType.DEFPercent, 40],
        [SWExporterTypes.EffectType.SPEED, 30],
        [SWExporterTypes.EffectType.CRITRate, 30],
        [SWExporterTypes.EffectType.CRITDmg, 35],
        [SWExporterTypes.EffectType.RES, 40],
        [SWExporterTypes.EffectType.ACC, 40],
    ])
      
    static mainStatEfficiency: Map<SWExporterTypes.EffectType,number> = new Map([
        [SWExporterTypes.EffectType.HP, 2448],
        [SWExporterTypes.EffectType.HPPercent, 63],
        [SWExporterTypes.EffectType.ATK, 160],
        [SWExporterTypes.EffectType.ATKPercent, 63],
        [SWExporterTypes.EffectType.DEF, 160],
        [SWExporterTypes.EffectType.DEFPercent, 63],
        [SWExporterTypes.EffectType.SPEED, 42],
        [SWExporterTypes.EffectType.CRITRate, 58],
        [SWExporterTypes.EffectType.CRITDmg, 80],
        [SWExporterTypes.EffectType.RES, 64],
        [SWExporterTypes.EffectType.ACC, 64],
    ])


    get efficiency(): {current: number, max: number } {
        let ratio = 0.0;
    
        // main stat
        ratio += this.primaryEffect.value / Rune.mainStatEfficiency.get(this.primaryEffect.type)

        // sub stats
        this.secondaryEffects.forEach((effect) => {
            let value = effect.value + effect.grindstones
            ratio += value / Rune.subStatEfficiency.get(effect.type)
        })
    
        // innate stat
        if (this.innateEffect) {
            // Ensure innateEffect is set with a valid type. 
            // This is due to empty innate Effect creates an effect of type 0.
            // TODO investigate, there should be no effect here. Might be in the importer.
            if (Rune.subStatEfficiency.has(this.innateEffect.type)) {
                ratio += this.innateEffect.value / Rune.subStatEfficiency.get(this.innateEffect.type)
            }
        }
    
        let efficiency = (ratio / 2.8) * 100;
    
        return {
            current: ((ratio / 2.8) * 100),
            max: (efficiency + ((Math.max(Math.ceil((12 - this.upgradeLevel) / 3.0), 0) * 0.2) / 2.8) * 100),
         }
    }
}