import { Component, OnInit } from "@angular/core";

@Component({
    selector: "media-card",
    templateUrl: "./media-card.template.html",
    styleUrls: ["../../../styles.css"],
  })
  export class MediaCardComponent implements OnInit {
  
    constructor() {}
  
    ngOnInit() {

    }
  }