import { Component, Input, OnInit } from "@angular/core";
import { faSlidersH, faStar } from "@fortawesome/free-solid-svg-icons";
import { Effect } from "../../core/models/effect.model";
import { Rune } from "../../core/models/rune.model";
import runeSetDataStore from "../../core/stores/rune-set-data.store";
import { SWExporterTypes } from "../../core/types/sw-exporter.types";

@Component({
    selector: "rune-card",
    templateUrl: "./rune-card.template.html",
    styleUrls: ["../../../styles.css"],
  })
  export class RuneCardComponent implements OnInit {

    /* FontAwesome Icons */
    star = faStar;
    slider = faSlidersH;
    /* Table */

    @Input() rune?: Rune // rune item.
  
    constructor() {}
  
    ngOnInit() {

    }
  
    getEffectTypeString(effectType: SWExporterTypes.EffectType) {
      return Effect.typeStr[effectType]
    }
  
    getSetName(setType: SWExporterTypes.SetType) {
      return runeSetDataStore.GetRuneSetFromType(setType).name
    }
  
    getKeys(obj:any){
      return Object.keys(obj)
    }
  }