<div class="main-container"> 
  <div class="full h-56 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl z-20 mt-12 flex flex-row items-center">Artifact Lab <img class="wh-40px" src="./assets/img/sw-neutron-logo.png"></h1>
    <div class="absolute w-full h-56 bg-sw-darkGrey opacity-90 z-10"></div>
  </div>
  <div class="flex flex-row w-full gap-4 p-4 content-container">
    <div class="result-artifacts flex flex-col w-10/12 gap-4">
      <div class="flex flex-row p-4 bg-sw-mediumGrey rounded-2xl flex-wrap whitespace-nowrap w-full justify-center">
        <div class="flex flex-col flex-1 px-4 pt-4 items-center text-white justify-between" *ngIf="!isBomb">
          <div clas="flex items-center text-left mx-2">
            <span>Min Dmg /Per hits - <span class="text-secondary">CRIT</span></span>
          </div>
          <span class="text-sm">Adjust statistics & artifacts</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 text-xl"><span class="text-secondary">{{finalDmg | number : '1.0-0'}}</span></span>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 px-4 pt-4 items-center text-white justify-between" *ngIf="!isBomb">
          <div clas="flex items-center text-left mx-2">
            <span>Average Dmg /Per hits - <span class="text-secondary">CRIT</span></span>
          </div>
          <span class="text-sm">Adjust statistics & artifacts</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 text-xl"><span class="text-secondary">{{finalDmgAverage | number : '1.0-0'}}</span></span>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 px-4 pt-4 items-center text-white justify-between" *ngIf="!isBomb">
          <div clas="flex items-center text-left mx-2">
            <span>Max Dmg /Per hits - <span class="text-secondary">CRIT</span></span>
          </div>
          <span class="text-sm">Adjust statistics & artifacts</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 text-xl"><span class="text-secondary">{{finalDmgMax | number : '1.0-0'}}</span></span>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 px-4 pt-4 items-center text-white justify-between bg-sw-darkGrey rounded-2xl">
          <div clas="flex items-center text-left mx-2">
            <span *ngIf="!isBomb">Dmg /Per hits - <span class="text-secondary">NO CRIT</span></span>
            <span *ngIf="isBomb">Dmg - <span class="text-secondary">BOMB</span></span>
          </div>
          <span class="text-sm">Adjust statistics & artifacts</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 text-xl"><span class="text-secondary">{{finalDmgNoCrit | number : '1.0-0'}}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="select-wrapper w-full rounded-none">
        <ng-select
            [items]="units" 
            bindLabel="name" 
            [(ngModel)]="selectedUnit"
            (ngModelChange)="onChangeUnit()" placeholder="Select Unit">
        </ng-select>
      </div>
      <div class="flex flex-wrap" *ngIf="selectedUnit">
        <div class="flex flex-row flex-wrap whitespace-nowrap w-full bg-sw-mediumGrey text-lg border-t items-center rounded-2xl">
          <div class="bonus flex flex-wrap p-4 gap-4">
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2 flex items-center text-sm">Guild <br> Skill</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="guildSkill" step="3" type="number" min="2" max="5" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl" [ngClass]="{'disable-input': defLead > 0 || hpLead > 0}">
              <img class="wh-30px" src="./assets/img/leader/leader_attack.png">
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="atkLead" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onModelChange(1);onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl" [ngClass]="{'disable-input': atkLead > 0 || hpLead > 0}">
              <img class="wh-30px"src="./assets/img/leader/leader_defense.png">
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="defLead" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onModelChange(2);onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl" [ngClass]="{'disable-input': defLead > 0 || atkLead > 0}">
              <img class="wh-30px" src="./assets/img/leader/leader_hp.png">
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="hpLead" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onModelChange(3);onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2 flex items-center text-sm">Skill Up <br> Bonus</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="skillUp" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <label class="mr-2 flex items-center text-sm">Ennemy <br> Hp</label>
              <mat-form-field class="input-number normal" appearance="fill">
                <input class="input-number" matInput [(ngModel)]="ennemyHp" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <label class="mr-2 flex items-center text-sm">Ennemy <br> Defense</label>
              <mat-form-field class="input-number normal" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="ennemyDef" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <label class="mr-2 flex items-center text-sm">Ennemy <br> Speed</label>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="ennemySpd" step="1" type="number" min="0" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <label class="mr-2 flex items-center text-sm">Ennnemy <br> Attribute</label>
              <ng-select [items]="ennemyAttribute"
                bindLabel="element"
                [(ngModel)]="selectedAttribute"
                (change)="onModelChange(0);onChange()">
              </ng-select>
            </div>
          </div>
        </div>
      </div>  
      <div class="flex flex-wrap w-full gap-4" *ngIf="selectedUnit">
        <div class="units flex flex-col flex-wrap p-4 bg-sw-mediumGrey rounded-2xl flex-auto">
          <div class="unit_title flex flex-row justify-between items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
            <img class="wh-40px" src="https://swarfarm.com/static/herders/images/monsters/{{
              selectedUnit.image
            }}">
            <h2 class="text-2xl px-2">{{selectedUnit.name}}</h2>
          </div>
          <div class="flex flex-col">
            <div class="unit_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
              <div class="level flex pb-2">
                <div class="w-full flex justify-between px-2">
                  <span>Lvl. {{selectedUnit.level}}</span>
                  <span class="flex justify-start text-sw-softBrown">{{ unitType }}</span>
                </div>
              </div>
              <div class="hp flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">HP</span>
                  <span>{{selectedUnit.baseCaracteristics.Health}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.bonusCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.manualAdditionalCaracteristics.Health" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="atk flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">ATK</span>
                  <span>{{selectedUnit.baseCaracteristics.Attack}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.bonusCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.manualAdditionalCaracteristics.Attack" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="def flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">DEF</span>
                  <span>{{selectedUnit.baseCaracteristics.Defense}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedUnit.bonusCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.manualAdditionalCaracteristics.Defense" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="speed flex pb-4">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">SPD</span>
                  <span>{{selectedUnit.baseCaracteristics.Spd}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedUnit.bonusCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.manualAdditionalCaracteristics.Spd" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                        <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <br>
              <div class="critRate flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Crit Rate</span>
                  <span>{{selectedUnit.baseCaracteristics["Critical Rate"]}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedUnit.bonusCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.manualAdditionalCaracteristics['Critical Rate']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="critDamage flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Crit Dmg</span>
                  <span>{{selectedUnit.baseCaracteristics["Critical Damage"]}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1"
                    [(ngModel)]="selectedUnit.bonusCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                        [(ngModel)]="selectedUnit.manualAdditionalCaracteristics['Critical Damage']" #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row bg-sw-darkGrey p-2 mt-4 rounded-2xl">
            <div class="flex flex-col p-4">
              <label>Buff Atk</label>
              <mat-checkbox class="text-s font-light" [ngModel]="isAtkBuff" (ngModelChange)="toggleAtkBuff()">
                <img class="wh-20px" src="./assets/img/effects/buff_attack.png">
              </mat-checkbox>
            </div>
            <div class="flex flex-col p-4">
              <label>Break Def</label>
              <mat-checkbox class="text-s font-light" [ngModel]="isDefBuff" (ngModelChange)="toggleBreakDef()">
                <img class="wh-20px" src="../../../assets/img/effects/debuff_defence.png">
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="skills-artifacts flex flex-col justify-between p-4 bg-sw-mediumGrey rounded-2xl flex-1" style="height: min-content;">
          <div class="unit-skills flex flex-row bg-sw-darkGrey p-4 rounded-2xl">
            <ng-container *ngFor="let skill of selectedUnit.spells">
              <div class="skill-container" (click)="onSelectSkill(skill)">
                <div class="skill_desc flex flex-row p-2">
                  <div class="left-col flex flex-col px-2">
                    <div [ngClass]="{'passiv_effect': skill.passive == true}">
                      <img class="wh-40px" src="https://swarfarm.com/static/herders/images/skills/{{
                        skill.icon_filename
                      }}">
                    </div>
                    <span class="text-white py-2" *ngIf="skill.scales_with.length > 0"><span class="text-sw-gold">Scales with:</span> {{skill.scales_with}}</span>
                    <hr class="divider" *ngIf="skill.scales_with.length > 0">
                    <span class="text-white py-2"><span class="text-sw-gold">Hits:</span> {{skill.hits}}</span>
                  </div>
                  <hr class="vertical-divider">
                  <div class="right-col flex flex-col px-2">
                    <span class="skill_title text-xl">{{ skill.name }} <span *ngIf="selectedSkill == skill" class="text-secondary">Selected</span></span>
                    <span class="text-gray-300 py-2">{{skill.description}}</span>
                    <span class="text-white py-2" *ngIf="skill.multiplier_formula.length > 0"><span class="text-sw-gold">Formula:</span> {{skill.multiplier_formula}}</span>
                    <ng-container *ngFor="let skillUp of skill.level_progress_description; let i = index" 
                      class="flex flex-col">
                      <span><span class="text-sw-gold">Lvl {{i}}:</span> {{ skillUp }}</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="buildings flex flex-wrap">
        <div class="building flex flex-row flex-wrap text-lg  bg-sw-mediumGrey rounded-2xl p-4 gap-4 w-full">
          <ng-container *ngFor="let building of buildings">
            <div class="flex flex-col flex-1 items-center p-4 text-center bg-sw-darkGrey rounded-2xl">
              <div class="flex flex-row align-middle items-center">  
                <img class="wh-40px p-1 rounded-full" src="{{building.image}}">
                <span class="text-sw-yellow p-1">{{building.metadata.name}}</span>
              </div>
              <div class="flex flex-wrap flex-row items-center p-1 justify-center">
                <fa-icon class="px-2" [icon]="plusCircle"></fa-icon>
                <span>Level</span>
                <ng-select [(ngModel)]="building.level" (ngModelChange)="onChange()" class="ml-2">
                  <ng-option *ngFor="let item of array(building.metadata.maxLevel+1); let i = index" [value]="i">{{i}}</ng-option>
                </ng-select>
              </div>
              <span class="capitalize">{{building.computeTotalBonus(building.level)}} - {{getEffectTypeString(building.effect.type)}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="artifacts w-2/12">
      <div class="flex flex-col p-4 rounded-2xl bg-sw-mediumGrey gap-4">
        <div class="artifact_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
          <img class="wh-40px" src="./assets/img/artifacts/rarity/artifact_element_legendary.png">
          <h2 class="text-2xl px-2">Artifact</h2>
        </div>
        <div class="flex flex-row flex-wrap flex-1 gap-4">
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2">Additional Damage by + N% of Def</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerDef" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2">Additional Damage by + N% of Hp</span> 
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerHp" step="0.1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2">Additional Damage by + N% of Speed</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerSpeed" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2">Additional Damage by + N% of Atk</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerAtk" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2">Crit damage boost as enemy condition is good</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="critBonusGoodHp" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="!isBomb">
            <span class="mr-2">Crit damage boost as enemy condition is Bad</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="critBonusBadHp" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedSkill?.slot == 1 && !isBomb">
            <span class="mr-2">Skill 1 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusFirstSkill" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedSkill?.slot == 2 && !isBomb">
            <span class="mr-2">Skill 2 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusSecondSkill" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedSkill?.slot == 3 && !isBomb">
            <span class="mr-2">Skill 3 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusThirdSkill" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedSkill?.slot == 4 && !isBomb">
            <span class="mr-2">Skill 3 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusFourthSkill" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedAttribute.id == 1">
            <span class="mr-2">Damage Dealt on Water + N%	</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnWater" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedAttribute.id == 2">
            <span class="mr-2">Damage Dealt on Fire + N%	</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnFire" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedAttribute.id == 3">
            <span class="mr-2">Damage Dealt on Wind + N%	</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnWind" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedAttribute.id == 4">
            <span class="mr-2">Damage Dealt on Light + N%	</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnLight" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl" *ngIf="selectedAttribute.id == 5">
            <span class="mr-2">Damage Dealt on Dark + N%	</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnDark" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>