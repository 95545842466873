/* NgModule and Route Import */
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
/* Component Import */
import { ImportDataComponent } from "./views/import-data/import-data.component";
import { MonstersComponent } from "./views/monsters/monsters.component";
import { RunesComponent } from "./views/runes/runes.component";
import { Bj5Component } from './views/bj5/bj5.component';
import { IcaruComponent } from "./views/icaru/icaru.component";
import { LushenComponent } from "./views/lushen/lushen.component";
import { ArtifactsComponent } from "./views/artifacts/artifacts.component";
import { ContributorsComponent } from "./views/contributors/contributors.component";
import { SiegeComponent } from "./views/siege/siege.component";
import { LabHubComponent } from "./views/lab-hub/lab-hub.component";

const routes: Routes = [
  { path: "", component: ImportDataComponent},
  { path: "import", component: ImportDataComponent},
  { path: "monsters", component: MonstersComponent},
  { path: "runes", component: RunesComponent},
  { path: "bj5", component: Bj5Component},
  { path: "icaru", component: IcaruComponent},
  { path: "lushen", component: LushenComponent},
  { path: "artifacts", component: ArtifactsComponent},
  { path: "labs", component: LabHubComponent},
  { path: "contributors", component: ContributorsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
