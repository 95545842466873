/* Angular*/
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ViewChild } from '@angular/core';
import { SelectionModel } from "@angular/cdk/collections";
/* Caiross Dungeon Model */
import { CairossImport } from '../../core/mapping/caiross-map.component'; 
/* Parser */
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
/* Angular Material */
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from '@angular/material/dialog';
/* FontAwesome */
import { faCheckCircle, faExclamationCircle, faChartBar, faFileCsv, faStar, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
/* File State */
export enum ImportState {
  none,
  importing,
  imported,
  loaded,
}

@Component({
  selector: 'app-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.scss']
})
export class RunsComponent implements OnInit {
  /* Set Import State */
  ImportState = ImportState;
  importState = ImportState.none;
  selectedFile: File;
  /* FontAwesome Icons */
  checkCircle = faCheckCircle;
  timesCircle = faTimesCircle;
  star = faStar;
  information = faExclamationCircle;
  chart = faChartBar;
  fileCsv = faFileCsv;
  /* Data */
  data: any = [];
  monstersByName: any = [];
  /* Run Time */
  runCount = 0;
  averageTimeGiant = 0;
  averageTimeDragon = 0;
  averageTimeNecropolis = 0;
  /* Run Counter */
  runCountGiant = 0;
  runCountDragon = 0;
  runCountNecro = 0;
  /* Run Win Counter */
  giantWinCount = 0;
  dragonWinCount = 0;
  necroWinCount = 0;
  
  csvRecords: any[] = [];
  header = true;

  dungeons: any = [];

  dataSource: any;
  displayedColumns: string[] = [
    "dungeon.dungeonName",
    "result",
    "time",
    "drop",
    "grade",
    "main_stat",
    "prefix_stat",
    "sub1",
    "sub2",
    "sub3",
    "sub4",
    "efficiency",
    "team",
  ];
  selection = new SelectionModel<any>(true, []);
// GLOBAL TODO PRevent to use localstorage directly
  constructor(private http: HttpClient, private ngxCsvParser: NgxCsvParser, public dialog: MatDialog) {}

  ngOnInit(): void {
    let dungeon = new CairossImport();
    this.dungeons = dungeon.cairossImport;
    let run_data = JSON.parse(localStorage.getItem("run_data"));
    this.csvRecords = run_data.csvRecords;
    /* Run Time */
    this.averageTimeGiant = run_data.averageTimeGiant;
    this.averageTimeDragon = run_data.averageTimeDragon;
    this.averageTimeNecropolis = run_data.averageTimeNecropolis;
    /* Run Win Counter */
    this.giantWinCount = run_data.giantWinCount;
    this.dragonWinCount = run_data.dragonWinCount;
    this.necroWinCount = run_data.necroWinCount;
    if(this.csvRecords) {
      this.dataSource = new MatTableDataSource(this.csvRecords);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.importState = ImportState.imported;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          /* MONSTERS */
          case "dungeon.dungeonName":
            return item.dungeon.dungeonName;
  
          default:
            return item[property];
        }
      }
    }
  }

  @ViewChild('fileImportInput') fileImportInput: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  openDialog() {
    this.dialog.open(loadingDialogCsv);
  }

  fileChangeListener($event: any): void {
    this.importState = ImportState.importing;
    const files = $event.srcElement.files;
    /* Run Time */
    this.averageTimeGiant = 0;
    this.averageTimeDragon = 0;
    this.averageTimeNecropolis = 0;
    /* Run Win Counter */
    this.giantWinCount = 0;
    this.dragonWinCount = 0;
    this.necroWinCount = 0;

    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
        localStorage.removeItem('run_data');
        this.csvRecords = result;
        /* Run Sumup Time */
        let totalTimeGiant = 0;
        let totalTimeDragon = 0;
        let totalTimeNecropolis = 0;
        
        for(let i in this.csvRecords){
          this.monstersByName = JSON.parse(localStorage.getItem("monster_data"));
          let runRecord = this.csvRecords[i];

          if(runRecord.dungeon.includes('Giant')){
            let timeStr = runRecord.time.split(':');
            if(timeStr[1][0] == '0'){
              timeStr[1].replace('0', '');
            }
            let timeSecond = parseInt(timeStr[0]) * 60 + parseInt(timeStr[1]);
            totalTimeGiant = totalTimeGiant + timeSecond;
            this.runCountGiant = this.runCountGiant + 1;
            if(runRecord.result.includes('Win')){
              this.giantWinCount = this.giantWinCount + 1;
            }
          } else if(runRecord.dungeon.includes('Necropolis')){
            let timeStr = runRecord.time.split(':');
            if(timeStr[1][0] == '0'){
              timeStr[1].replace('0', '');
            }
            let timeSecond = parseInt(timeStr[0]) * 60 + parseInt(timeStr[1]);
            totalTimeNecropolis = totalTimeNecropolis + timeSecond;
            this.runCountNecro = this.runCountNecro + 1;
            if(runRecord.result.includes('Win')){
              this.necroWinCount = this.necroWinCount + 1;
            }
          } else if(runRecord.dungeon.includes('Dragon')){
            let timeStr = runRecord.time.split(':');
            if(timeStr[1][0] == '0'){
              timeStr[1].replace('0', '');
            }
            let timeSecond = parseInt(timeStr[0]) * 60 + parseInt(timeStr[1]);
            totalTimeDragon = totalTimeDragon + timeSecond;
            this.runCountDragon = this.runCountDragon + 1;
            if(runRecord.result.includes('Win')){
              this.dragonWinCount = this.dragonWinCount + 1;
            }
          }

          for(let j in this.monstersByName){
            if(runRecord.team1 == this.monstersByName[j].name){
              runRecord.team1 = {
                monster_name: this.monstersByName[j].name,
                monster_file: this.monstersByName[j].image_filename
              }
            }
            if(runRecord.team2 == this.monstersByName[j].name){
              runRecord.team2 = {
                monster_name: this.monstersByName[j].name,
                monster_file: this.monstersByName[j].image_filename
              }
            }
            if(runRecord.team3 == this.monstersByName[j].name){
              runRecord.team3 = {
                monster_name: this.monstersByName[j].name,
                monster_file: this.monstersByName[j].image_filename
              }
            }
            if(runRecord.team4 == this.monstersByName[j].name){
              runRecord.team4 = {
                monster_name: this.monstersByName[j].name,
                monster_file: this.monstersByName[j].image_filename
              }
            }
            if(runRecord.team5 == this.monstersByName[j].name){
              runRecord.team5 = {
                monster_name: this.monstersByName[j].name,
                monster_file: this.monstersByName[j].image_filename
              }
            }
          }
          
          runRecord.grade = {
            grade: runRecord.grade.replace("*", ""),
          }
          
          runRecord.dungeon = {
            dungeonName: runRecord.dungeon,
            dungeon_img: this.dungeons.boss.boss_img[runRecord.dungeon],
          }
          
          if(runRecord.drop.includes('Rune Piece')){
            runRecord.drop = "runePieces";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Harmony')){
            runRecord.drop = "harmonySymbol";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Chaos')){
            runRecord.drop = "chaosSymbol";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Mystic')){
            runRecord.drop = "mysticScroll";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Unknown')){
            runRecord.drop = "unknownScroll";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Shapeshifting')){
            runRecord.drop = "shapeshiftingStone";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Transcendence')){
            runRecord.drop = "transcendenceSymbol";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          else if(runRecord.drop.includes('Rainbowmon')){
            runRecord.drop = "arcenmon";
            runRecord.drop = {
              drop_type: runRecord.drop,
              drop_img: this.dungeons.rune.drop_img[runRecord.drop],
            }
          }
          runRecord.slot = {
            id: runRecord.slot,
            rune_img: this.dungeons.rune.rune_img[runRecord.slot],
          }
          runRecord.set = {
            set_name: runRecord.set,
            set_img: this.dungeons.rune.sets_img[runRecord.set],
          }
        }

        this.averageTimeGiant = totalTimeGiant / this.runCountGiant;
        this.averageTimeDragon = totalTimeDragon / this.runCountDragon;
        this.averageTimeNecropolis = totalTimeNecropolis / this.runCountNecro;

        this.giantWinCount = this.giantWinCount * 100 / this.runCountGiant;
        this.dragonWinCount = this.dragonWinCount * 100 / this.runCountDragon;
        this.necroWinCount = this.necroWinCount * 100 / this.runCountNecro;
        
        localStorage.setItem("run_data", JSON.stringify({
          csvRecords: this.csvRecords, 
          averageTimeGiant: this.averageTimeGiant, 
          averageTimeDragon: this.averageTimeDragon, 
          averageTimeNecropolis: this.averageTimeNecropolis,

          giantWinCount: this.giantWinCount,
          dragonWinCount: this.dragonWinCount,
          necroWinCount: this.necroWinCount,
        }));
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
      this.importState = ImportState.imported;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}

@Component({
  selector: 'loadingDialogCsv',
  templateUrl: 'loadingDialogCsv.html',
  styleUrls: ["./loading-dialogCsv.scss"],
})
export class loadingDialogCsv {
  ImportState = ImportState;
  importState = ImportState.none;
  selectedFile: File;
  /* FontAwesome Icons */
  checkCircle = faCheckCircle;
  timesCircle = faTimesCircle;
  star = faStar;
  information = faExclamationCircle;

  /* Data */
  data: any = [];
  monstersByName: any = [];
  
  runCount = 0;
  averageTimeGiant = 0;
  averageTimeDragon = 0;
  averageTimeNecropolis = 0;

  runCountGiant = 0;
  runCountDragon = 0;
  runCountNecro = 0;

  giantWinCount = 0;
  dragonWinCount = 0;
  necroWinCount = 0;
  
  csvRecords: any[] = [];
  header = true;

  ngOnInit(){
    this.data = JSON.parse(localStorage.getItem("run_data"));
  }
}
