<mat-tab-group mat-align-tabs="center" class="pt-16">
    <mat-tab label="Tricaru">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon mx-4">
                <img src="../../assets/img/icaru.jpg" class="rounded-full">
            </mat-icon>
            Tricaru
        </ng-template>
        
        <app-icaru></app-icaru>
    </mat-tab>
    <mat-tab label="Bj5">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon mx-4">
                <img src="../../assets/img/balgr.jpg" class="rounded-full">
            </mat-icon>
            Bj5
        </ng-template>
        <app-bj5></app-bj5>
    </mat-tab>
    <mat-tab label="Lushen">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon mx-4">
                <img src="../../assets/img/lushen.png" class="rounded-full">
            </mat-icon>
            Lushen
        </ng-template>
        <app-lushen></app-lushen>
    </mat-tab>
</mat-tab-group>