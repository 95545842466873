<div class="main-container">
  <div class="full h-32 bg-hero-background title-header flex-col bg-center flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl z-20 flex flex-row items-center">Lushen Lab <img class="wh-40px" src="./assets/img/sw-neutron-logo.png"></h1>
    <div class="absolute w-full h-32 bg-sw-darkGrey opacity-90 z-10"></div>
  </div>
  <div class="flex flex-row w-full gap-4 p-4 content-container">
    <div class="result-lushen flex flex-col w-10/12 gap-4">
      <div class="flex flex-row flex-wrap p-4 gap-4 bg-sw-mediumGrey rounded-2xl justify-center">
        <div class="flex flex-col p-4 items-center text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Min Lushen Dmg /Per card :</span>
            <span class="m-2 text-xl text-secondary">10,091</span>
          </div>
          <span class="text-sm">To kill all waves if 100% Crit rate</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 whitespace-nowrap">Dmg <span class="text-green">{{finalDmg | number : '1.0-0'}}</span> /Per
                card</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 p-4 items-center text-white justify-between bg-sw-darkGrey rounded-2xl">
          <div clas="flex items-center text-left mx-2">
            <span>Wave initial HP :</span>
            <span class="m-2 text-xl text-secondary">{{ selectedDungeon }}</span>
          </div>
          <span class="text-sm">Wave's HP after 1 hit</span>
          <div class="flex flex-row items-center border-t-soft mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 whitespace-nowrap"><span class="text-green">{{firstHitHp | number : '1.0-0'}}</span> HP Remains</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 p-4 items-center text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Wave initial HP :</span>
            <span class="m-2 text-xl text-secondary">{{ selectedDungeon }}</span>
          </div>
          <span class="text-sm">Wave's HP after 2 hit</span>
          <div class="flex flex-row items-center border-t mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 whitespace-nowrap">
                <span class="text-green" *ngIf="secondHitHp >= 0">{{secondHitHp | number : '1.0-0'}}</span>
                <span class="text-green" *ngIf="secondHitHp < 0"> 0 </span>
                HP Remains
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 p-4 items-center text-white justify-between bg-sw-darkGrey rounded-2xl">
          <div clas="flex items-center text-left mx-2">
            <span>Wave initial HP :</span>
            <span class="m-2 text-xl text-secondary">{{ selectedDungeon }}</span>
          </div>
          <span class="text-sm">Wave's HP after 3 hit</span>
          <div class="flex flex-row items-center border-t-soft mt-2">
            <div class="flex flex-1 p-4 justify-between items-center max-w-xxs">
              <span class="mr-2 whitespace-nowrap">
                <span class="text-green">
                  {{thirdHitHp | number : '1.0-0'}}
                </span>
                HP Remains
              </span>
              <fa-icon *ngIf="isValidLushen" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="!isValidLushen" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 p-4 items-center text-white justify-between">
          <div clas="flex items-center text-left mx-2">
            <span>Min Lushen Speed Required:</span>
            <span class="m-2 text-xl text-secondary">169</span>
          </div>
          <span class="text-sm">To outspeed waves</span>
          <div class="flex flex-row items-center mt-2 border-t">
            <div class="flex flex-1 p-4 justify-between items-center">
              <span class="mr-2 whitespace-nowrap">Lushen Speed <span class="text-green">{{ actualMaxSpd | json}}</span></span>
              <fa-icon *ngIf="actualMaxSpd > 169" class="faCheckCircle" [icon]="checkCircle"></fa-icon>
              <fa-icon *ngIf="actualMaxSpd <= 169" class="faTimeCircle" [icon]="timeCircle"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="flex flex-row flex-wrap whitespace-nowrap w-full bg-sw-mediumGrey text-lg border-t items-center rounded-2xl">
          <div class="bonus flex flex-wrap p-4 gap-4">
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2">Fight Set</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="fightSet" step="1" type="number" min="0"
                  max="12" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <span class="mr-2">Guild Skill</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="guildSkill" step="3" type="number" min="2"
                  max="5" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
            <div class="flex flex-row items-center text-center justify-between bg-sw-darkGrey p-4 rounded-2xl">
              <img class="w-20px h-20px rounded-2xl" src="./assets/img/lushen.png">
              <span class="mx-2">S3 Skill Up</span>
              <mat-form-field class="input-number small" appearance="fill">
                <input class="input-number small" matInput [(ngModel)]="skillUp" step="15" type="number" min="0"
                  max="30" #ctrl="ngModel" (ngModelChange)="onChange()">
              </mat-form-field>
              <fa-icon [icon]="edit"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap p-4 gap-4 bg-sw-mediumGrey rounded-2xl">
        <div class="lushen flex flex-col flex-1">
          <mat-spinner [diameter]="65" class="m-auto" *ngIf="lushens.length == 0 && !selectedLushen"></mat-spinner>
          <div class="lushens" *ngIf="!selectedLushen">
            <ng-container *ngFor="let lushen of lushens">
              <div class="bg-sw-darkGrey rounded-2xl px-4 py-4 mb-2">
                <div class="baleygr_title flex flex-row flex-wrap items-center text-center rounded">
                  <img class="wh-40px rounded" src="./assets/img/lushen.png">
                  <div class="flex flex-col mx-4">
                    <h2 class="text-2xl">{{ lushen.name }}</h2>
                    <span class="text-left">Lvl. {{lushen.level}}</span>
                  </div>
                  <button mat-button
                    class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide"
                    (click)="onLushenSelect(lushen)">Select Lushen</button>
                </div>
                <div class="flex w-full justify-center">
                  <div *ngFor="let slot of getKeys(lushen.runes)" (click)="onRuneSelect(lushen.runes[slot])">
                    <div class="rune_img flex items-center justify-start cursor-pointer mt-2">
                      <img *ngIf="lushen.runes[slot]?.slotImage" class="wh-40px" src="{{ lushen.runes[slot]?.slotImage }}" />
                      <img *ngIf="lushen.runes[slot]?.setType" class="rune_element wh-20px absolute ml-10px" ngClass="_{{ lushen.runes[slot]?.setType }}"
                        src="{{ lushen.runes[slot]?.setImage }}" />
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="lushen_title flex flex-row justify-between items-center text-center bg-sw-darkGrey p-4 rounded-2xl"
            *ngIf="selectedLushen">
            <img class="wh-40px rounded" src="./assets/img/lushen.png">
            <h2 class="text-2xl mx-4">Lushen</h2>
            <button mat-button
              class="p-2 whitespace-nowrap rounded-md w-min text-base accent-gradient-btn tracking-wide"
              (click)="showSelect()" *ngIf="lushens.length > 0">Change Lushen</button>
          </div>
          <div class="flex flex-col" *ngIf="selectedLushen">
            <div class="baleygr_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
              <div class="level flex pb-2">
                <div class="w-full flex justify-between px-2">
                  <span>Lvl. {{selectedLushen.level}}</span>
                  <span class="flex justify-start text-sw-mediumGrey">Attack</span>
                </div>
              </div>
              <div class="hp flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">HP</span>
                  <span>{{selectedLushen.baseCaracteristics.Health}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics.Health"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.manualAdditionalCaracteristics.Health"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="atk flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">ATK</span>
                  <span>{{selectedLushen.baseCaracteristics.Attack}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics.Attack"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.manualAdditionalCaracteristics.Attack"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="def flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">DEF</span>
                  <span>{{selectedLushen.baseCaracteristics.Defense}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics.Defense"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.manualAdditionalCaracteristics.Defense"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="speed flex pb-4">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">SPD</span>
                  <span>{{selectedLushen.baseCaracteristics.Spd}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics.Spd" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.manualAdditionalCaracteristics.Spd"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <br>
              <div class="critRate flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Crit Rate</span>
                  <span>{{selectedLushen.baseCaracteristics["Critical Rate"]}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics['Critical Rate']"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                      [(ngModel)]="selectedLushen.manualAdditionalCaracteristics['Critical Rate']" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="critDamage flex">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Crit Dmg</span>
                  <span>{{selectedLushen.baseCaracteristics["Critical Damage"]}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics['Critical Damage']"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1"
                      [(ngModel)]="selectedLushen.manualAdditionalCaracteristics['Critical Damage']" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
              <div class="speed flex pb-4">
                <div class="w-7/12 flex justify-between px-2">
                  <span class="text-sw-yellow">Accuracy</span>
                  <span>{{selectedLushen.baseCaracteristics.Accuracy}}</span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-green">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.bonusCaracteristics.Accuracy" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
                <div class="w-5/12 px-2">
                  <span class="flex justify-start text-sw-red">
                    +<input type="number" step="1" [(ngModel)]="selectedLushen.manualAdditionalCaracteristics.Accuracy"
                      #ctrl="ngModel" (ngModelChange)="onChange()" class="bg-transparent" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-row bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg justify-center">
              <div *ngFor="let slot of getKeys(selectedLushen.runes)"
                (click)="onRuneSelect(selectedLushen.runes[slot])">
                <div class="rune_img flex items-center justify-start cursor-pointer"
                  *ngIf="selectedLushen.runes[slot] !== null">
                  <img *ngIf="selectedLushen.runes[slot]?.slotImage" class="wh-40px" src="{{ selectedLushen.runes[slot]?.slotImage }}" />
                  <img *ngIf="selectedLushen.runes[slot]?.setImage" class="rune_element wh-20px absolute ml-10px"
                    ngClass="_{{ selectedLushen.runes[slot]?.setType }}"
                    src="{{ selectedLushen.runes[slot]?.setImage }}" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="melia flex flex-col flex-1" *ngFor="let melia of melias; let i = index">
          <div class="melia_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
            <img class="wh-40px" src="./assets/img/melia.png">
            <h2 class="text-2xl px-2">Melia {{i + 1}}</h2>
          </div>
          <div class="melia-flex flex flex-row">
            <div class="flex flex-col w-full">
              <div class="melia_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
                <div class="level flex pb-2">
                  <div class="w-full flex justify-between px-2">
                    <span>Lvl. {{melia.level}}</span>
                  </div>
                </div>
                <!-- <div class="hp flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">HP</span>
                    <span>{{melia.baseCaracteristics.Health}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{melia.bonusCaracteristics.Health}}</span>
                    </span>
                  </div>
                </div>
                <div class="def flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">DEF</span>
                    <span>{{melia.baseCaracteristics.Defense}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{melia.bonusCaracteristics.Defense}}</span>
                    </span>
                  </div>
                </div> -->
                <div class="speed flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">SPD</span>
                    <span>{{melia.baseCaracteristics.Spd}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1" [(ngModel)]="melia.bonusCaracteristics.Spd" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="acc flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">Accuracy</span>
                    <span>{{melia.baseCaracteristics.Accuracy}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1" [(ngModel)]="melia.bonusCaracteristics.Accuracy" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                      <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div class="bg-sw-darkGrey p-4 flex flex-col gap-4 rounded-2xl mt-4">
                <span class="flex justify-center text-xl border-b border-white">Requirements</span>
                <div class="flex flex-row px-2 rounded-2xl text-lg justify-left">
                  <span>Accuracy: 
                    <span class="text-red" *ngIf="melia.bonusCaracteristics.Accuracy < 55"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon> (+55 min)</span>
                    <span class="text-green" *ngIf="melia.bonusCaracteristics.Accuracy >= 55"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                </div>
                <div class="flex flex-col px-2 rounded-2xl text-lg justify-left" *ngIf="i == 0">
                  <span class="flex flex-row">Speed >= 169:
                    <span class="text-red" *ngIf="actualMaxSpdMeliaFirst < 169"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdMeliaFirst >= 169"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed > <img class="wh-20px mx-2 rounded-full" src="./assets/img/tatu.png">:
                    <span class="text-red" *ngIf="actualMaxSpdMeliaFirst <= actualMaxSpdTatu"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdMeliaFirst > actualMaxSpdTatu"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed < <img class="wh-20px mx-2 rounded-full" src="./assets/img/sath.png">:
                    <span class="text-red" *ngIf="actualMaxSpdMeliaFirst >= actualMaxSpdSath"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdMeliaFirst < actualMaxSpdSath"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                </div>
                <div class="flex flex-col px-2 rounded-2xl text-lg justify-left" *ngIf="i == 1">
                  <span class="flex flex-row">Speed >= 169:
                    <span class="text-red" *ngIf="actualMaxSpdMeliaSecond < 169"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdMeliaSecond >= 169"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed > <img class="wh-20px mx-2 rounded-full" src="./assets/img/tatu.png">:
                    <span class="text-red" *ngIf="actualMaxSpdMeliaSecond <= actualMaxSpdTatu"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdMeliaSecond > actualMaxSpdTatu"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed < <img class="wh-20px mx-2 rounded-full" src="./assets/img/sath.png">:
                    <span class="text-red" *ngIf="actualMaxSpdMeliaSecond >= actualMaxSpdSath"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdMeliaSecond < actualMaxSpdSath"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sath flex flex-col flex-1" *ngFor="let sath of saths">
          <div class="melia_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
            <img class="wh-40px" src="./assets/img/sath.png">
            <h2 class="text-2xl px-2">Sath</h2>
          </div>
          <div class="sath-flex flex flex-row">
            <div class="flex flex-col w-full">
              <div class="melia_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
                <div class="level flex pb-2">
                  <div class="w-full flex justify-between px-2">
                    <span>Lvl. {{sath.level}}</span>
                  </div>
                </div>
                <!-- <div class="hp flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">HP</span>
                    <span>{{sath.baseCaracteristics.Health}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{sath.bonusCaracteristics.Health}}</span>
                    </span>
                  </div>
                </div>
                <div class="def flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">DEF</span>
                    <span>{{sath.baseCaracteristics.Defense}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{sath.bonusCaracteristics.Defense}}</span>
                    </span>
                  </div>
                </div> -->
                <div class="speed flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">SPD</span>
                    <span>{{sath.baseCaracteristics.Spd}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1" [(ngModel)]="sath.bonusCaracteristics.Spd" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="acc flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">Accuracy</span>
                    <span>{{sath.baseCaracteristics.Accuracy}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1" [(ngModel)]="sath.bonusCaracteristics.Accuracy" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
              </div>
              <div class="bg-sw-darkGrey p-4 flex flex-col gap-4 rounded-2xl mt-4">
                <span class="flex justify-center text-xl border-b border-white">Requirements</span>
                <div class="flex flex-row px-2 rounded-2xl text-lg justify-left">
                  <span>Accuracy: 
                    <span class="text-red" *ngIf="sath.bonusCaracteristics.Accuracy < 55"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon> (+55 min)</span>
                    <span class="text-green" *ngIf="sath.bonusCaracteristics.Accuracy >= 55"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                </div>
                <div class="flex flex-col px-2 rounded-2xl text-lg justify-left">
                  <span>Speed >= 169:
                    <span class="text-red" *ngIf="actualMaxSpdSath < 169"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdSath >= 169"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed > <img class="wh-20px mx-2 rounded-full" src="./assets/img/tatu.png">:
                    <span class="text-red" *ngIf="actualMaxSpdSath <= actualMaxSpdTatu"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdSath > actualMaxSpdTatu"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed > <img class="wh-20px mx-2 rounded-full" src="./assets/img/melia.png">:
                    <span class="text-red" *ngIf="actualMaxSpdSath <= actualMaxSpdMeliaFirst || actualMaxSpdSath <= actualMaxSpdMeliaSecond"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdSath > actualMaxSpdMeliaFirst && actualMaxSpdSath > actualMaxSpdMeliaSecond"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tatu flex flex-col flex-1" *ngFor="let tatu of tatus">
          <div class="melia_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
            <img class="wh-40px" src="./assets/img/tatu.png">
            <h2 class="text-2xl px-2">Tatu</h2>
          </div>
          <div class="tatu-flex flex flex-row">
            <div class="flex flex-col w-full">
              <div class="melia_stats_first flex flex-col bg-sw-darkGrey p-2 mt-4 rounded-2xl text-lg">
                <div class="level flex pb-2">
                  <div class="w-full flex justify-between px-2">
                    <span>Lvl. {{tatu.level}}</span>
                  </div>
                </div>
                <!-- <div class="hp flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">HP</span>
                    <span>{{tatu.baseCaracteristics.Health}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{tatu.bonusCaracteristics.Health}}</span>
                    </span>
                  </div>
                </div>
                <div class="def flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">DEF</span>
                    <span>{{tatu.baseCaracteristics.Defense}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{tatu.bonusCaracteristics.Defense}}</span>
                    </span>
                  </div>
                </div> -->
                <div class="speed flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">SPD</span>
                    <span>{{tatu.baseCaracteristics.Spd}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<input type="number" step="1" [(ngModel)]="tatu.bonusCaracteristics.Spd" #ctrl="ngModel"
                      (ngModelChange)="onChange()" class="bg-transparent text-green" size="4">
                    <fa-icon [icon]="edit"></fa-icon>
                    </span>
                  </div>
                </div>
                <div class="acc flex">
                  <div class="w-7/12 flex justify-between px-2">
                    <span class="text-sw-yellow">Accuracy</span>
                    <span>{{tatu.baseCaracteristics.Accuracy}}</span>
                  </div>
                  <div class="w-5/12 px-2">
                    <span class="flex justify-start text-sw-green">
                      +<span class="text-gold">{{tatu.bonusCaracteristics.Accuracy}}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="bg-sw-darkGrey p-4 flex flex-col gap-4 rounded-2xl mt-4">
                <span class="flex justify-center text-xl border-b border-white">Requirements</span>
                <div class="flex flex-row px-2 rounded-2xl text-lg justify-left">
                  <span>No accuracy required</span>
                </div>
                <div class="flex flex-col px-2 rounded-2xl text-lg justify-left">
                  <span>Speed >= 169:
                    <span class="text-red" *ngIf="actualMaxSpdTatu < 169"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdTatu >= 169"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed < <img class="wh-20px mx-2 rounded-full" src="./assets/img/melia.png">:
                    <span class="text-red" *ngIf="actualMaxSpdTatu >= actualMaxSpdMeliaFirst || actualMaxSpdTatu >= actualMaxSpdMeliaSecond"><fa-icon class="mx-2 faTimeCircle" [icon]="timeCircle"></fa-icon></span>
                    <span class="text-green" *ngIf="actualMaxSpdTatu < actualMaxSpdMeliaFirst && actualMaxSpdTatu < actualMaxSpdMeliaSecond"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                  <span class="flex flex-row">Speed < <img class="wh-20px mx-2 rounded-full" src="./assets/img/sath.png">:
                    <span class="text-red" *ngIf="actualMaxSpdTatu >= actualMaxSpdSath">Not OK</span>
                    <span class="text-green" *ngIf="actualMaxSpdTatu < actualMaxSpdSath"><fa-icon class="mx-2 faCheckCircle" [icon]="checkCircle"></fa-icon></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buildings flex flex-wrap">
        <div class="building flex flex-row flex-wrap text-lg  bg-sw-mediumGrey rounded-2xl p-4 gap-4 w-full">
          <ng-container *ngFor="let building of buildings">
            <div class="flex flex-col flex-1 items-center p-4 text-center bg-sw-darkGrey rounded-2xl">
              <div class="flex flex-row align-middle items-center">  
                <img class="wh-40px p-1 rounded-full" src="{{building.image}}">
                <span class="text-sw-yellow p-1">{{building.metadata.name}}</span>
              </div>
              <div class="flex flex-wrap flex-row items-center p-1 justify-center">
                <fa-icon class="mx-2 px-2" [icon]="plusCircle"></fa-icon>
                <span>Level</span>
                <ng-select [(ngModel)]="building.level" (ngModelChange)="onChange()" class="ml-2">
                  <ng-option *ngFor="let item of array(building.metadata.maxLevel+1); let i = index" [value]="i">{{i}}</ng-option>
                </ng-select>
              </div>
              <span class="capitalize">{{building.computeTotalBonus(building.level)}} - {{getEffectTypeString(building.effect.type)}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="artifacts w-2/12">
      <div class="flex flex-col p-4 rounded-2xl bg-sw-mediumGrey gap-4">
        <div class="artifact_title flex flex-row items-center text-center bg-sw-darkGrey p-4 rounded-2xl">
          <img class="wh-40px" src="./assets/img/artifacts/rarity/artifact_element_legendary.png">
          <h2 class="text-2xl px-2">Artifact Bonus</h2>
        </div>
        <div class="flex flex-row flex-wrap flex-1">
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Def</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerDef" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Hp</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerHp" step="0.1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Speed</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerSpeed" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Additional Damage by + N% of Atk</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusPerAtk" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Crit damage boost as enemy condition is Good</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="critBonusGoodHp" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Crit damage boost as enemy condition is Bad</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="critBonusBadHp" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Skill 3 Bonus Crit Damage</span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgBonusThirdSkill" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
          <div class="flex flex-row text-left justify-between bg-sw-darkGrey p-4 items-center w-full mt-4 rounded-2xl">
            <span class="mr-2 w-2/4">Damage Dealt on Water + N% </span>
            <mat-form-field class="input-number small" appearance="fill">
              <input class="input-number small" matInput [(ngModel)]="dmgDealtOnWater" step="1" type="number" min="0"
                #ctrl="ngModel" (ngModelChange)="onChange()">
            </mat-form-field>
            <fa-icon [icon]="edit"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>