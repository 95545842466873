import {SwarFarmAPIService} from "./swarfarm-api.service"
import { LocalStorageService } from "./local-storage.service"

import {SWExporterTypes} from "../core/types/sw-exporter.types"

import { Injectable } from '@angular/core';
import { SWCalculatorTypes } from "../core/types/sw-calculator.types"
import { emptyRuneSlot, Rune } from "../core/models/rune.model"
import { Unit } from "../core/models/unit.model"
import { Artifact, emptyArtifactSlot } from "../core/models/artifact.model"
import { Building } from "../core/models/building.model"
import { CompressionService } from "./compression.service";

@Injectable({
    providedIn: 'root',
})
export class ImportService  {

    private buildings: Building[] = []
    private artifacts: any  = []
    private units: Unit[]  = []
    private wizard_data: any 
    private storage_runes: Rune[] = []

    constructor(private swarmFarmApi: SwarFarmAPIService, private browserStorage: LocalStorageService, private lzString: CompressionService) {
        // Retrieve data from local storage.
        this.wizard_data = this.browserStorage.Get("wizard_data");
        this.storage_runes = this.importFromStorage(Rune, this.browserStorage.Get("storage_runes")) as Rune[];
        this.units = this.importFromStorage(Unit, this.browserStorage.Get<Unit[]>("new_units")) as Unit[]

        this.artifacts = this.browserStorage.Get("monster_artifacts");
        let tempBuilding = this.browserStorage.Get<Building[]>("new_buildings");
        this.buildings = tempBuilding != undefined ? tempBuilding.map(n => Building.CreateFromBuilding(n)) : []
    }

    // Import a sw exporter json.
    async Import(sw_exporter_json: any) {
        // v2
        {          
          this.storage_runes = await this.importStorageRunesv2(sw_exporter_json.runes)
          this.units = await this.convertUnits(sw_exporter_json.unit_list as SWExporterTypes.Unit[])

          const compressedRunes = this.lzString.compressObject(this.storage_runes) as unknown as Rune[]
          const compressedUnits = this.lzString.compressObject(this.units) as unknown as Unit[]

          this.buildings = await this.importBuildingsV2(sw_exporter_json)

          this.browserStorage.Set("storage_runes", compressedRunes);
          this.browserStorage.Set<SWCalculatorTypes.Unit[]>("new_units", compressedUnits);
          this.browserStorage.Set<Building[]>("new_buildings", this.buildings);
        }
       {
         //let units = await this.importUnits(sw_exporter_json)
         this.wizard_data = this.lzString.compressObject(sw_exporter_json)
         // Store data on local storage.
         this.browserStorage.Set("wizard_data", this.wizard_data);
         //this.browserStorage.Remove('verde_select');
         //this.browserStorage.Remove('lushen_select');
       }
    }

    // Clear and clean local storage (used to reimport a json easily)
    Clear() {
        //
        this.browserStorage.RemoveAll();
        //
    }

    GetBuildings() {
        return this.buildings
    }

    GetStorageRunes() {
        return this.storage_runes
    }

    GetUnits() {
        return this.units
    }

    GetWizardData() {
        return this.wizard_data
    }

    GetArtifacts() {
        return this.artifacts
    }

    private async importStorageArtifacts(data: SWExporterTypes.Artifact[]) {
        return data.map(r => {return this.convertArtifact(r)}).map(n => new Artifact(n))
    }

    private async importStorageRunesv2(data: SWExporterTypes.Rune[]) {
        return data.map(r =>{return this.convertRune(r)}).map(n => new Rune(n))
    }
    // V2
    private async importBuildingsV2(data: any) {
      let buildings = []

      for (let index in data.deco_list){
          const deco = data.deco_list[index];
          if(SWExporterTypes.BuildingType[deco.master_id] != undefined) {
            buildings.push(new Building(deco.master_id, deco.level))
          }
          else {
            console.log(`Deco list with id ${deco.master_id} not supported`)
          }
      }
      
      return buildings
  }

    private importFromStorage<T>(type: { new(t: T): T;}, objFromStorage: T[]): T[] {
      if(objFromStorage != null){
        const decompressObjFromStorage = this.lzString.decompressObject(objFromStorage.toString())
        return decompressObjFromStorage ? decompressObjFromStorage.map(n => new type(n)) : []
      } else {
        return objFromStorage ? objFromStorage.map(n => new type(n)) : []
      }
    }

    private async convertUnits(unit_list: SWExporterTypes.Unit[]) {
      let monsters = await this.swarmFarmApi.GetAllMonsters();
      let skillList = await this.swarmFarmApi.GetAllSkills()

      let units: Unit[] = []

      for(let monsterKey in monsters) {
          let monster = monsters[monsterKey]
        for (let index in unit_list) {
          if (unit_list[index].unit_master_id === monster.id) {

            const exportUnit = unit_list[index] as SWExporterTypes.Unit;

            let toRuneSlot = (runes: SWExporterTypes.Rune[]): Record<SWExporterTypes.RuneSlot, SWCalculatorTypes.Rune> => {
              let runeSlots: Record<SWExporterTypes.RuneSlot, SWCalculatorTypes.Rune> = emptyRuneSlot()
              runes.forEach((n) => {
                runeSlots[n.slot_no] = this.convertRune(n)
                runeSlots[n.slot_no].unitImage = monster.image
                this.storage_runes.push(new Rune(runeSlots[n.slot_no]))
              })
              
              return runeSlots
            }

            let toArtifactSlot = (artifacts: SWExporterTypes.Artifact[]): Record<SWExporterTypes.ArtifactSlot,SWCalculatorTypes.Artifact> => {
              let artifactSlots: Record<SWExporterTypes.ArtifactSlot, SWCalculatorTypes.Artifact> = emptyArtifactSlot()
              artifacts.forEach((n) => {
                artifactSlots[n.slot] = this.convertArtifact(n)
              })
              return artifactSlots
            }

            let unit = new Unit({
              ...monster,
              runes: toRuneSlot(exportUnit.runes),
              artifacts: toArtifactSlot(exportUnit.artifacts),
              level: exportUnit.unit_level
            })
            units.push(unit)
          }
        }
      }
      for(let unit of units){
        for(let skill of unit.skills){
          skillList.forEach(element => {
            if(element.id == skill){
                
              if(Object.values(unit.spells).includes(element)){
                unit.spells = []
                unit.spells.push(element)
              } else {
                unit.spells.push(element)
              }
            }
          });
        }
      }
      return units
    }
  

    convertEffect(exporterEffect: SWExporterTypes.Effect): SWCalculatorTypes.Effect {
      return {
        type: exporterEffect[0] as SWExporterTypes.EffectType,
        value: exporterEffect[1],
        gems:0,
        grindstones: 0,
        effect_reducer:1
      }
    }

    convertInGameEffect(exporterEffect: SWExporterTypes.Effect): SWCalculatorTypes.InGameEffect {
      return {
        type: exporterEffect[0] as SWExporterTypes.InGameEffectType, // tricks to convert type to another. 
        value: exporterEffect[1]
      }
    }

    convertRuneSecondaryEffect(exporterEffect: SWExporterTypes.RuneSecondaryEffect): SWCalculatorTypes.Effect {
      return {
        type: exporterEffect[0] as SWExporterTypes.EffectType,
        value: exporterEffect[1],
        gems: exporterEffect[2],
        grindstones: exporterEffect[3],
        effect_reducer:1
      }
    }

    convertArtifact(exporterArtifact: SWExporterTypes.Artifact) : SWCalculatorTypes.Artifact {
        return {
          extra: exporterArtifact.extra,
          rank: exporterArtifact.rank,
          slot: exporterArtifact.slot,
          type: exporterArtifact.type,
          level: exporterArtifact.level,
          attribute: exporterArtifact.attribute,
          primaryEffect: this.convertEffect(exporterArtifact.pri_effect),
          // map transform original array of type T to another of type U via a function
          secondaryEffects: exporterArtifact.sec_effects.map(this.convertInGameEffect),
        }
    }

    convertRune(exporterRune: SWExporterTypes.Rune) : SWCalculatorTypes.Rune {
      console.log(exporterRune.rank)
      return {
        setType : exporterRune.set_id,
        isAntique: exporterRune.class - 10 > 0,
        stars: exporterRune.class%10,
        extra: exporterRune.extra,
        rank: exporterRune.rank,
        slotFactor: exporterRune.slot_no,
        primaryEffect: this.convertEffect(exporterRune.pri_eff),
        secondaryEffects: exporterRune.sec_eff.map(this.convertRuneSecondaryEffect),
        innateEffect: this.convertEffect(exporterRune.prefix_eff),
        sellValue: exporterRune.sell_value,
        maxUpgradeLevel: exporterRune.ugrade_limit,
        upgradeLevel: exporterRune.upgrade_curr,
      }
    }
}