import { Component, OnInit } from '@angular/core';
import { faBoxOpen, faCode, faHandsHelping, faPeopleCarry } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss']
})
export class ContributorsComponent implements OnInit {
  code = faCode;
  helper = faHandsHelping;
  contributor = faPeopleCarry
  ressources = faBoxOpen

  constructor() { }

  ngOnInit(): void {
  }

}
