/* Angular*/
import { Component, OnInit } from '@angular/core';
/* FontAwesome Icons */
import { faCheckCircle, faTimesCircle, faStar, faPlusCircle, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { Caracteristics, Effect, zeroedCaracteristics } from 'src/app/core/models/effect.model';
import { Unit } from 'src/app/core/models/unit.model';
import { SWExporterTypes } from 'src/app/core/types/sw-exporter.types';
import { ImportService } from 'src/app/services/import.service';
import { SwarFarmAPIService } from 'src/app/services/swarfarm-api.service';
import { StatsCalculatorService } from 'src/app/services/stats-calculator.service';
import buildingDataStore from 'src/app/core/stores/building-data.store';
import { Building } from 'src/app/core/models/building.model';
import { Rune } from 'src/app/core/models/rune.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import runeSetDataStore from 'src/app/core/stores/rune-set-data.store';
import { BuildingService } from 'src/app/services/building.service';

@Component({
  selector: 'app-icaru',
  templateUrl: './icaru.component.html',
  styleUrls: ['./icaru.component.scss']
})
export class IcaruComponent implements OnInit {
  /* Data */
  buildings: Building[] = [];
  requiredSpd: boolean = false;
  actualMaxSpd: number;
  actualMaxAccuracy: number;
  //
  selectedRune: Rune;
  showSelectVerde: boolean = false;
  /* FontAwesome Icons */
  star = faStar;
  checkCircle = faCheckCircle;
  timeCircle = faTimesCircle;
  plusCircle = faPlusCircle;
  edit = faPenSquare;
  /* Verde Stats */
  eHpVerde: number;
  verdeBoostedAtk: number;
  bossReduction: number;
  finalDmg: number;
  /* Bonus Stats */
  determinationSet: number =0;
  fightSet: number = 0;
  guildSkill: 0 | 3 | 5 =0;
  /* Skill Bonus */
  skillUp: number = 30;
  skillCrit: number = 0;
  /* Artifacts Bonus Damage */
  dmgBonusPerAtk: number =0;
  dmgBonusPerHp: number =0;
  dmgBonusPerDef: number = 0;
  dmgBonusPerSpeed: number = 0;
  dmgBonusFirstSkill: number = 0;
  dmgDealtOnFire: number = 0;
  critBonusGoodHp: number = 0;
  critBonusBadHp: number = 0;
  //
  tricaruMinDef: number
  //
  icarus : Unit[] = []
  verdehiles: Unit[] = []
  selectedVerdehile: Unit
  //
  verdehileEHP: number
  verdehileDmgPerHit : number
  verdehileCritRate: number
  fastestIcaruSpeed: number
  // 
  icarusCaracs : Caracteristics[] = []
  selectedVerdehileCaracs: Caracteristics = zeroedCaracteristics()
  // Count total activated sets
  runeSetCount: Map<SWExporterTypes.SetType,number>

  constructor(private buildingService: BuildingService ,private browserStorage: LocalStorageService, private statCalculator: StatsCalculatorService, private importService: ImportService, private swarfarmApi: SwarFarmAPIService, private swarfarm: SwarFarmAPIService) { }

  async ngOnInit() {
    this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
      SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.WATER_SANCTUARY, SWExporterTypes.BuildingType.FIRE_SANCTUARY,
      SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);

    let t = this.browserStorage.Get<Unit>("verde_select")
    this.selectedVerdehile = t? new Unit(t): undefined
    this.guildSkill = 5;

    // recreate units to prevent sync of data. // TODO use observables.
    this.icarus = this.importService.GetUnits().filter(n => n.id == 11031).map(n => new Unit(n));
    // Handle the case of less than 3 icaru found.
    if(this.icarus.length < 3) {
      let base_icaru = await this.swarfarm.GetMonsterById(11031)
      for(let i = this.icarus.length ; i <3; i++) {
        this.icarus.push(new Unit(base_icaru)) // Copy base icaru until there is in the array.
      }
    }
    //
    this.verdehiles = this.importService.GetUnits().filter(n => n.name == "Verdehile").map(n => new Unit(n));
    if (this.verdehiles.length == 0) {
      let base_verdhe = await this.swarfarm.GetMonsterByName("Verdehile")
      this.onVerdeSelect(new Unit(base_verdhe))
    } else {
      this.onVerdeSelect(this.selectedVerdehile)
    }

    // count determination enabled set 
    this.determinationSet = this.icarus.map(n => n.enabledRuneSets.filter(r => r.name == "Determination").length).reduce((prev, current) => prev + current)
    if (this.selectedVerdehile) {
      this.determinationSet += this.selectedVerdehile.enabledRuneSets.filter(r => r.name == "Determination").length
    }
    this.computeTricaru();
    this.computeVerdehile();
  }

  ICARU_REQUIRED_SPEED = 170
  MIN_DEF_RAW = 3300
  MIN_FINAL_DAMAGE = 3300

  VERDE_MIN_EHP = 81000
  VERDE_LEAD_SKILL=28 // Speed Percent
  DETERSET_DEF_BONUS=0.08 

  computeTricaru() {
    // compute verdehille deterset count
  
    // add additional theory deterset count.
    this.icarusCaracs = this.icarus.map((n, i) => {
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: SWExporterTypes.EffectType.SPEEDPercent,
        value: this.VERDE_LEAD_SKILL
      })
      // 
      return this.statCalculator.ComputeStats(this.icarus[i], this.icarus.filter((n, index) => index != i), [leaderEffect], this.buildings, this.guildSkill)
    })

    let defBuilding = this.buildings.filter(n => n.id == SWExporterTypes.BuildingType.GUARDSTONE)[0]
    // compute minimal def needed according to current building, guildskill, and determination sets number.
    this.tricaruMinDef = this.MIN_DEF_RAW - (this.icarus[0].baseCaracteristics.Defense * (defBuilding.computeTotalBonus(defBuilding.level) + this.guildSkill)/100 + this.icarus[0].baseCaracteristics.Defense) - (this.icarus[0].baseCaracteristics.Defense * this.DETERSET_DEF_BONUS * this.determinationSet);
    
    this.actualMaxAccuracy = Math.max(...this.icarusCaracs.map(n => n.Accuracy))
    this.actualMaxSpd = Math.max(...this.icarusCaracs.map(n => n.Spd))
    
    this.requiredSpd = this.icarusCaracs.filter(n => n.Spd > this.ICARU_REQUIRED_SPEED).length == 0
  }

  computeVerdehile() {
    if (this.selectedVerdehile != undefined ) {
      // TODO store lead effects in unit.
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: SWExporterTypes.EffectType.SPEED,
        value: 28
      })
      //
      let additionalSets = []
      for (let i=0; i < this.fightSet; i++) {
        additionalSets.push(runeSetDataStore.GetRuneSetFromType(SWExporterTypes.SetType.FIGHT))
      }
      for (let i=0; i < this.determinationSet; i++) {
        additionalSets.push(runeSetDataStore.GetRuneSetFromType(SWExporterTypes.SetType.DETERMINATION))
      }
      this.selectedVerdehileCaracs = this.statCalculator.ComputeStats(this.selectedVerdehile, this.icarus, [leaderEffect], this.buildings, this.guildSkill, additionalSets)
      this.eHpVerde = this.statCalculator.ComputeEHP(this.selectedVerdehileCaracs)

      let targetDef = 3605

      let hits = this.statCalculator.ComputeSkillHit(
        this.selectedVerdehileCaracs, {
          multipliers: [],
          attackMultiplier: 2,
          defMultiplier: 0,
          livingAllyMultiplier: 0,
          maxHpTargetMultiplier: 0,
          maxHpMultiplier: 0,
          spdAdd: 0,
          spdDivider: 0,
          targetSpd: 0,
          livingAlly: 0,
          hitCount: 2,
          skillUpBonus: this.skillUp,
          selectedSkill: {}
        },
        this.dmgBonusFirstSkill,
        0,
        this.critBonusGoodHp,
        this.critBonusBadHp,
        targetDef,
        true,
        false,
        0
      )

      let artifactBonusDamage = this.statCalculator.ComputeArtefactBonusDamage(
        this.selectedVerdehileCaracs, 
        this.dmgBonusPerSpeed, this.dmgBonusPerDef,this.dmgBonusPerHp,this.dmgBonusPerAtk
      )

      this.finalDmg = (1+ (this.dmgDealtOnFire/100)) * hits.minDamage + artifactBonusDamage
      this.verdehileCritRate = this.selectedVerdehile.totalCaracteristics['Critical Rate']
      if(this.verdehileCritRate > 100){
        this.verdehileCritRate = 100
      }
    }
  }

  onChange() {
    this.computeTricaru()
    this.computeVerdehile()
  }

  verdehileDisplay(){
    this.onChange();
  }

  tricaruDisplay(){
    this.onChange();
  }
  onRuneSelect(rune: Rune): void {
    this.selectedRune = rune;
  }
  onVerdeSelect(verde: Unit): void {
    this.selectedVerdehile = verde;
    // TODO add clean method (that reinit everything to 0)
    this.getVerdehileArtifacts();
    this.computeVerdehile();

    this.showSelectVerde = true;
    this.browserStorage.Set<Unit>("verde_select", this.selectedVerdehile);
  }


  showSelect(){
    
    this.browserStorage.Remove("verde_select");
    this.selectedVerdehile = undefined
    this.showSelectVerde = false
    this.eHpVerde = undefined
    this.finalDmg = undefined
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    this.critBonusGoodHp =0 
    this.critBonusBadHp =0
    this.dmgBonusFirstSkill =0
    this.dmgDealtOnFire =0
  }

  getKeys(obj:any){
    return Object.keys(obj)
  }

  getEffectTypeString(effectType: SWExporterTypes.EffectType) {
    return Effect.typeStr[effectType]
  }

  getVerdehileArtifacts(){
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    this.critBonusGoodHp =0 
    this.critBonusBadHp =0
    this.dmgBonusFirstSkill =0
    this.dmgDealtOnFire =0
    for(let artifact in this.selectedVerdehile.artifacts){
      if(this.selectedVerdehile.artifacts[artifact] != undefined){
        for(let sec_eff in this.selectedVerdehile.artifacts[artifact].secondaryEffects){
          let effect = this.selectedVerdehile.artifacts[artifact].secondaryEffects[sec_eff];

          switch(effect.type) {
            case 218:
              this.dmgBonusPerHp += effect.value
              break;
            case 219: 
              this.dmgBonusPerAtk += effect.value
              break;
            case 220: 
              this.dmgBonusPerDef += effect.value
              break;
            case 221: 
              this.dmgBonusPerSpeed += effect.value
              break;
            case 222: 
              this.critBonusGoodHp += effect.value 
              break;           
            case 223: 
              this.critBonusBadHp += effect.value
              break;
            case 400: 
              this.dmgBonusFirstSkill += effect.value
              break;
            case 300: 
              this.dmgDealtOnFire += effect.value
              break;
          }
        }
      }
    }
  }

  array(n: number) {
    return Array(n)
  }
}
