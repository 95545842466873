<mat-radio-group [(ngModel)]="selectedUnit" (change)="onSelectUnit()">
    <h2 class="text-sw-orange p-4 border-top-accent">{{title}}</h2> 
    <mat-radio-button *ngFor="let unit of units" [value]="unit" class="flex flex-col px-4">
        <div class="flex flex-row items-center">
            <div class="loren flex flex-row items-center m-4">
            <img class="rounded-full w-20px h-20px" src="https://swarfarm.com/static/herders/images/monsters/{{unit.image}}">
            <span class="mx-2">{{unit.name}}</span>
            /
            <span class="mx-2">Lvl. {{unit.level}}</span>
            </div> 
            <div class="flex flex-row">
                <img class="w-20px h-20px" *ngFor="let set of unit.enabledRuneSets" src="{{set.image}}"/>
            </div> 
        </div>
    </mat-radio-button>
</mat-radio-group>

