<h1 mat-dialog-title class="title">Caiross Dungeon</h1>
<div class="import-box-flex second">
  <div class="flex-wrapper caiross">
    <img src="../../assets/img/boss/giant_caiross.jpg">
    <div class="flex-wrapper stats">
      <span>Win Rate  <span class="accent">{{this.data.giantWinCount }}%</span></span>
      <span>Average Time - <span class="accent">{{this.data.averageTimeGiant | minuteSeconds }}</span></span>
    </div>
  </div>
  <div class="flex-wrapper caiross">
    <img src="../../assets/img/boss/dragon_caiross.jpg">
    <div class="flex-wrapper stats">
      <span>Win Rate <span class="accent">{{this.data.dragonWinCount }}%</span></span>
      <span>Average Time - <span class="accent">{{this.data.averageTimeDragon | minuteSeconds }}</span></span>
    </div>
  </div>
  <div class="flex-wrapper caiross">
    <img src="../../assets/img/boss/necropolis_caiross.jpg">
    <div class="flex-wrapper stats">
      <span>Win Rate <span class="accent">{{this.data.necroWinCount }}%</span></span>
      <span>Average Time - <span class="accent">{{this.data.averageTimeNecropolis | minuteSeconds }}</span></span>
    </div>
  </div>
</div>