import { Injectable } from '@angular/core';

import { compressToUTF16, decompressFromUTF16, compress, decompress } from 'lz-string';

@Injectable()
export class CompressionService {

  compressString(data: string): string {
    if(navigator.userAgent.indexOf("Firefox") != -1){
      return compressToUTF16(data);
    } else {
      return compress(data);
    }
  }
  compressObject(data: any): string {
    if(navigator.userAgent.indexOf("Firefox") != -1){
      return compressToUTF16(JSON.stringify(data));
    } else {
      return compress(JSON.stringify(data));
    }
  }

  decompressString(compressed: string): string {
    if(navigator.userAgent.indexOf("Firefox") != -1){
      return decompressFromUTF16(compressed);
    } else {
      return decompress(compressed);
    }
  }
  decompressObject(compressed: string): any {
    if(navigator.userAgent.indexOf("Firefox") != -1){
      return JSON.parse(decompressFromUTF16(compressed));
    } else {
      return JSON.parse(decompress(compressed));
    }
  }
}
