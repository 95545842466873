import { Component, Input, OnInit } from "@angular/core";
import { faSlidersH, faStar } from "@fortawesome/free-solid-svg-icons";
import { Artifact } from "src/app/core/models/artifact.model";
import { Effect, InGameEffect } from "../../core/models/effect.model";
import runeSetDataStore from "../../core/stores/rune-set-data.store";
import { SWExporterTypes } from "../../core/types/sw-exporter.types";

@Component({
    selector: "artifact-card",
    templateUrl: "./artifact-card.template.html",
    styleUrls: ["../../../styles.css"],
  })
  export class ArtifactCardComponent implements OnInit {

    /* FontAwesome Icons */
    star = faStar;
    slider = faSlidersH;
    /* Table */

    @Input() artifact?: Artifact // artifact item.
  
    constructor() {}
  
    ngOnInit() {

    }
  
    getEffectTypeString(effectType: SWExporterTypes.EffectType) {
      return Effect.typeStr[effectType]
    }

    getInGameEffectTypeString(effectType: SWExporterTypes.InGameEffectType) {
      return InGameEffect.typeStr[effectType]
    }  
  
    getSetName(setType: SWExporterTypes.SetType) {
      return runeSetDataStore.GetRuneSetFromType(setType).name
    }
  
    getKeys(obj:any){
      return Object.keys(obj)
    }
  }