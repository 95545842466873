import { RuneSet } from "../models/runeset.model";
import { SWExporterTypes } from "../types/sw-exporter.types"

class RuneSetStore {
    RuneSets: Map<SWExporterTypes.SetType, RuneSet> = new Map()

    private static _instance: RuneSetStore;

    private constructor() {
// Base stats
        this.createRuneSet(SWExporterTypes.SetType.ENERGY, "Energy", 2, 15,SWExporterTypes.EffectType.HPPercent)
        this.createRuneSet(SWExporterTypes.SetType.GUARD, "Guard", 2, 15, SWExporterTypes.EffectType.DEFPercent)
        this.createRuneSet(SWExporterTypes.SetType.SWIFT, "Swift", 4, 25, SWExporterTypes.EffectType.SPEEDPercent)
        this.createRuneSet(SWExporterTypes.SetType.BLADE, "Blade", 2, 12, SWExporterTypes.EffectType.CRITRate)
        this.createRuneSet(SWExporterTypes.SetType.RAGE, "Rage", 4, 40,  SWExporterTypes.EffectType.CRITDmg)
        this.createRuneSet(SWExporterTypes.SetType.FOCUS, "Focus", 2, 20, SWExporterTypes.EffectType.ACC)
        this.createRuneSet(SWExporterTypes.SetType.ENDURE, "Endure", 2, 20,SWExporterTypes.EffectType.RES)
        this.createRuneSet(SWExporterTypes.SetType.FATAL, "Fatal", 4, 35, SWExporterTypes.EffectType.ATKPercent)
//
        this.createRuneSet(SWExporterTypes.SetType.VIOLENT, "Violent", 4, 22, SWExporterTypes.EffectType.REPLAY_CHANCE)
        this.createRuneSet(SWExporterTypes.SetType.DESPAIR, "Despair", 4, 25, SWExporterTypes.EffectType.STUN_CHANCE)
        this.createRuneSet(SWExporterTypes.SetType.VAMPIRE, "Vampire", 4, 35, SWExporterTypes.EffectType.LIFE_STEAL)
        this.createRuneSet(SWExporterTypes.SetType.NEMESIS, "Nemesis", 2, 4, SWExporterTypes.EffectType.ATB_MISSING_HEALTH)
        this.createRuneSet(SWExporterTypes.SetType.REVENGE, "Revenge", 2, 15, SWExporterTypes.EffectType.COUNTER_ATTACK_CHANCE)
        this.createRuneSet(SWExporterTypes.SetType.DESTROY, "Destroy", 2, 4, SWExporterTypes.EffectType.HPPercent_DESTRUCTION)
// Status effects 
        this.createRuneSet(SWExporterTypes.SetType.WILL, "Will", 2, 1,   SWExporterTypes.EffectType.WILL)
        this.createRuneSet(SWExporterTypes.SetType.SHIELD, "Shield", 2, 1, SWExporterTypes.EffectType.SHIELD)
// Ally + self buffs 
        this.createRuneSet(SWExporterTypes.SetType.FIGHT, "Fight", 2, 8,  SWExporterTypes.EffectType.ATKPercent)
        this.createRuneSet(SWExporterTypes.SetType.DETERMINATION, "Determination", 2, 8, SWExporterTypes.EffectType.DEFPercent)
        this.createRuneSet(SWExporterTypes.SetType.ENHANCE, "Enhance", 2, 8, SWExporterTypes.EffectType.HPPercent)
        this.createRuneSet(SWExporterTypes.SetType.ACCURACY, "Accuracy", 2, 10, SWExporterTypes.EffectType.ACC)
        this.createRuneSet(SWExporterTypes.SetType.TOLERANCE, "Tolerance", 2, 10, SWExporterTypes.EffectType.RES)
    }

    private createRuneSet(setType: SWExporterTypes.SetType, name: string, minimumRuneRequired: number, modifier:number, effect: SWExporterTypes.EffectType) {
        this.RuneSets.set(setType, new RuneSet(setType, name, minimumRuneRequired, modifier, effect))
    }

    GetRuneSetFromType(setType: SWExporterTypes.SetType) : RuneSet{
        if (this.RuneSets.has(setType)) {
            return this.RuneSets.get(setType)
        }
        // Return null if set is not defined, for now it is tied to the limited number defined above.
        return null
    }

    static GetInstance() {
      return this._instance || (this._instance = new this());
    }
}

export default RuneSetStore.GetInstance()