import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Effect } from "../core/models/effect.model";
import { Rune } from "../core/models/rune.model";
import { SWCalculatorTypes } from "../core/types/sw-calculator.types";
import { SWExporterTypes } from "../core/types/sw-exporter.types";
import { ImportService } from "./import.service";

@Injectable({
    providedIn: 'root',
})
export class RuneFilterService {

  rune$ = new Subject<Rune[]>()
  filters: ((rune: Rune) => boolean)[]
  runeTotal = []
  enumSet = []

  setTypeEnum = []

  availableFilters: {
    slot: MultiSelectFilter<Rune, SWExporterTypes.RuneSlot>,
    extra: MultiSelectFilter<Rune, SWExporterTypes.Extra>,
    rank: MultiSelectFilter<Rune, SWExporterTypes.Rank>,
    level: SliderFilter<Rune>,
    stars: SliderFilter<Rune>,
    set: MultiSelectFilter<Rune, SWExporterTypes.SetType>,
    mainStat: MultiSelectFilter<Rune, SWExporterTypes.EffectType>,
    innateStat: MultiSelectFilter<Rune, SWExporterTypes.EffectType>,
    secondaryEffect: MultiSelectFilter<Rune, SWExporterTypes.EffectType>,
    isAssigned: ThreeChoiceFilter<Rune>,
    isAncient: ThreeChoiceFilter<Rune>,
    
  }
  
  constructor(private importService: ImportService) {
    this.availableFilters = {
      slot: new MultiSelectFilter<Rune, SWExporterTypes.RuneSlot>([], (rune: Rune, selectedOptions: SWExporterTypes.RuneSlot[])=> {
        return selectedOptions?.includes(rune.slotFactor) || selectedOptions?.length == 0
      }),
      extra: new MultiSelectFilter<Rune, SWExporterTypes.Extra>([], (rune: Rune, selectedOptions: SWExporterTypes.Extra[])=> {
        return selectedOptions?.includes(rune.extra %10) || selectedOptions?.length == 0
      }),
      rank: new MultiSelectFilter<Rune, SWExporterTypes.Rank>([], (rune: Rune, selectedOptions: SWExporterTypes.Rank[])=> {
        return selectedOptions?.includes(rune.rank %10) || selectedOptions?.length == 0
      }),
      stars: new SliderFilter<Rune>(1, 6, (rune: Rune, min: number, max: number)=> {
        return rune.stars >= min && rune.stars <= max
      }),
      level: new SliderFilter<Rune>(0, 15, (rune: Rune, min: number, max: number)=> {
        return rune.upgradeLevel >= min && rune.upgradeLevel <= max
      }),
      set: new MultiSelectFilter<Rune, SWExporterTypes.SetType>([], (rune: Rune, selectedOptions: SWExporterTypes.SetType[]) => {
        return selectedOptions?.includes(rune.setType) || selectedOptions?.length == 0
      }),
      mainStat: new MultiSelectFilter<Rune, SWExporterTypes.EffectType>([], (rune: Rune, selectedOptions: SWExporterTypes.EffectType[]) => {
        return selectedOptions.includes(rune.primaryEffect.type) || selectedOptions?.length == 0
      }),
      innateStat: new MultiSelectFilter<Rune, SWExporterTypes.EffectType>([], (rune: Rune, selectedOptions: SWExporterTypes.EffectType[]) => {
        return selectedOptions.includes(rune.innateEffect.type) || selectedOptions?.length == 0
      }),
      secondaryEffect: new MultiSelectFilter<Rune, SWExporterTypes.EffectType>([], (rune: Rune, selectedOptions: SWExporterTypes.EffectType[]) => {
        let secondaryEffects = rune.secondaryEffects.filter(effect => selectedOptions?.includes(effect.type))
        return secondaryEffects.length >= selectedOptions?.length
      }),
      isAssigned: new ThreeChoiceFilter<Rune>((rune: Rune, state?: boolean) => {
        if(state == null){
          return true
        }
        if(state === true){
          return rune.unitImage != null
        }
        if(state === false){
          return rune.unitImage == null
        } 
      }),
      isAncient: new ThreeChoiceFilter<Rune>((rune: Rune, state?: boolean) => {
        if(state == null){
          return true
        }
        return rune.isAntique == state
      })
    }

    this.rune$.next(this.getRunes())
  }

  private getRunes(){
    return this.importService.GetStorageRunes()
  }

  getFilteredRune(){
    return this.rune$
  }

  ApplyFilters() {

    let filteredRunes = this.getRunes()
    .filter(this.availableFilters.isAncient.Apply())
    .filter(this.availableFilters.isAssigned.Apply())
    .filter(this.availableFilters.secondaryEffect.Apply())
    .filter(this.availableFilters.slot.Apply())
    .filter(this.availableFilters.extra.Apply())
    .filter(this.availableFilters.rank.Apply())
    .filter(this.availableFilters.stars.Apply())
    .filter(this.availableFilters.level.Apply())
    .filter(this.availableFilters.set.Apply())
    .filter(this.availableFilters.mainStat.Apply())
    .filter(this.availableFilters.innateStat.Apply())

    this.rune$.next(filteredRunes)
  }
}


interface Filter<T> {
  Apply(): (item: T)=> any
}

class SliderFilter<T> implements Filter<T> {
  // selected range
  private _selectedMin: number
  private _selectedMax: number
  
  constructor(private minimumValue: number, private maximumValue: number, private filterFunction: (item: T, minimum:number, maximum:number) => boolean, defaultMinValue= minimumValue, defaultMaxValue=maximumValue){
    this._selectedMin = defaultMinValue
    this._selectedMax = defaultMaxValue
  }

  get selectedMin() {
    return this._selectedMin
  }

  set selectedMin(value: number) {
    this._selectedMin = value
  }
  
  get selectedMax() {
    return this._selectedMax
  }

  set selectedMax(value: number) {
    this._selectedMax = value
  }

  Apply(){
    return (item:T) => {
      return this.filterFunction(item, this._selectedMin, this._selectedMax)
    }
  }
}


class SelectFilter<T, I> implements Filter<T> {
  
  selectedOption: I

  constructor(availableChoices: I[], private filterFunction: (item: T, selectedOption: I) => boolean) {

  }

  Apply(){
    return (item: T) => {
       return this.filterFunction(item, this.selectedOption)
    }
  }
}

class MultiSelectFilter<T, I> implements Filter<T> {
  
  selectedOptions: I[]

  constructor(availableChoices: I[], private filterFunction: (item: T, selectedOptions: I[]) => boolean) {

  }

  Apply(){
    return (item: T) => {
      return this.filterFunction(item, this.selectedOptions)
    }
  }
}

class ThreeChoiceFilter<T> implements Filter<T> {

  state?: boolean

  constructor(private filterFunction: (item: T, state?: boolean) => boolean) {

  }

  Apply(){
    return (item: T) => {
      return this.filterFunction(item, this.state)
    }
  }
}

class SearchFilter<T> implements Filter<T> {
  searchValue: string

  constructor(private filterFunction: (item: T, searchValue: string) => boolean) {

  }

  Apply(){
    return (item: T) => {
      return this.filterFunction(item, this.searchValue)
    }
  }
}
