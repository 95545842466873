import RuneSetDataStore from "../stores/rune-set-data.store"
import { SWCalculatorTypes } from "../types/sw-calculator.types"
import { SWExporterTypes } from "../types/sw-exporter.types"
import { emptyRuneSlot, Rune} from "./rune.model"
import { Artifact, emptyArtifactSlot } from "./artifact.model"
import { RuneSet } from "./runeset.model"
import { Caracteristics, InGameEffect, zeroedCaracteristics } from "./effect.model"

// RuneSlot type helper
export type RuneSlot = Record<SWExporterTypes.RuneSlot,Rune | null>
// Artifact Slot type helper
export type ArtifactSlot = Record<SWCalculatorTypes.ArtifactSlot, Artifact | null>

export class Unit implements SWCalculatorTypes.Unit {
    // TODO make it available elsewhere than units. 
    id: number
    name: string
    image: string
    level: number
    attribute: SWExporterTypes.Attribute
    archetype: SWExporterTypes.Archetype
    // Base stats of a monster at max_level (40)
    baseCaracteristics: Caracteristics
    // Caracteristics computed from runes / artifacts
    bonusCaracteristics: Caracteristics
    // Caracteristics manualy edited by the user (allow freely theorycraft)
    manualAdditionalCaracteristics: Caracteristics = zeroedCaracteristics()
    enabledRuneSets: RuneSet[] = []
    runes: RuneSlot = emptyRuneSlot()
    artifacts: ArtifactSlot = emptyArtifactSlot()
    skills: any[];
    spells: any[];

    constructor(data: SWCalculatorTypes.Unit) {
        this.id = data.id
        this.name = data.name
        this.image = data.image
        this.level = data.level
        this.attribute = data.attribute
        this.archetype = data.archetype
        this.baseCaracteristics = data.baseCaracteristics
        this.skills = data.skills
        this.spells = data.spells
        for(let i in data.runes) {
            let rune = null
            if (data.runes[i] != undefined) {
                rune = new Rune(data.runes[i])
                this.AttachRune(rune)
            }
        }
        for(let i in data.artifacts)
        {
            let artifact = null
            if (data.artifacts[i] != undefined) {
                artifact = new Artifact(data.artifacts[i])
                this.AttachArtifact(artifact)
            }
        }
        
        this.computeRuneSet()
        this.computeAdditionalCaracteristics()
    }

    get damageBonus(): number {
        let c = 0
        for(let i in this.artifacts) {
            let artifact: Artifact = this.artifacts[i]
            if (artifact) {
                for(let j in artifact.secondaryEffects){
                    let subEffect = artifact.secondaryEffects[j]
                    switch(subEffect.type) {
                        case SWExporterTypes.InGameEffectType.DAMAGE_BY_ATK:
                        case SWExporterTypes.InGameEffectType.DAMAGE_BY_DEF:
                        case SWExporterTypes.InGameEffectType.DAMAGE_BY_HP:
                        case SWExporterTypes.InGameEffectType.DAMAGE_BY_SPEED:
                            c += subEffect.Apply(this.additionalCaracteristics)
                        default:
                            console.log("Artifact Effect not supported")
                            break
                    }
                }
            }
        }

        return 
    }

    get totalCaracteristics():Caracteristics {
        return this.combineCaracteristics(
            this.combineCaracteristics(this.baseCaracteristics, this.bonusCaracteristics),
            this.manualAdditionalCaracteristics
        )
    }

    get additionalCaracteristics():Caracteristics {
        return this.combineCaracteristics(this.bonusCaracteristics, this.manualAdditionalCaracteristics)
    }

// Attache rune to the monster, and recompute additional caracteristics as well as enanbled runeSets. 
    AttachRune(rune: Rune) {
        if(this.runes[rune.slotFactor] != undefined) {
            console.log("Overriding rune in slot", rune.slotFactor)
        }
        if(rune != undefined){
            rune.unitImage = this.image
            this.runes[rune.slotFactor] = rune
        }
        this.computeRuneSet()
        this.computeAdditionalCaracteristics()
    }

// Attach artifact to the monster and recompute additional caracteristics
    AttachArtifact(artifact: Artifact) {
        // TODO add constraint for artifact type or element appropriate to on matching monster.
        if(this.artifacts[artifact.slot] != undefined) {
            console.log("Overriding artifact in slot", artifact.slot)
        }
        if(artifact != undefined){
            // artifact.unit = this
            this.artifacts[artifact.slot] = artifact
        }
        this.computeAdditionalCaracteristics()
    }

// Enable rune sets based on equipped runes.
    private computeRuneSet() {
        // Compute sets when rune changes.
        let runeCount = new Map<SWExporterTypes.SetType, number>();
        // Clear enabled Runesets 
        this.enabledRuneSets = []
        for(let key in this.runes) {
            let rune = this.runes[key] as Rune
            if (rune != undefined) {
                if (!runeCount.has(rune.setType)) {
                    runeCount.set(rune.setType, 0)
                }
                runeCount.set(rune.setType, runeCount.get(rune.setType) + 1);
                // 
                let set = RuneSetDataStore.GetRuneSetFromType(rune.setType)
                if (set == undefined) {
                    console.log("Set", rune.setType, "is not supported yet, skipping.")
                    continue
                }
                if(set.minRuneRequired == runeCount.get(rune.setType)) {
                    // Add set. 
                    this.enabledRuneSets.push(set)
                    // Reset rune Count
                    runeCount.set(rune.setType, 0)
                }

            }
        }
    }
// Compute additionnal caracteristics by applying effects recursively
    private computeAdditionalCaracteristics() {
        let computedCaracteristics :Caracteristics = zeroedCaracteristics()
        // First we compute additional stats given by runes.
        for(let slotNumber in this.runes) {
            let rune = this.runes[slotNumber]
            if (rune != undefined)  {
                // apply main effect 
                computedCaracteristics = this.combineCaracteristics(rune.primaryEffect.Apply(this.baseCaracteristics), computedCaracteristics)
                // apply secondary effects
                for(let index in rune.secondaryEffects) {
                    computedCaracteristics = this.combineCaracteristics(rune.secondaryEffects[index].Apply(this.baseCaracteristics), computedCaracteristics)
                }
                // apply innate effect
                computedCaracteristics = this.combineCaracteristics(rune.innateEffect.Apply(this.baseCaracteristics), computedCaracteristics)
            }
        }
        // Then we compute artifact main effect stats modifier.
        for(let slotNumber in this.artifacts) {
            let artifact = this.artifacts[slotNumber]
            if (artifact != undefined) {
                computedCaracteristics = this.combineCaracteristics(artifact.primaryEffect.Apply(this.baseCaracteristics), computedCaracteristics)
            }
        }

        // Finally we round up everything
        // !! Not sure roundup is applied here or each time a % is applied 
        //          (which would make more sense but less precision)
        for(let i in computedCaracteristics) {
            computedCaracteristics[i] = Math.ceil(computedCaracteristics[i])
        }

        // Then we compute rune sets effect stats modifiers.
        for(let i in this.enabledRuneSets) {
            switch (this.enabledRuneSets[i].id) {
                // Sets that have effect over the whole team are not used in the calculus for additional caracs..
                case SWExporterTypes.SetType.FIGHT: 
                case SWExporterTypes.SetType.DETERMINATION:
                case SWExporterTypes.SetType.ENHANCE:
                case SWExporterTypes.SetType.ACCURACY:
                case SWExporterTypes.SetType.TOLERANCE:
                    break;
                default:
                    computedCaracteristics = this.combineCaracteristics(this.enabledRuneSets[i].effect.Apply(this.baseCaracteristics), computedCaracteristics)
            }

            for(let i in computedCaracteristics) {
                computedCaracteristics[i] = Math.ceil(computedCaracteristics[i])
            }
        }

        this.bonusCaracteristics = computedCaracteristics
    }

    // helper method to combine caracteristics.
    private combineCaracteristics(record1: Caracteristics, record2: Caracteristics) {
        let combined = zeroedCaracteristics()
        for(let i in record1) {
            combined[i] = record2[i] + record1[i]
        }
        return combined
    }
}

export class Team { 
    leader: Unit
    team: Unit[] = []
}