import { Component, OnInit } from "@angular/core";
import { RuneFilterService } from "src/app/services/rune-filter.service";
import { Options } from '@angular-slider/ngx-slider';
import { faStar, faFilter } from "@fortawesome/free-solid-svg-icons";
import { SWExporterTypes } from "src/app/core/types/sw-exporter.types";

@Component({
    selector: "rune-filter",
    templateUrl: "./rune-filter.template.html",
    styleUrls: ["./rune-filter.component.scss"],
  })
  export class RuneFilterComponent implements OnInit {
    /* FontAwesome Icons */
    star = faStar;
    filter = faFilter;
    //Rune Stars
    minStarsSelect: number = 1;
    maxStarsSelect: number = 6;
    optionsStars: Options = {
      floor: 1,
      ceil: 6,
      step: 1,
      showTicks: true
    };
    //Rune Levels
    minLevelsSelect: number = 1;
    maxLevelsSelect: number = 15;
    optionsLevels: Options = {
      floor: 1,
      ceil: 15,
      step: 1,
      showTicks: true
    };
    // Multi Select ngModel
    selectedSets = []
    selectedMainStats = []
    seletedInnateStats = []
    selectedSubStats = []
    selectedExtra = []
    selectedRank = []
    selectedSlot = []
    
    sets = []
    extras = []
    ranks = []
    stats = []
    slots = []
    innateStats = []
    subStats = []

    setId: any = []
    setValue: any = []

    assignmentState?: boolean
    ancientState?:boolean

    reapTarget: boolean = false
    
    constructor(private runeFilterService: RuneFilterService) {}
  
    ngOnInit() {
      this.getSlot();
      this.getExtraRarity();
      this.getSetTypes();
      this.getMainStats();
      this.onSlidersChange();
    }

    onSlidersChange() {
      const STARS_FILTER = this.runeFilterService.availableFilters.stars;
      const LEVELS_FILTER = this.runeFilterService.availableFilters.level;
      const EXTRA = this.runeFilterService.availableFilters.extra;
      const RANK = this.runeFilterService.availableFilters.rank;
      const SETS_FILTER = this.runeFilterService.availableFilters.set;
      const MAIN_STATS_FILTER = this.runeFilterService.availableFilters.mainStat;
      const INNATE_STATS_FILTER = this.runeFilterService.availableFilters.innateStat;
      const SLOT = this.runeFilterService.availableFilters.slot;
      const SUB_STATS = this.runeFilterService.availableFilters.secondaryEffect;
      const IS_ASSIGNED = this.runeFilterService.availableFilters.isAssigned;
      const IS_ANCIENT = this.runeFilterService.availableFilters.isAncient;
      //Filter By Slot
      SLOT.selectedOptions = this.selectedSlot;
      //Filter By Rarity
      EXTRA.selectedOptions = this.selectedExtra;
      RANK.selectedOptions = this.selectedRank;
      //Filter By Stars
      STARS_FILTER.selectedMin = this.minStarsSelect;
      STARS_FILTER.selectedMax = this.maxStarsSelect;
      //Filter By Levels
      LEVELS_FILTER.selectedMin = this.minLevelsSelect;
      LEVELS_FILTER.selectedMax = this.maxLevelsSelect;
      //Filter By Sets
      SETS_FILTER.selectedOptions = this.selectedSets;
      //Filter By Stats
      MAIN_STATS_FILTER.selectedOptions = this.selectedMainStats
      INNATE_STATS_FILTER.selectedOptions = this.seletedInnateStats
      SUB_STATS.selectedOptions = this.selectedSubStats
      //Filter By assignment
      IS_ASSIGNED.state = this.assignmentState
      //Filter By Ancient
      IS_ANCIENT.state = this.ancientState

      this.runeFilterService.ApplyFilters()
    }

    getExtraRarity(){
      let rarity: {id: number; value: string}[] = [];

      for(var n in SWExporterTypes.Extra) {
        if (typeof SWExporterTypes.Extra[n] === 'number') {
          rarity.push({id: <any>SWExporterTypes.Extra[n], value: n});
        }
      }

      rarity.splice(5, 9)
      this.extras = rarity;
      this.ranks = rarity
    }

    getSlot(){
      let slot: {id: number; value: string}[] = [];

      for(var n in SWExporterTypes.RuneSlot) {
        if (typeof SWExporterTypes.RuneSlot[n] === 'number') {
          slot.push({id: <any>SWExporterTypes.RuneSlot[n], value: n});
        }
      }
      this.slots = slot;
    }

    getSetTypes(){
      let setTypes: {id: number; value: string}[] = [];

      for(var n in SWExporterTypes.SetType) {
          if (typeof SWExporterTypes.SetType[n] === 'number') {
            setTypes.push({id: <any>SWExporterTypes.SetType[n], value: n});
          }
      }
      this.sets = setTypes;
    }

    getMainStats(){
      let mainStats: {id: number; value: string}[] = [];
      let innateStats: {id: number; value: string}[] = [];
      let subStats: {id: number; value: string}[] = [];

      for(var n in SWExporterTypes.EffectType) {
          if (typeof SWExporterTypes.EffectType[n] === 'number') {
            mainStats.push({id: <any>SWExporterTypes.EffectType[n], value: n});
            innateStats.push({id: <any>SWExporterTypes.EffectType[n], value: n});
            subStats.push({id: <any>SWExporterTypes.EffectType[n], value: n});
          }
      }

      this.stats = mainStats;
      this.innateStats = innateStats
      this.subStats = subStats

      this.stats.splice(11, 23);
      this.innateStats.splice(11, 23);
      this.subStats.splice(11, 23);

      this.innateStats.unshift({id: 0, value: "NO_EFFECT"})
    }

    onValAncientChange(value){
      if(value == 'All') {
        this.ancientState = null
      }
      if(value == 'Normal') {
        this.ancientState = false
      }
      if(value == 'Ancient') {
        this.ancientState = true
      }
      this.onSlidersChange();
    }

    onValAssignementChange(value){
      if(value == 'All') {
        this.assignmentState = null
      }
      if(value == 'Assigned') {
        this.assignmentState = true
      }
      if(value == 'Storage') {
        this.assignmentState = false
      }
      this.onSlidersChange();
    }
  }