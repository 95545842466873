<div class=""> 
  <div class="full h-28 bg-hero-background title-header bg-center flex-col flex items-center content-center justify-center">
    <h1 class=" font-semibold tracking-widest text-5xl">Informations</h1>
    <media-card></media-card>
    <app-footer></app-footer>
  </div>
  <div class="flex flex-wrap bg-clearBlack overflow-scroll">
    <div class="acknowledgments w-full p-5 bg-sw-gray">
      <h2 class="text-4xl uppercase">Contributors</h2>
    </div>
    <div class="flex flex-wrap flex-col p-5">
      <div class="kannely flex-1">
        <div class="flex flex-row items-center">
          <img class="rounded-full mx-2" src="/assets/img/contrib/kannely.png">
          <h3>Kannely</h3>
        </div>
        <span></span>
      </div>
      <div class="shinneday flex-1">
        <div class="flex flex-row items-center">
          <img class="rounded-full mx-2" src="/assets/img/contrib/shinneday.png">
          <h3>Shinneday</h3>
        </div>
        <span></span>
      </div>
      <div class="blue flex-1 text-center">
        <div class="flex flex-row items-center">
          <img class="rounded-full mx-2" src="/assets/img/contrib/bluedragon.png">
          <h3>Bluedragonfly</h3>
        </div>
        <span>Top tier refactor God</span>
      </div>
    </div>
  </div>
</div>