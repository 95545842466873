import { Attribute, Component, OnInit } from '@angular/core';
import { Building } from 'src/app/core/models/building.model';
import { Caracteristics, Effect, zeroedCaracteristics } from 'src/app/core/models/effect.model';
import { Unit } from 'src/app/core/models/unit.model';
import { SWExporterTypes } from 'src/app/core/types/sw-exporter.types';
import { BuildingService } from 'src/app/services/building.service';
import { ImportService } from 'src/app/services/import.service';
import { StatsCalculatorService } from 'src/app/services/stats-calculator.service';
import { faPlusCircle, faPenSquare } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-artifacts',
  templateUrl: './artifacts.component.html',
  styleUrls: ['./artifacts.component.scss']
})
export class ArtifactsComponent implements OnInit {
  selectedUnit: Unit
  selectedSkill: any
  units: Unit[]
  selectedUnitCaracs: Caracteristics = zeroedCaracteristics()
  unitsCaracs : Caracteristics[] = []
  editedUnit: boolean = false;
  unitType: string;
  leadType: any;
  leadValue: number =0;
  //extras
  guildSkill: 0 | 3 | 5 = 5;
  isBomb: boolean = false;
  //buffs
  atkLead: number = 0;
  isAtkLead: boolean = false;
  hpLead: number = 0;
  isHpLead: boolean = false;
  defLead: number = 0;
  isDefLead: boolean = false;
  isBreakDef: boolean = false;
  atkBuff: number = 0;
  isAtkBuff: boolean = false;
  defBuff: number = 0;
  isDefBuff: boolean = false;
  /* Ennemy */
  ennemyDef: number= 1000;
  ennemyHp: number= 30000;
  isEnnemyDefLead: boolean = false;
  ennemyDefLead: number = 0;
  isEnnemyHpLead: boolean = false;
  ennemyHpLead: number = 0;
  ennemySpd: number = 200;
  /* Skill Bonus */
  skillUp: number = 0;
  skillCrit: number = 0;
  /* Artifacts Bonus Damage */
  //Increasing Effect +x%
  atkIncrease: number = 0;
  defIncrease: number = 0;
  spdIncrease: number = 0;
  //Additional Dmg by x% of
  dmgBonusPerAtk: number =0;
  dmgBonusPerHp: number =0;
  dmgBonusPerDef: number = 0;
  dmgBonusPerSpeed: number = 0;
  //Crit Bonus
  dmgBonusFirstSkill: number = 0;
  dmgBonusSecondSkill: number = 0;
  dmgBonusThirdSkill: number = 0;
  dmgBonusFourthSkill: number = 0;
  //Dealt On
  dmgDealtOnWater: number = 0;
  dmgDealtOnWind: number = 0;
  dmgDealtOnFire: number = 0;
  dmgDealtOnLight: number = 0;
  dmgDealtOnDark: number = 0;
  elementaryAdv: number = 0
  //Crit Bonus
  critBonusGoodHp: number = 0;
  critBonusBadHp: number = 0;
  //Buildings
  buildings: Building[]
  //Dungeons
  dungeons = [
    {name: 'Giant B12', waveHp: 26235},
    {name: 'Dragon B12', waveHp: 28350},
    {name: 'Necropolis B12', waveHp: 18945},
    {name: 'Punisher Crypt B10', waveHp: 24045},
    {name: 'Steel Fortress B10', waveHp: 21615}
  ];
  selectedDungeon: any;
  ennemyAttribute = [
    {id: 1, element: 'Water'},
    {id: 2, element: 'Fire'},
    {id: 3, element: 'Wind'},
    {id: 4, element: 'Light'},
    {id: 5, element: 'Dark'},
    {id: 7, element: 'Neutral'},
  ];
  selectedAttribute: any = 'Neutral';
  //
  finalDmg: number;
  finalDmgNoCrit: number;
  finalDmgAverage: number;
  finalDmgAverageNoCrit: number;
  finalDmgMax: number;
  finalDmgMaxNoCrit: number;
  //multiplers
  multipliers: any[]
  atkMultiplier: number = 0;
  hpMultiplier: number = 0;
  defMultiplier: number = 0;
  spdSumup: number = 0;
  spdDivider: number = 0;
  maxHpTargetMultiplier: number = 0;
  livingAlly: number = 0;
  livingAllyMultiplier: number = 0;
  //FontAwesome
  plusCircle = faPlusCircle;
  edit = faPenSquare;

  constructor(
    private importService :ImportService,
    private buildingService: BuildingService,
    private statCalculator: StatsCalculatorService,
  ) { }

  ngOnInit(): void {
    this.units = this.importService.GetUnits()

    this.computeSelectedUnit()
  }

  computeSelectedUnit(){
    if (this.selectedUnit != undefined ) {
      // TODO store lead effects in unit.
      this.unitType = SWExporterTypes.Archetype[this.selectedUnit.archetype].toLowerCase()
      let leaderEffect = new Effect({
        effect_reducer: 1,
        gems: 0,
        grindstones: 0,
        type: this.leadType,
        value: this.leadValue
      })
      
      this.selectedUnitCaracs = this.statCalculator.ComputeStats(this.selectedUnit, [], [leaderEffect], this.buildings, this.guildSkill)

      let targetDef = this.ennemyDef
      let ennemyMaxHp = this.ennemyHp
      let skillCrit: number= 0
      let skillsMultipliers = this.multipliers

      if(this.selectedSkill){
        for(let item of this.selectedSkill.effects){
          console.log(item)
          if(item.effect.name == 'Ignore DEF' || item.effect.name == 'Bomb'){
            targetDef = 0;
          }
          if( item.effect.name == 'Bomb'){
            this.isBomb = true;
          }
        }
        if(this.selectedSkill.slot == 1){
          skillCrit = this.dmgBonusFirstSkill
        }
        if(this.selectedSkill.slot == 2){
          skillCrit = this.dmgBonusSecondSkill
        }
        if(this.selectedSkill.slot == 3){
          skillCrit = this.dmgBonusThirdSkill
        }
        if(this.selectedSkill.slot == 4){
          skillCrit = this.dmgBonusFourthSkill
        }
      }

      let hits = this.statCalculator.ComputeSkillHit(
        this.selectedUnitCaracs, {
          multipliers: skillsMultipliers,
          attackMultiplier: this.atkMultiplier,
          defMultiplier: this.defMultiplier,
          livingAllyMultiplier: this.livingAllyMultiplier,
          livingAlly: this.livingAlly,
          maxHpTargetMultiplier: this.maxHpTargetMultiplier,
          maxHpMultiplier: this.hpMultiplier,
          spdAdd: this.spdSumup,
          spdDivider: this.spdDivider,
          targetSpd: this.ennemySpd,
          hitCount: 1,
          skillUpBonus: this.skillUp,
          selectedSkill: this.selectedSkill
        },
        skillCrit,
        0,
        this.critBonusGoodHp,
        this.critBonusBadHp,
        targetDef,
        this.isBreakDef,
        this.isAtkBuff,
        ennemyMaxHp,        
      )

      let artifactBonusDamage = this.statCalculator.ComputeArtefactBonusDamage(
        this.selectedUnitCaracs, this.dmgBonusPerSpeed, this.dmgBonusPerDef,this.dmgBonusPerHp,this.dmgBonusPerAtk
      )      
      this.finalDmg = (1+ (this.dmgDealtOnWater/100)) * hits.minDamage + artifactBonusDamage;
      this.finalDmgNoCrit = (1+ (this.dmgDealtOnWater/100)) * hits.minDamageNoCrit + artifactBonusDamage;
      this.finalDmgAverage = (1+ (this.dmgDealtOnWater/100)) * hits.averageDamage + artifactBonusDamage;
      this.finalDmgMax = (1+ (this.dmgDealtOnWater/100)) * hits.maxDamage + artifactBonusDamage;
    }
  }

  getMonsterElement(){
    if(this.selectedUnit != undefined && this.editedUnit == false){
      if(this.selectedUnit.attribute == 1) {
        this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
          SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.WATER_SANCTUARY, 
          SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);
      }
      if(this.selectedUnit.attribute == 2) {
        this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
          SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.FIRE_SANCTUARY, 
          SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);
      }
      if(this.selectedUnit.attribute == 3) {
        this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
          SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.WIND_SANCTUARY, 
          SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);
      }
      if(this.selectedUnit.attribute == 4) {
        this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
          SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.LIGHT_SANCTUARY, 
          SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);
      }
      if(this.selectedUnit.attribute == 5) {
        this.buildings = this.buildingService.GetBuildingsWithType([SWExporterTypes.BuildingType.ANCIENT_SWORD, SWExporterTypes.BuildingType.CRYSTAL_ALTAR,
          SWExporterTypes.BuildingType.FALLEN_ANCIENT_GUARDIAN, SWExporterTypes.BuildingType.DARK_SANCTUARY, 
          SWExporterTypes.BuildingType.GUARDSTONE, SWExporterTypes.BuildingType.SKY_TRIBE_TOTEM]);
      }
      this.editedUnit = true;
    }
  }

  onChange() {
    this.getMonsterElement();
    this.computeSelectedUnit();
  }

  onModelChange(type: number){
    if(type == 1){
      console.log('1')
      this.defLead = 0
      this.hpLead = 0
      this.leadValue = Number(this.atkLead)
      this.leadType = SWExporterTypes.EffectType.ATKPercent
    }
    if(type == 2){
      console.log('2')
      this.atkLead = 0
      this.hpLead = 0
      this.leadValue = Number(this.defLead)
      this.leadType = SWExporterTypes.EffectType.DEFPercent
    }
    if(type == 3){
      console.log('3')
      this.atkLead = 0
      this.defLead = 0
      this.leadValue = Number(this.hpLead)
      this.leadType = SWExporterTypes.EffectType.HPPercent
    }
    if(type == 0){
      if(this.selectedAttribute.id == 1){
        this.dmgDealtOnFire = 0;
        this.dmgDealtOnWind = 0;
        this.dmgDealtOnLight = 0;
        this.dmgDealtOnDark = 0;
      }
      if(this.selectedAttribute.id == 2){
        this.dmgDealtOnWater = 0;
        this.dmgDealtOnWind = 0;
        this.dmgDealtOnLight = 0;
        this.dmgDealtOnDark = 0;
      }
      if(this.selectedAttribute.id == 3){
        this.dmgDealtOnWater =0
        this.dmgDealtOnFire = 0;
        this.dmgDealtOnLight = 0;
        this.dmgDealtOnDark = 0;
      }
      if(this.selectedAttribute.id == 4){
        this.dmgDealtOnWater = 0;
        this.dmgDealtOnFire = 0;
        this.dmgDealtOnWind = 0;
        this.dmgDealtOnDark = 0;
      }
      if(this.selectedAttribute.id == 5){
        this.dmgDealtOnWater = 0;
        this.dmgDealtOnFire = 0;
        this.dmgDealtOnWind = 0;
        this.dmgDealtOnLight = 0;
      }
    }
  }

  getUnitArtifacts(){
    this.atkIncrease =0
    this.defIncrease =0
    this.spdIncrease =0
    //
    this.dmgBonusPerHp =0
    this.dmgBonusPerAtk =0
    this.dmgBonusPerDef =0
    this.dmgBonusPerSpeed =0
    //
    this.critBonusGoodHp =0 
    this.critBonusBadHp =0
    //
    this.dmgBonusFirstSkill =0
    this.dmgBonusSecondSkill =0
    this.dmgBonusThirdSkill =0
    this.dmgBonusFourthSkill =0
    //
    this.dmgDealtOnWater =0
    this.dmgDealtOnFire =0
    this.dmgDealtOnWind =0
    this.dmgDealtOnLight =0
    this.dmgDealtOnDark =0
    //
    for(let artifact in this.selectedUnit.artifacts){
      if(this.selectedUnit.artifacts[artifact] != undefined){
        for(let sec_eff in this.selectedUnit.artifacts[artifact].secondaryEffects){
          let effect = this.selectedUnit.artifacts[artifact].secondaryEffects[sec_eff];
          switch(effect.type) {
            case 204:
              this.atkIncrease += effect.value
              break;
            case 205: 
              this.defIncrease += effect.value
              break;
            case 206: 
              this.spdIncrease += effect.value
              break;
            case 218:
              this.dmgBonusPerHp += effect.value
              break;
            case 219: 
              this.dmgBonusPerAtk += effect.value
              break;
            case 220: 
              this.dmgBonusPerDef += effect.value
              break;
            case 221: 
              this.dmgBonusPerSpeed += effect.value
              break;
            case 222: 
              this.critBonusGoodHp += effect.value 
              break;           
            case 223: 
              this.critBonusBadHp += effect.value
              break;
            case 400: 
              this.dmgBonusFirstSkill += effect.value
              break;
            case 401: 
              this.dmgBonusSecondSkill += effect.value
              break;
            case 402: 
              this.dmgBonusThirdSkill += effect.value
              break;
            case 403: 
              this.dmgBonusFourthSkill += effect.value
              break;
            case 300: 
              this.dmgDealtOnFire += effect.value
              break;
            case 301: 
              this.dmgDealtOnWater += effect.value
              break;
            case 302: 
              this.dmgDealtOnWind += effect.value
              break;
            case 303: 
              this.dmgDealtOnLight += effect.value
              break;
            case 304: 
              this.dmgDealtOnDark += effect.value
              break;
          }
        }
      }
    }
  }

  onChangeUnit(){
    this.editedUnit =false;
    this.getUnitArtifacts()
    this.onChange()
  }

  toggleDefBuff(){
    this.isDefLead= !this.isDefLead
    this.onChange()
  }

  toggleAtkBuff(){
    this.isAtkBuff= !this.isAtkBuff
    this.onChange()
  }

  toggleBreakDef(){
    this.isBreakDef = !this.isBreakDef
    this.onChange()
  }

  onSelectSkill(skill){
    this.selectedSkill = skill
    this.skillUp = 0;
    this.isBomb =false;

    this.multipliers = this.selectedSkill.multiplier_formula.split('+')

    if(this.selectedSkill.multiplier_formula.includes('-')){
      this.multipliers = this.selectedSkill.multiplier_formula.split('-')
    }

    for(let skillProgress of this.selectedSkill.level_progress_description){
      if(skillProgress.includes('Damage +')){
        let tempSkillProgress = skillProgress.split('+')[1]
        console.log(tempSkillProgress)
        this.skillUp += Number(tempSkillProgress.split('%')[0]);
      }
    }
    
    this.atkMultiplier = 0
    this.defMultiplier = 0
    this.spdSumup = 0
    this.spdDivider = 0
    this.hpMultiplier = 0
    this.maxHpTargetMultiplier = 0
    this.livingAllyMultiplier = 0

    for(let multiplier of this.multipliers){
      if(multiplier.includes('*{ATK}')){
        this.atkMultiplier = Number(multiplier.split('*{ATK}')[0]);
      }
      if(multiplier.includes('{ATK}*')){
        this.atkMultiplier = Number(multiplier.split('{ATK}*(')[1]);
      }
      if(multiplier.includes('*{MAX HP}')){
        this.hpMultiplier = Number(multiplier.split('*{MAX HP}')[0]);
      }
      if(multiplier.includes('*{DEF}')){
        this.defMultiplier = Number(multiplier.split('*{DEF}')[0]);
      }
      if(multiplier.includes('/')){
        this.spdSumup = Number(multiplier.split(')/')[0]);
        this.spdDivider = Number(multiplier.split(')/')[1]);
      }
      if(multiplier.includes('{Target MAX HP}')){
        this.maxHpTargetMultiplier = Number(multiplier.split('*{Target MAX HP}')[0]);
      }
      if(multiplier.includes('{Living Ally %}')){
        this.livingAllyMultiplier = Number(multiplier.split('*{Living Ally %})')[0]);
        this.livingAlly = 1
      }
    }

    this.onChange()
  }

  getKeys(obj:any){
    return Object.keys(obj)
  }

  getEffectTypeString(effectType: SWExporterTypes.EffectType) {
    return Effect.typeStr[effectType]
  }

  array(n: number) {
    return Array(n)
  }
}
