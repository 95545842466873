import { SWExporterTypes } from "../types/sw-exporter.types"
import { Effect } from "./effect.model"

export class RuneSet {
    effect: Effect
  
    constructor(readonly id: SWExporterTypes.SetType,readonly name: string, readonly minRuneRequired, readonly modifier: number, effectType: SWExporterTypes.EffectType) {
      this.effect = new Effect({
          type: effectType,
          value: modifier,
          gems: 0,
          grindstones: 0,
          effect_reducer: 1,
      })
    }

    get image() : string {
      return "assets/img/rune/rune_" + this.name.toLowerCase() + ".png"
  }
}